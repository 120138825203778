import { type FC } from 'react';

import { type EventingOverrides } from '@core/blocks/best-match-experience/services/events/productEvents';
import type { UserPreferencesAndMilitary } from '@core/blocks/best-match-experience/types';
import CustomTooltip from '@core/shared/components/CustomTooltip';
import { type VoyagerResult } from '@core/ts/results';

import AdvertisingSupportedCopy from '../../AdvertisingSupportedCopy';
import OutlineButton from '../../CtaButtons/OutlineButton';
import Pill from '../../Pill';
import ResultList from '../../ResultList';
import styles from './RecommenderProgramSelect.module.css';

type Props = {
  recommenderMatches: VoyagerResult[];
  onProgramCtaClick: (result: VoyagerResult) => void;
  onFlowExit: () => void;
  firstName?: string;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  degree: string;
  subject: string;
  eventingOverrides?: EventingOverrides;
};
const RecommenderProgramSelect: FC<Props> = ({
  recommenderMatches,
  onProgramCtaClick,
  onFlowExit,
  firstName,
  userPreferencesAndMilitary,
  degree,
  subject,
  eventingOverrides,
}) => (
  <>
    <div className={styles.programSelectHeaderSection}>
      <div className={styles.programSelectPersonlizedTitle}>You’ve got matches, {firstName}!</div>
      <div className={styles.programSelectIntroductionTitle}>
        These are your <br /> top recommendations based on:
      </div>
      <div className={styles.programSelectDataPills}>
        <Pill>{degree}</Pill>
        <Pill>{subject}</Pill>
      </div>
      <div className={styles.programSelectAddVerbiageDesktop}>
        <CustomTooltip
          className={styles.tooltip}
          tooltipTitle="Learn More About Our Partners"
          text={`${window.location.host} is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.`}
          title="About Our Partner Schools"
          position="below"
        />
      </div>
      <div className={styles.programSelectAddVerbiageMobile}>
        <CustomTooltip
          className={styles.tooltip}
          tooltipTitle=" Learn More About Our Partners"
          text={`${window.location.host} is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.`}
          title="About Our Partner Schools"
        />
      </div>
    </div>

    <div className={styles.programSelectResultsSection}>
      <ResultList
        cardType="recommender"
        onProgramCardClick={onProgramCtaClick}
        results={recommenderMatches}
        userPreferencesAndMilitary={userPreferencesAndMilitary}
        eventingOverrides={eventingOverrides}
      />

      <div className={styles.programSelectSkipAdVerbiage}>
        <OutlineButton className={styles.skipToResultsBtn} onClick={onFlowExit}>
          Skip to all results
        </OutlineButton>

        <CustomTooltip
          tooltipTitle="How We Select Your Matches"
          text="All schools and programs featured on this page have been sponsored, meaning BestColleges receives compensation. This compensation may impact how, where, and in what order these sponsored schools appear. Program tags reflect your preferences for the schools that offer them, but the order of these programs is determined by a proprietary algorithm."
          title="How We Select Your Matches"
          position="above"
        />
      </div>
      <div className={styles.advertisingSupportedCopyMobile}>
        <AdvertisingSupportedCopy />
      </div>
    </div>
  </>
);

export default RecommenderProgramSelect;
