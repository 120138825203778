import { VoyagerResult } from '@core/ts/results';

import {
  showAccredited,
  showApplyForFree,
  showFlexible,
  showMilitary,
  showNoActSat,
  showTransferCredits,
} from '../UserPreferencesPills';
import { type UserPreferencesAndMilitary } from '../types';

const pillCustomDimensions = (result: VoyagerResult, userPreferencesAndMilitary: UserPreferencesAndMilitary) => [
  {
    key: 'apply-for-free',
    value: showApplyForFree(result, userPreferencesAndMilitary) ? 'true' : 'false',
  },
  {
    key: 'accredited',
    value: showAccredited(result, userPreferencesAndMilitary) ? 'true' : 'false',
  },
  {
    key: 'no-act-sat',
    value: showNoActSat(result, userPreferencesAndMilitary) ? 'true' : 'false',
  },
  {
    key: 'credit-transfer',
    value: showTransferCredits(result, userPreferencesAndMilitary) ? 'true' : 'false',
  },
  {
    key: 'flexible',
    value: showFlexible(result, userPreferencesAndMilitary) ? 'true' : 'false',
  },
  {
    key: 'military',
    value: showMilitary(result, userPreferencesAndMilitary) ? 'true' : 'false',
  },
];

export default pillCustomDimensions;
