import { type PropsWithChildren, type FC } from 'react';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { type MonarchPsychBannerTest } from '@core/context/types';

import PsychBanner1 from './PsychBanner1';
import PsychBanner2 from './PsychBanner2';

enum PsychBannerId {
  BANNER_1 = 'psych-banner-1',
  BANNER_2 = 'psych-banner-2',
}
const psychBannerComponentMap: Record<PsychBannerId, FC<MonarchPsychBannerTest>> = {
  [PsychBannerId.BANNER_1]: PsychBanner1,
  [PsychBannerId.BANNER_2]: PsychBanner2,
};

const PsychBannerTestComponent: FC<PropsWithChildren<{ isGrad?: boolean }>> = ({ children, isGrad }) => {
  const flags = useFeatureFlags();

  const BannerComponent =
    psychBannerComponentMap[flags.psychBannerTest?.id as PsychBannerId] ?? (() => <>{children}</>);

  // Pass all the payload properties as component props
  return <BannerComponent {...flags?.psychBannerTest} isGrad={isGrad} />;
};

export default PsychBannerTestComponent;
