import { type FC, type ReactNode } from 'react';

import cn from '@core/utils/classNames';

import styles from './Tooltip.module.css';

type Props = {
  text: string | ReactNode;
  buttonText: string | ReactNode;
  isOpen: boolean;
  toggleOpen: () => void;
  position?: string;
};

const Tooltip: FC<Props> = ({ text, isOpen, toggleOpen, buttonText, position = 'below' }) => (
  <div className={styles.tooltipWrapper}>
    {isOpen && (
      <div className={cn(styles.tooltip, position === 'below' ? styles.below : styles.above)}>
        <div className={styles.tooltipTopBorder} />
        <div className={styles.tooltipContent}>
          {text}
          <button type="button" className={styles.tooltipButton} onClick={toggleOpen}>
            {buttonText}
          </button>
        </div>
      </div>
    )}
  </div>
);

export default Tooltip;
