import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import UserPreferencesPills from '../../UserPreferencesPills';
import type { UserPreferencesAndMilitary } from '../../types';
import styles from './DrawerExperienceHeaderMobile.module.css';

type Props = {
  result: VoyagerResult;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
};

const DrawerExperienceHeaderMobile: FC<Props> = ({ result, userPreferencesAndMilitary }) => (
  <header className={styles.drawerExperienceHeader}>
    <h2 className={styles.programName}>{result?.program?.name}</h2>
    <UserPreferencesPills result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
  </header>
);

export default DrawerExperienceHeaderMobile;
