import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import HorizontalCardSlider from '../HorizontalCardSlider';
import styles from './ProgramOverview.module.css';

type Props = {
  otherProgramsForSchool?: VoyagerResult[];
  result: VoyagerResult;
  lastUserPreference: string;
};

const userPreferencesQuestionRegistry = {
  Affordability: [
    'How much is tuition for this program?',
    'What additional fees do I need to consider?',
    'What financial aid and scholarships opportunities are available?',
  ],
  Flexibility: [
    'How many credits do I need each semester?',
    'Can accommodations be made for students with varying needs or preferences regarding coursework, deadlines, or learning styles?',
    'Does this school offer flexible learning schedules, like night or weekend classes?',
  ],
  'Admissions rate': [
    'When is my application due?',
    `What is this program's acceptance rate?`,
    'What factors do you look at when accepting students into this program?',
  ],
  Accreditation: [
    `Could you provide information on the school's accreditation status and any specialized accreditations?`,
    `How does accreditation impact the quality of education and transferability of credits?`,
    `Are there specific career benefits associated with attending an accredited institution?`,
  ],
  'Ability to transfer credits': [
    `Can this school provide guidance on maximizing transfer credits to accelerate my degree completion?`,
    `What is the process for transferring my credits?`,
    `What is my deadline for transferring my credits?`,
  ],
  'Program length': [
    'How many credits do I need each semester?',
    'Are there opportunities to accelerate my degree completion?',
    'Does this school offer flexible learning schedules, like night or weekend classes?',
  ],
  'Diversity & inclusion': [
    'How many students will be in my classes?',
    'Is there financial aid offered for certain minority groups or income levels?',
    'Can accommodations be made for students with varying needs or preferences regarding coursework, deadlines, or learning styles?',
  ],
  'Salary potential': [
    'What careers have alumni pursued after this program?',
    `What was this program’s graduation rate last year?`,
    `What factors do you look at when accepting students into this program?`,
  ],
};
const ProgramOverview: FC<Props> = ({ otherProgramsForSchool, result, lastUserPreference }) => (
  <div className={styles.programOverviewContainer}>
    <div className={styles.overviewSection}>
      <h4 className={styles.overviewHeader}>Program Overview</h4>
      <p className={styles.overviewDescription}>{result?.program?.snippet}</p>
    </div>

    <div className={styles.questionSection}>
      <div className={styles.questionsHeader}>Questions to Ask a School Advisor</div>
      <div className={styles.questionsDescription}>
        You can learn a lot from talking with a school advisor. Here are a few questions you may want to ask them about{' '}
        <b>{lastUserPreference.toLowerCase()}.</b>
      </div>
      <ul className={styles.questionsList}>
        {userPreferencesQuestionRegistry[lastUserPreference as keyof typeof userPreferencesQuestionRegistry]?.map(
          (question, index) => (
            <li key={index} className={styles.question}>
              {question}
            </li>
          )
        )}
      </ul>
    </div>

    <div className={styles.relatedProgramsSection}>
      <div className={styles.relatedProgramsHeader}>Related Programs</div>
      <HorizontalCardSlider results={otherProgramsForSchool} />
    </div>
  </div>
);

export default ProgramOverview;
