import { type FC } from 'react';

import addAcronymSchoolIds from '@core/config/addAcronymSchoolIds.json';
import { appendAcronym } from '@core/shared/features/TcpaSection';
import { School } from '@core/ts/results';

import { replaceCTALabelInTCPA } from '../../utils/tcpa';
import styles from './TCPA.module.css';

type Props = {
  school?: School;
};

const TCPA: FC<Props> = ({ school }) => {
  const isAcronymRequired = addAcronymSchoolIds.includes(school?.id as number);

  return (
    <div className={styles.piiConfirmationTermsAndConditions}>
      <div className={styles.piiConfirmationTermsAndConditionsTitle}> Terms and Conditions </div>

      <span className={styles.schoolName} data-tf-element-role="consent-advertiser-name">
        {isAcronymRequired ? appendAcronym(school?.name || '') : school?.name}:
      </span>
      <p
        data-tf-element-role="consent-language"
        dangerouslySetInnerHTML={{ __html: replaceCTALabelInTCPA(school?.config?.tcpa) ?? '' }}
      />
    </div>
  );
};

export default TCPA;
