import { useEffect } from 'react';

const JornayaScript = () => {
  useEffect(() => {
    // leadId script
    const s = document.createElement('script');
    s.id = 'LeadiDscript_campaign';
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//create.lidstatic.com/campaign/1a8b1fee-893f-e8f5-901a-5d12b2e0d47b.js?snippet_version=2';

    document.body.appendChild(s);

    // leadId noscript

    return () => {
      document.body.removeChild(s);
    };
  }, []);

  return (
    <noscript>
      <img
        alt="JornayaScript"
        src="//create.leadid.com/noscript.gif?lac=10640D98-6234-AE07-4241-E51B5D303C88&lck=1a8b1fee-893f-e8f5-901a-5d12b2e0d47b&snippet_version=2"
      />
    </noscript>
  );
};

export default JornayaScript;
