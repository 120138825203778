import type { FC } from 'react';

import Button from '../../ui/Button';
import BaseCard from '../BaseCard';
import type { ProgramCardProps } from '../types';
import styles from './RecommenderCard.module.css';

const RecommenderCard: FC<ProgramCardProps> = (props) => (
  <BaseCard {...props}>
    <Button className={styles.strechedButton} primary>
      <div>Explore Program </div>+
    </Button>
  </BaseCard>
);

export default RecommenderCard;
