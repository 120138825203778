import { type IdSchema } from '@rjsf/utils';
import { type Dispatch, type SetStateAction, type FC } from 'react';

import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import type { VoyagerResult } from '@core/ts/results';

import useProgramSubmissionFlow from '../../../hooks/useProgramSubmissionFlow';
import { FlowType, type ApplicationCta, type UserPreferencesAndMilitary } from '../../../types';
import AdditionalQuestions2 from '../../AdditionalQuestions2';
import DrawerExperienceHeaderDesktop from '../../DrawerExperienceHeaderDesktop';
import ExitStrategyComponent from '../../ExitStrategy';
import ExitStrategyContent from '../../ExitStrategy/ExitStrategyContent';
import NotQualified from '../../NotQualified';
import PiiConfirmation from '../../PiiConfirmation';
import { Step, Steps } from '../../Steps';
import { StepTypes } from '../../Steps/types';
import SubmissionLoader from '../../SubmissionLoader';
// Styles
import styles from './undergrad.module.css';

type Props = {
  result: VoyagerResult;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  prqSchema?: IdSchema;
  relatedSchoolResults: VoyagerResult[];
  userInputs: VoyagerInputs;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onEdit: (result: VoyagerResult) => void;
  onFlowExit: () => void;
  hasCurrentProgramBeenSubmitted: boolean;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const GradDrawer: FC<Omit<Props, 'open' | 'setOpen' | 'onClose'>> = ({
  result,
  onFormSubmit,
  prqSchema,
  userInputs,
  onProgramCtaClick,
  onFlowExit,
  onEdit,
  hasCurrentProgramBeenSubmitted,
  currentResultApplicationCta,
  isHeclidLoading,
  isCurrentResultApplicationCtaLoading,
  userPreferencesAndMilitary,
}) => {
  const flowType = FlowType.DRAWER;

  const {
    goToPRQ,
    goToPogramSelect,
    handleProgramCtaClick,
    handlePRQFormSubmit,
    currentStepId,
    isSubmittingLead,
    handleFormSubmission,
    handleEditConfirm,
    handleEdit,
    pii,
    setPii,
    piiErrors,
    isEditing,
  } = useProgramSubmissionFlow({
    userInputs,
    onFormSubmit,
    prqSchema,
    result,
    initialStep: StepTypes.PII_CONFIRMATION,
    onProgramCtaClick,
    hasCurrentProgramBeenSubmitted,
    onEdit,
  });

  const isExitStrategyComponent = currentStepId === StepTypes.EXIT_STRATEGY;

  return (
    <div className={styles.submissionContainer}>
      <Steps currentStepId={currentStepId}>
        <Step stepId={StepTypes.PII_CONFIRMATION} className={styles.piiConfirmationStep}>
          <PiiConfirmation
            flowType={flowType}
            result={result}
            onFormSubmit={handleFormSubmission}
            onBackButtonClick={goToPogramSelect}
            onEdit={handleEdit}
            isHeclidLoading={isHeclidLoading}
            isSubmittingLead={isSubmittingLead}
            onEditConfirm={handleEditConfirm}
            pii={pii}
            piiErrors={piiErrors}
            setPii={setPii}
            isEditing={isEditing}
            headerComponent={
              <DrawerExperienceHeaderDesktop result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
            }
          />
        </Step>
        <Step stepId={StepTypes.ADDITIONAL_QUESTIONS}>
          <AdditionalQuestions2
            flowType={flowType}
            selectedResult={result}
            prqSchema={prqSchema as IdSchema}
            onFormSubmit={handlePRQFormSubmit}
            eventingOverrides={{}}
            onBackButtonClick={() => handleProgramCtaClick(result, {})}
            result={result}
            isSubmittingLead={isSubmittingLead}
          />
        </Step>

        <Step stepId={StepTypes.EXIT_STRATEGY}>
          <ExitStrategyComponent
            onBackButtonClick={goToPRQ}
            result={result}
            firstName={userInputs?.firstName?.value}
            flowType={flowType}
            currentResultApplicationCta={currentResultApplicationCta}
            isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
            userPreferencesAndMilitary={userPreferencesAndMilitary}
            isGrad
          />
        </Step>

        <Step stepId={StepTypes.NOT_QUALIFIED}>
          <NotQualified flowType={flowType} result={result} onCtaClick={onFlowExit} />
        </Step>

        <Step stepId={StepTypes.LOADING}>
          <SubmissionLoader flowType={flowType} result={result} />
        </Step>
      </Steps>
      {isExitStrategyComponent && <ExitStrategyContent result={result} />}
    </div>
  );
};

export default GradDrawer;
