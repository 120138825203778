import { type KeyboardEvent, type FC, useState, useMemo, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import BaseCard from './BaseCard';
import MatchesCard from './MatchesCard';
import RecommenderCard from './RecommenderCard';
import type { ProgramCardProps, ProgramCardWrapper } from './types';

const CardTypeMap: Record<string, FC<ProgramCardProps>> = {
  default: MatchesCard,
  recommender: RecommenderCard,
  spotlight: BaseCard,
};

const ProgramCard: FC<ProgramCardWrapper> = ({
  type = 'default',
  position = 1,
  result,
  userPreferencesAndMilitary,
  onClick,
  onMount,
  onView,
  handleCheckIfProgramIsSubmitted,
  handleFindProgramApplicationCta,
  isCoveredByElement,
  ...props
}) => {
  const CardComponent: FC<ProgramCardProps> = CardTypeMap[type] ?? BaseCard;

  const [hasCardBeenViewed, setCardHasBeenViewed] = useState(false);
  const applicationCta = useMemo(
    () => handleFindProgramApplicationCta?.(result),
    [handleFindProgramApplicationCta, result]
  );

  const hasApplicationCta = useMemo(() => Boolean(applicationCta?.label), [applicationCta?.label]);
  const hasProgramBeenSubmitted = useMemo(
    () => handleCheckIfProgramIsSubmitted?.(result),
    [handleCheckIfProgramIsSubmitted, result]
  );

  const handleProgramClick = () => {
    if (hasApplicationCta && hasProgramBeenSubmitted) {
      window.open(applicationCta?.url, '_blank');
      return;
    }

    onClick?.(result);
  };

  const handleProgramEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClick?.(result);
    }
  };

  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.3 });

  useEffect(() => {
    onMount?.(result);
  }, []);

  useEffect(() => {
    if (inView && onView && !hasCardBeenViewed && !isCoveredByElement) {
      onView(result);
      setCardHasBeenViewed(true);
    }
  }, [inView, onView, result, hasCardBeenViewed, isCoveredByElement]);

  return (
    <div
      tabIndex={Number(position)}
      onKeyDown={handleProgramEnter}
      role="button"
      ref={ref}
      onClick={handleProgramClick}
    >
      <CardComponent
        hasProgramBeenSubmitted={hasProgramBeenSubmitted}
        hasApplicationCta={hasApplicationCta}
        type={type}
        result={result}
        userPreferencesAndMilitary={userPreferencesAndMilitary}
        {...props}
      />
    </div>
  );
};

export default ProgramCard;
