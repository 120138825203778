import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import styles from './SchoolHighlights.module.css';

type Props = {
  result: VoyagerResult;
};
const SchoolHighlights: FC<Props> = ({ result }) =>
  (result?.school?.config?.valueProps && (
    <div className={styles.schoolHighlights}>
      {result?.school?.config.valueProps?.map((valueProp: { title: string; description: string }) => (
        <div className={styles.valuePropSection}>
          <div className={styles.valuePropHeader}>{valueProp.title}</div>
          <div className={styles.valuePropDescription} dangerouslySetInnerHTML={{ __html: valueProp.description }} />
        </div>
      ))}
    </div>
  )) ||
  null;

export default SchoolHighlights;
