import { type FC, type FormEvent, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import RoundedCheckbox from '@core/shared/components/RoundedCheckbox';
import SlideUpPopup from '@core/shared/components/SlideUpPopup';
import { ElementEventProperties } from '@core/ts/cohesion';

import styles from './UserExperienceSurvey.module.css';
import { userInputFieldPayload, elementEventPayloads } from './eventingPayloads';

const options: Record<string, string> = {
  'I was expecting to see other schools here.': 'What schools were you expecting to see?',
  "There wasn't enough information to make a decision.": 'What information would have been more useful?',
  "I'd like to do more research directly on the school's website.":
    'What information are you wanting to research further?',
  Other: 'Please provide additional feedback.',
};

export type BannerProps = {
  onClick: () => void;
};

const DefaultBanner: FC<BannerProps> = ({ onClick }) => (
  <section className={styles.userExperienceSurvey}>
    <div className={styles.userExperienceSurveyBanner}>
      <div className={styles.userExperienceSurveyBannerContainer}>
        <h2>Not what you expected?</h2>
        <button type="button" onClick={onClick}>
          Give feedback
        </button>
      </div>
    </div>
  </section>
);

type Props = {
  BannerComponent?: FC<BannerProps>;
  isCoveredByElement?: boolean;
};

const UserExperienceSurvey: FC<Props> = ({ BannerComponent = DefaultBanner, isCoveredByElement }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasElementBeenViewed, setHasElementBeenViewed] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [checkedFields, setCheckedFields] = useState<Record<string, boolean>>({});
  const canSubmit = Object.values(checkedFields).some((value) => value);
  const [fieldInputs, setFieldInputs] = useState<Record<string, string>>({});
  const [canFireFieldInputted, setCanFireFieldInputted] = useState(true);
  const { current: correlationId } = useRef(uuid());

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId,
  });
  const { fieldViewed, fieldSelected } = useFieldEvents({
    correlationId,
    userInputField: {
      autofilled: false,
      fieldName: 'survey-feedback',
      fieldLabel: 'Give us your feedback',
      fieldType: 'checkbox',
    },
  });

  const { fieldInputted } = useFieldEvents({
    correlationId,
    userInputField: {
      autofilled: false,
      fieldName: 'survey-feedback-other',
      fieldLabel: 'Give us your feedback',
      fieldType: 'checkbox',
    },
  });

  useEffect(() => {
    if (!isCoveredByElement && !isModalOpen && !hasElementBeenViewed) {
      elementViewed(elementEventPayloads.giveFeedbackButton);
      setHasElementBeenViewed(true);
    }
  }, [elementViewed, hasElementBeenViewed, isCoveredByElement, isModalOpen]);

  const handleClickToOpenModal = () => {
    elementClicked(elementEventPayloads.giveFeedbackButton);
    setIsModalOpen(true);
    elementViewed(elementEventPayloads.popupCloseButton);
    elementViewed(elementEventPayloads.popupCancelButton);
    elementViewed(elementEventPayloads.popupSubmitButton);
    fieldViewed();
  };

  const handleClose = (elementClickedEventPayload: ElementEventProperties) => {
    elementClicked(elementClickedEventPayload);
    setIsModalOpen(false);
    setFieldInputs({});
    setCheckedFields({});
  };

  const handleChange = (event: FormEvent<HTMLInputElement>, label: string, fieldNumber: number) => {
    setCheckedFields({ ...checkedFields, [label]: event.currentTarget.checked });

    const fieldSelectedPayload = {
      userInputField: {
        ...userInputFieldPayload,
        fieldLabel: label,
        fieldValue: String(event.currentTarget.checked),
        fieldNumber,
      },
    };
    fieldSelected(fieldSelectedPayload);
  };

  const handleInputChange = (event: FormEvent<HTMLTextAreaElement>, option: string) => {
    setFieldInputs({ ...fieldInputs, [option]: event.currentTarget.value });
  };

  const handleInputBlur = (event: FormEvent<HTMLTextAreaElement>, label: string, fieldNumber: number) => {
    const fieldInputtedPayload = {
      userInputField: {
        ...userInputFieldPayload,
        fieldLabel: label,
        fieldValue: event.currentTarget.value,
        fieldNumber,
      },
    };
    if (canFireFieldInputted) {
      fieldInputted(fieldInputtedPayload);
      setCanFireFieldInputted(false);
    }
  };

  const handleFocus = () => {
    setCanFireFieldInputted(true);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!canSubmit) return;

    elementClicked(elementEventPayloads.popupSubmitButton);
    setIsFormSubmitted(true);
  };

  useEffect(
    () => () => {
      setFieldInputs({});
      setCheckedFields({});
    },
    []
  );

  return (
    <>
      {!isFormSubmitted && <BannerComponent onClick={handleClickToOpenModal} />}

      <SlideUpPopup show={isModalOpen} onClose={() => handleClose(elementEventPayloads.popupXButton)}>
        <div className={styles.userExperienceSurveyPopup}>
          {!isFormSubmitted && (
            <>
              <h2>How can we improve your experience?</h2>
              <h3>Please select all that apply.</h3>
              <form onSubmit={handleSubmit}>
                <div className={styles.inputs}>
                  {Object.keys(options).map((option, index) => (
                    <label htmlFor={option} key={option}>
                      <span>
                        <RoundedCheckbox
                          id={option}
                          name={option}
                          onChange={(e) => handleChange(e, option, index + 1)}
                        />
                        {option}
                      </span>
                      {checkedFields[option] && (
                        <div className={styles.feedbackSection}>
                          <div>{options[option]}</div>
                          <textarea
                            value={fieldInputs[option]}
                            className={styles.inputField}
                            onChange={(e) => handleInputChange(e, option)}
                            onBlur={(e) => handleInputBlur(e, option, index + 1)}
                            onFocus={handleFocus}
                          />
                        </div>
                      )}
                    </label>
                  ))}
                </div>
                <div className={styles.buttonGroup}>
                  <button
                    className={styles.secondaryBtn}
                    type="button"
                    onClick={() => handleClose(elementEventPayloads.popupCancelButton)}
                  >
                    Close
                  </button>
                  <button disabled={!canSubmit} className={styles.primaryBtn} type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </>
          )}

          {isFormSubmitted && (
            <>
              <h2>Thanks for your feedback.</h2>
              <p>We&apos;ll review your response. We&apos;re always looking for ways we can improve your experience.</p>
              <div className={styles.buttonGroup}>
                <button
                  className={styles.primaryBtn}
                  type="button"
                  onClick={() => handleClose(elementEventPayloads.popupCloseButton)}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </SlideUpPopup>
    </>
  );
};

export default UserExperienceSurvey;
