/* eslint-disable react/require-default-props */
// Components
import Page from '@edu-flow/components/Page';
import RootRouteHandler from '@edu-flow/components/RootRouteHandler';
import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';

import getFieldType from '@core/blocks/edu-flow/utils/getFieldType';
import getStepContextFromPage from '@core/blocks/edu-flow/utils/getStepContextFromPage';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import useQueryParams from '@core/hooks/useQueryParams';
// Reducers selectors/actions
import {
  selectFormCorrelationId,
  selectFormContext,
  selectHasFiredFormStarted,
  selectHasFiredFormViewed,
  setHasFiredFormStarted,
  setHasFiredFormViewed,
  setSourceCorrelationId,
  setStepContext,
  selectStepContext,
} from '@core/reducers/eventingSlice';
import { setInputs } from '@core/reducers/inputsSlice';
import { BlockPage } from '@core/schemas/schema';
// Utils & Hooks
import { getBrowserLocation } from '@core/services/mobius';
import { RootState } from '@core/store';

import styles from './EduFlow.module.css';
import DefaultPage, { DefaultPageProps } from './components/Page/DefaultPage';
import getNumOfSkippedQuestion from './components/Page/utils/getNumOfSkippedQuestion';

type EduFlowProps = {
  pages: BlockPage[];
  PageComponent?: FC<DefaultPageProps>;
};

const EduFlow: FC<EduFlowProps> = ({ pages, PageComponent = DefaultPage }) => {
  const { pathname } = useLocation();

  const queryParams = useQueryParams();

  const currentPage = pages?.find((page) => page.slug === pathname);

  const currentPageIndex = pages?.findIndex((page) => page.slug === pathname) as number;

  const storeStepContext = useSelector(selectStepContext);

  const store = useSelector((state: RootState) => state);

  const flags = useFeatureFlags();

  const stepContext = {
    ...getStepContextFromPage(currentPage as BlockPage, flags),
    stepNumber: currentPageIndex + 1 - getNumOfSkippedQuestion(store, pages, currentPageIndex, flags),
  };

  // checks if both step contexts are the same
  const hasUpdatedStepContext = JSON.stringify(stepContext) === JSON.stringify(storeStepContext);

  // Store properties
  const formContext = useSelector(selectFormContext);
  const hasFiredFormStarted = useSelector(selectHasFiredFormStarted);
  const hasFiredFormViewed = useSelector(selectHasFiredFormViewed);
  const formCorrelationId = useSelector(selectFormCorrelationId);

  // Action Dispatcher
  const dispatch = useDispatch();

  // Pass the eventing
  const { formViewed, formStarted } = useFormEvents({
    formContext,
    stepContext,
    correlationId: formCorrelationId,
  });

  useEffect(() => {
    dispatch(setStepContext(stepContext));
  }, [currentPage]);

  // Check to see if schema has a zip field
  // if so, then get browser location from Mobius and pre-populate the zip if available
  // want to do this here so it is resolved before the user get's to the zip page
  // NOTE: This assumes ONLY one zip field in the whole schema
  useEffect(() => {
    const zipField = getFieldType('zip', pages, flags); // Get the first field with type zip

    if (zipField) {
      // If we have a zip field, then get the browser location and pre-populate the zip input
      const fetchBrowserLocation = async () => {
        const res = await getBrowserLocation('fc6954c9-b2b3-47f1-9749-1c6330009ad8');
        if (res?.zipCode) {
          dispatch(
            setInputs([
              { key: 'zip', value: res.zipCode },
              { key: 'ipInferredPostalCode', value: res.zipCode },
            ])
          );
        }
      };
      // Call this asynchronously
      fetchBrowserLocation();
    }
  }, []);

  // will fire the FormViewed event, only if it hasn't been fired before on this instance
  useEffect(() => {
    const sourceCorrelationId = queryParams?.elementClickID as string;

    if (!hasFiredFormViewed && pathname !== '/') {
      formViewed({ sourceCorrelationId });
      dispatch(setHasFiredFormViewed());
      dispatch(setSourceCorrelationId(sourceCorrelationId));
    }
  }, [pathname]);

  // A click anywhere on the screen will trigger a FormStarted
  const handleClick = () => {
    if (!hasFiredFormStarted) {
      formStarted();
      dispatch(setHasFiredFormStarted());
    }
  };

  return (
    <section
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleClick();
      }}
      className={styles.sectionButton}
      role="button"
      tabIndex={0}
    >
      <Routes>
        <Route key="/" path="/" element={<RootRouteHandler pages={pages} />} />
        {pages.map((page, index) => (
          <Route
            key={page.slug}
            path={page.slug}
            element={
              <Page
                hasUpdatedStepContext={hasUpdatedStepContext}
                flow={pages}
                page={page}
                index={index}
                PageComponent={PageComponent}
              />
            }
          />
        ))}
      </Routes>
    </section>
  );
};

export default EduFlow;
