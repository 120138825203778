import { type FC } from 'react';

import styles from './ResourcesBanner.module.css';

const href = window?.HE?.publisher?.resourcesUrl ?? 'https://www.bestcolleges.com/resources/';

type Props = {
  handleResourcesClick: () => void;
};

const ResourcesBanner: FC<Props> = ({ handleResourcesClick }) => (
  <div className={styles.resourcesBannerContainer}>
    <div>Before you go!</div>
    <p>Check out some resources that&apos;ll help outline the next steps in your education journey.</p>
    <a href={href} target="_BLANK" rel="noreferrer" onClick={handleResourcesClick}>
      See Resources
    </a>
  </div>
);

export default ResourcesBanner;
