import { type ButtonHTMLAttributes, type ReactNode, type FC, useState, MouseEvent } from 'react';

import cn from '@core/utils/classNames';

import styles from './Button.module.css';

type Props = {
  children: ReactNode;
  submit?: boolean;
  onView?: () => void;
  primary?: boolean;
  className?: string;
  clickOnce?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<Props> = ({
  submit = false,
  primary = false,
  className = '',
  onView,
  children,
  clickOnce = false,
  onClick,
  disabled,
  ...props
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={cn(styles.button, primary ? styles.primary : styles.secondary, className)}
    type={submit ? 'submit' : 'button'}
    {...props}
  >
    {children}
  </button>
);

export default Button;
