import { useState, useEffect, type Dispatch, type SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import { setInputs, type VoyagerInputs } from '@core/reducers/inputsSlice';
import { validateEmail, validatePhone } from '@core/services/leadDelivery';
import type { VoyagerResult } from '@core/ts/results';

import { StepTypes } from '../components/Steps/types';
import { type EventingOverrides } from '../services/events/productEvents';

type ProgramSubmissionFlowParams = {
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  prqSchema?: any;
  result: VoyagerResult;
  initialStep: StepTypes;
  onProgramCtaClick: (result: VoyagerResult, eventingOverrides: EventingOverrides) => void;
  hasCurrentProgramBeenSubmitted?: boolean;
  userInputs: VoyagerInputs;
  onEdit: (result: VoyagerResult) => void;
};

type ProgramSubmissionFlowReturn = {
  goToPRQ: () => void;
  goToPogramSelect: () => void;
  handleProgramCtaClick: (result: VoyagerResult, eventingOverrides: EventingOverrides) => void;
  handlePRQFormSubmit: () => void;
  currentStepId: StepTypes;
  isSubmittingLead: boolean;
  handleFormSubmission: () => void;
  setIsSubmittingLead: Dispatch<SetStateAction<boolean>>;
  setCurrentStepId: Dispatch<SetStateAction<StepTypes>>;
  isEditing: boolean;
  piiErrors: Record<string, boolean>;
  handleEdit: () => void;
  handleEditConfirm: () => void;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  pii: Record<string, string>;
};

const isEmptyString = (str: String) => str === null || str?.match(/^ *$/) !== null;

const useProgramSubmissionFlow = ({
  onFormSubmit,
  prqSchema,
  result,
  initialStep,
  onProgramCtaClick,
  hasCurrentProgramBeenSubmitted,
  userInputs,
  onEdit,
}: ProgramSubmissionFlowParams): ProgramSubmissionFlowReturn => {
  const dispatch = useDispatch();
  const [currentStepId, setCurrentStepId] = useState(initialStep);
  const [isSubmittingLead, setIsSubmittingLead] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [piiErrors, setPiiLeadErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  });

  const { formErrored } = useFormEvents({
    formContext: {
      formName: 'voyager',
      formVersion: 'dynamic-post-flow',
      formType: 'post-flow',
      formBrand: result?.school?.slug,
      formId: '1015',
    },
    errorDetails: 'INVALID',
    errorMessage: 'Please provide your valid phone number.',
    errorType: 'Phone Validation',
  });

  const [pii, setPii] = useState<Record<string, string>>({
    firstName: userInputs?.firstName?.value,
    lastName: userInputs?.lastName?.value,
    email: userInputs?.email?.value,
    phone: userInputs?.phone?.value,
  });

  // Save PII to local state
  const savePii = useCallback(() => {
    const { firstName, lastName, email, phone } = pii;
    dispatch(
      setInputs([
        { key: 'firstName', value: firstName },
        { key: 'lastName', value: lastName },
        { key: 'email', value: email },
        { key: 'phone', value: phone },
      ])
    );
  }, [dispatch, pii]);

  // Check for PII errors
  const checkForPiiErrors = useCallback(async (): Promise<boolean> => {
    const formErrors = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
    };

    setPiiLeadErrors(formErrors);

    const { firstName, lastName, email, phone } = pii;

    if (isEmptyString(firstName)) formErrors.firstName = true;
    if (isEmptyString(lastName)) formErrors.lastName = true;
    if (isEmptyString(email) || !validateEmail(email ?? '')) formErrors.email = true;

    if (isEmptyString(phone)) {
      formErrors.phone = true;
    } else {
      const { validPhoneNumber } = await validatePhone(phone);
      if (!validPhoneNumber) {
        formErrors.phone = true;
        formErrored();
      }
    }

    const hasErrors = Object.values(formErrors).some((error) => error);

    if (hasErrors) {
      setPiiLeadErrors(formErrors);
      return true;
    }
    savePii();

    return false;
  }, [formErrored, pii, savePii]);

  // Handle edit state
  const handleEdit = () => {
    setIsEditing(true);
    onEdit(result);
  };

  // Handle edit confirm
  const handleEditConfirm = useCallback(async () => {
    const hasPiiErrors = await checkForPiiErrors();

    if (hasPiiErrors) return;
    setIsEditing(false);
    savePii();
  }, [checkForPiiErrors, savePii]);

  // change submitting lead state, check for pii errors, and handle program submission
  const handleFormSubmission = async () => {
    setIsSubmittingLead(true);
    const hasPiiErrors = await checkForPiiErrors();

    if (hasPiiErrors) {
      setIsEditing(true);
      setIsSubmittingLead(false);
      return;
    }

    if (prqSchema) {
      setCurrentStepId(StepTypes.ADDITIONAL_QUESTIONS);
      setIsSubmittingLead(false);
      return;
    }

    setCurrentStepId(StepTypes.LOADING);
    const responseStatus = await onFormSubmit(result);
    if (responseStatus === 201) {
      setCurrentStepId(StepTypes.EXIT_STRATEGY);
    }

    if (responseStatus === 400) {
      setCurrentStepId(StepTypes.NOT_QUALIFIED);
    }

    setIsSubmittingLead(false);
  };

  const handlePRQFormSubmit = async () => {
    setIsSubmittingLead(true);
    setCurrentStepId(StepTypes.LOADING);
    const responseStatus = await onFormSubmit(result);
    if (responseStatus === 201) {
      setCurrentStepId(StepTypes.EXIT_STRATEGY);
    }

    if (responseStatus === 400) {
      setCurrentStepId(StepTypes.NOT_QUALIFIED);
    }

    setIsSubmittingLead(false);
  };

  const handleProgramCtaClick = (result: VoyagerResult, eventingOverrides: EventingOverrides) => {
    onProgramCtaClick(result, eventingOverrides);
    setCurrentStepId(StepTypes.PII_CONFIRMATION);
  };

  const goToPogramSelect = () => setCurrentStepId(StepTypes.PROGRAM_SELECT);

  const goToPRQ = () => setCurrentStepId(StepTypes.ADDITIONAL_QUESTIONS);

  useEffect(() => {
    if (hasCurrentProgramBeenSubmitted) setCurrentStepId(StepTypes.EXIT_STRATEGY);
  }, [hasCurrentProgramBeenSubmitted, result]);

  return {
    goToPRQ,
    goToPogramSelect,
    handleProgramCtaClick,
    handlePRQFormSubmit,
    currentStepId,
    isSubmittingLead,
    handleFormSubmission,
    setIsSubmittingLead,
    setCurrentStepId,
    isEditing,
    handleEditConfirm,
    piiErrors,
    handleEdit,
    setPii,
    pii,
  };
};

export default useProgramSubmissionFlow;
