export type ApplicationCta = {
  url: string;
  label: string;
};

export type Submission = {
  programId: number;
  schoolId: number;
};

export type UserPreferencesAndMilitary = Array<{ key: string; label: string }>;

export enum FlowType {
  DRAWER = 'DRAWER',
  POPUP = 'POPUP',
}
