import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import type { UserPreferencesAndMilitary } from '../../types';
import ResultList from '../ResultList';
import styles from './EmbeddedRecommender.module.css';

type Props = {
  firstName?: string;
  subject?: string;
  results?: VoyagerResult[];
  onProgramCardClick: (result: VoyagerResult) => void;
  isCoveredByElement?: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  handleCheckIfProgramIsSubmitted: (result: VoyagerResult) => boolean;
};

const EmbeddedRecommender: FC<Props> = ({
  firstName,
  subject,
  results = [],
  onProgramCardClick,
  isCoveredByElement,
  userPreferencesAndMilitary,
  handleCheckIfProgramIsSubmitted,
}) => (
  <>
    {results?.length > 0 && (
      <section className={styles.embeddedRecommender}>
        <div className={styles.embeddedRecommenderContainer}>
          <>
            <header>
              <span>Hi {firstName}! Ready to switch careers?</span>
              <h2>
                Your Top Matches for <br /> {subject}
              </h2>
            </header>
            <div className={styles.embeddedRecommenderResults}>
              <ResultList
                results={results}
                onProgramCardClick={onProgramCardClick}
                isCoveredByElement={isCoveredByElement}
                userPreferencesAndMilitary={userPreferencesAndMilitary}
                handleCheckIfProgramIsSubmitted={handleCheckIfProgramIsSubmitted}
                eventingOverrides={{
                  location: 'voyager-recommender-embedded',
                  customDimensions: [{ key: 'isUserRequested', value: 'true' }],
                  formatSubtype: 'grid',
                }}
              />
            </div>
          </>
        </div>
      </section>
    )}
  </>
);

export default EmbeddedRecommender;
