type ProgramFormFieldsConfigOption = {
  label: string;
  value: string | number;
};

type ProgramFormFieldsConfigQuestion = {
  question: string;
  options: ProgramFormFieldsConfigOption[];
};

type ProgramFormFieldsConfig = {
  yearsOfProgramRelatedExperience?: ProgramFormFieldsConfigQuestion;
  undergraduateMajor?: ProgramFormFieldsConfigQuestion;
  gpa?: ProgramFormFieldsConfigQuestion;
};

type Config = {
  [programId: number]: ProgramFormFieldsConfig;
};

const russelSageCollegeProgramFormFieldsConfig: ProgramFormFieldsConfig = {
  yearsOfProgramRelatedExperience: {
    question: 'How many hours of clinical observation under the supervision of an occupational therapist do you have?',
    options: [
      { label: '0-9', value: 0 },
      { label: '10-19', value: 0.001 },
      { label: '20-29', value: 0.002 },
      { label: '30+', value: 0.003 },
    ],
  },
};

const albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig: ProgramFormFieldsConfig = {
  undergraduateMajor: {
    question: 'What is your educational background?',
    options: [
      { label: 'Biology', value: 0 },
      { label: 'Chemistry', value: 0.001 },
      { label: 'Physics', value: 0.002 },
      { label: 'Mathematics', value: 0.003 },
      { label: 'Statistics', value: 0.004 },
      { label: 'Behavioral Sciences', value: 0.005 },
      { label: 'Other', value: 0.006 },
    ],
  },
};

const lynnUniversityProgramFormFieldsConfig: ProgramFormFieldsConfig = {
  gpa: {
    question: 'Do you remember what your undergraduate GPA was?',
    options: [
      { label: '2.0 and above', value: 2 },
      { label: '1.99 and below', value: 1 },
    ],
  },
};

const programFormFieldConfig: Config = {
  // Russell Sage College programs
  35387: russelSageCollegeProgramFormFieldsConfig,
  35388: russelSageCollegeProgramFormFieldsConfig,
  35485: russelSageCollegeProgramFormFieldsConfig,
  35389: russelSageCollegeProgramFormFieldsConfig,
  // Albany College of Pharmacy and Health Sciences programs
  35954: albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig,
  35957: albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig,
  35955: albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig,
  35956: albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig,
  35953: albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig,
  35951: albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig,
  35952: albanyCollegeOfPharmacyAndHealthSciencesProgramFormFieldsConfig,
  // Lynn University programs
  35406: lynnUniversityProgramFormFieldsConfig,
  31155: lynnUniversityProgramFormFieldsConfig,
  35403: lynnUniversityProgramFormFieldsConfig,
  31601: lynnUniversityProgramFormFieldsConfig,
  31159: lynnUniversityProgramFormFieldsConfig,
  31605: lynnUniversityProgramFormFieldsConfig,
  31603: lynnUniversityProgramFormFieldsConfig,
  35405: lynnUniversityProgramFormFieldsConfig,
  31607: lynnUniversityProgramFormFieldsConfig,
  31165: lynnUniversityProgramFormFieldsConfig,
  35402: lynnUniversityProgramFormFieldsConfig,
  36016: lynnUniversityProgramFormFieldsConfig,
  35566: lynnUniversityProgramFormFieldsConfig,
  35533: lynnUniversityProgramFormFieldsConfig,
  31167: lynnUniversityProgramFormFieldsConfig,
  31591: lynnUniversityProgramFormFieldsConfig,
  31169: lynnUniversityProgramFormFieldsConfig,
  35532: lynnUniversityProgramFormFieldsConfig,
  31163: lynnUniversityProgramFormFieldsConfig,
  35404: lynnUniversityProgramFormFieldsConfig,
};

export default programFormFieldConfig;
