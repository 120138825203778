export function paragraphToSentencesArray(paragraph: string): string[] {
  const sentences = paragraph.split(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?|!)\s/);
  return sentences;
}

export const highLightKeywords = (text: string, keywords?: string[]): string => {
  if (!keywords) return text;
  // take a string and replace the keywords with a <b>{word}<b>
  const regex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');
  return text.replace(regex, '<b>$1</b>');
};
