import { type FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import prospectScriptConfig from '@core/config/prospectScriptSchoolIds.json';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { computeDcsConsolidation, programDifference } from '@core/hooks/useDCSConsolidation';
import { setContactId } from '@core/reducers/contactSlice';
import { selectDcs } from '@core/reducers/dcsSlice';
import { selectAllInputs, selectInput } from '@core/reducers/inputsSlice';
import {
  selectIsRecommenderPopupOpen,
  selectLeadDelivery,
  selectShowDuplicatePopup,
  setIsRecommenderPopupOpen,
} from '@core/reducers/matchesSlice';
import { selectQueryParam } from '@core/reducers/queryParamsSlice';
import { saveContact, SaveContactResult } from '@core/services/hub';
import { PageActions } from '@core/services/newRelic';
import { getSchoolConfig } from '@core/services/schoolConfig';
import type { VoyagerRecommenderResult, VoyagerResult } from '@core/ts/results';
import buildLeadObject, { type BuildLeadObjectInputs } from '@core/utils/buildLeadObject';

import RevampLoading from '../edu-flow-2/components/Loading/index';
import type { SchoolConfigData } from '../edu-match/components/ExitStrategy/type';
import EduResultsUI from './EduResultsUI';
import useResults from './hooks/useResults';

type Props = {
  experience: 'edu-results' | 'bme';
};

const EduResults: FC<Props> = ({ experience }) => {
  const dispatch = useDispatch();
  const userFirstName: string = useSelector((state) => selectInput(state, 'firstName'));
  const showRecommenderPopup = useSelector(selectIsRecommenderPopupOpen);
  const showDuplicatePopup = useSelector(selectShowDuplicatePopup);
  const dcs = useSelector(selectDcs);
  const {
    dcsCategories: [category],
    dcsDegrees: [degree],
    dcsSubjects: [subject],
  } = dcs;

  const inputs: Record<string, any> = useSelector(selectAllInputs);

  const { currentPrograms } = useSelector(selectLeadDelivery);

  // Prospect script schools
  const currentProgramsIdList = currentPrograms?.map((cp) => cp?.school?.id);
  const isActiveProspectScriptRequired = currentProgramsIdList?.some((cp) => prospectScriptConfig.includes(cp));

  const {
    loading,
    searchClient,
    showEmbeddedRecommender,
    recommenderResults,
    exactResults,
    relatedResults,
    fetchRecommenderResults,
    fetchExactResults,
    fetchRelatedResults,
    enhancedSchoolData,
  } = useResults({
    dcs,
    inputs,
  });

  const flags = useFeatureFlags();
  const resultsPageEngagement = flags.resultsPageEngagementTest;
  const showAdVerbiageTest = flags?.adVerbiageTest === 'test';
  const showUgToGradResultsPageABTest = flags?.voyagerGradResultsPage === 'test';
  const isGradResultsTest = flags?.voyagerGradResultsPage === 'test';
  const showUserExperienceSurvey = flags?.userExperienceSurvey === 'test';
  const isBestMatchExperience = experience === 'bme';

  const publisher = useSelector((state) => selectQueryParam(state, 'publisher'));

  const [schoolConfigRegistry, setSchoolConfigRegistry] = useState<Record<string, SchoolConfigData>>({});

  // Fetch Recommender, Related Results
  useEffect(() => {
    /* Fetch Recommended results and filter them out of exact matches */
    (async () => {
      const recommendedResults = await fetchRecommenderResults();
      if (recommendedResults?.recommender?.data) dispatch(setIsRecommenderPopupOpen(true));

      await fetchExactResults(recommendedResults.recommender as VoyagerRecommenderResult);
      await fetchRelatedResults();

      if (experience === 'bme') {
        const allSchoolIds = [
          ...(recommendedResults?.spotlight?.data ?? []).map((result) => result.school.id),
          ...(recommendedResults?.recommender?.data ?? []).map((result) => result.school.id),
          ...(exactResults ?? []).map((result) => result.school.id),
          ...(relatedResults ?? []).map((result) => result.school.id),
        ];

        const uniqueSchoolIds = Array.from(new Set(allSchoolIds));
        Promise.all(uniqueSchoolIds.map(getSchoolConfig)).then((schoolConfigs) => {
          setSchoolConfigRegistry(schoolConfigs.reduce((acc, config) => ({ ...acc, [config.id]: config }), {}));
        });
      }
    })();

    PageActions.ResultsLoaded({
      resultsPage: 'eduResults',
      anonymousId: window?._Cohesion.anonymousId,
      sessionId: window?._Cohesion.sessionId,
    });
  }, [resultsPageEngagement]);

  useEffect(() => {
    if (!flags.useContactDb) return;
    saveContact({
      degree,
      category,
      subject,
      cohesionSessionId: window?._Cohesion?.sessionId,
      lead: buildLeadObject(inputs as BuildLeadObjectInputs),
      publisher,
    }).then((res: SaveContactResult) => {
      if (res?.data?.contactId) {
        dispatch(setContactId(res.data.contactId));
      }
    });
  }, [inputs, degree, category, subject, flags]);

  /*
   * DCS Consolidation hook that grabs unique hits from related matches and consolidates them into exact matches
   * Returns a list of unique matches
   */
  // DCS consolidation Flag
  const consolidatedResults = computeDcsConsolidation<VoyagerResult>({
    degree,
    exactMatches: exactResults as VoyagerResult[],
    relatedMatches: relatedResults as VoyagerResult[],
  });

  if (loading || !exactResults) {
    return <RevampLoading />;
  }

  const dcsConsolidationResults = [...exactResults, ...consolidatedResults];

  const unborrowedMatches = programDifference<VoyagerResult>(
    relatedResults as VoyagerResult[],
    dcsConsolidationResults
  );

  const recommenderData = recommenderResults?.recommender?.data;
  const popupData = recommenderResults?.spotlight?.data || recommenderResults?.recommender?.data;
  const popupEventing = recommenderResults?.spotlight?.eventing || recommenderResults?.recommender?.eventing;

  const props = {
    showEmbeddedRecommender,
    recommenderResults,
    recommenderData,
    userFirstName,
    isActiveProspectScriptRequired,
    subject,
    showDuplicatePopup,
    dcsConsolidationResults,
    showRecommenderPopup,
    searchClient,
    popupData,
    popupEventing,
    enhancedSchoolData,
    showAdVerbiageTest,
    exactResults,
    consolidatedResults,
    showUgToGradResultsPageABTest,
    unborrowedMatches,
    isGradResultsTest,
    showUserExperienceSurvey,
    schoolConfigRegistry,
  };

  return <EduResultsUI {...props} />;
};

export default EduResults;
