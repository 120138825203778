import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { HiCheckCircle, HiMapPin, HiOutlineChevronDoubleRight } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import {
  selectRecommenderPopupListId,
  setHeclidMapEntry,
  setProductCorrelationIdMapEntry,
  setViewCorrelationIdMapEntry,
} from '@core/reducers/eventingSlice';
import { removeProgramFromList, setCurrentProgram } from '@core/reducers/matchesSlice';
import { mobiusRedirect } from '@core/services/mobius';
import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
import { VoyagerResult } from '@core/ts/results';
import getToken from '@core/utils/getToken';

import styles from './SpotlightProgram.module.css';
import Tags from './Tags';

type Props = {
  hit: VoyagerResult;
  eventingOverrides: Record<string, any>;
  setRecommenderStep: Dispatch<SetStateAction<string>>;
  closePopup: () => void;
};

const SpotlightProgram: FC<Props> = ({ hit, eventingOverrides, setRecommenderStep, closePopup }) => {
  const dispatch = useDispatch();
  const schoolConfig = useSchoolConfig(hit.school.id);

  const popupListId = useSelector(selectRecommenderPopupListId);
  const newViewCorrelationId = useMemo(() => uuid(), []);

  const { productLoaded, productViewed, productClicked } = useProductEvents({
    listId: popupListId,
    viewCorrelationId: newViewCorrelationId,
    product: {
      sku: String(hit.cap.id),
      variant: hit.program.degree.slug,
      productId: String(hit.program.id),
      name: hit.program.subject.slug,
      category: hit.program.category.slug,
      brand: hit.school.slug,
      position: 1,
      location: eventingOverrides?.location,
      formatType: 'app',
      formatSubtype: 'recommendation',
      positionEngine: 'algolia',
      ...eventingOverrides.product,
    },
    customDimensions: [{ key: 'isUserRequested', value: 'true' }, ...(eventingOverrides.customDimensions || [])],
  });

  const { elementClicked } = useElementEvents({
    webElement: {
      elementType: 'exit-element',
      text: 'Skip to all results',
      location: eventingOverrides?.location,
      htmlId: 'thank-you-button',
      name: 'skip-recommender',
    },
  });

  useEffect(() => {
    productLoaded();
    productViewed();
    dispatch(
      setViewCorrelationIdMapEntry({
        key: hit.program.id,
        value: newViewCorrelationId,
      })
    );
  }, []);

  const handleRequestInfo = async () => {
    dispatch(setCurrentProgram({ ...hit, leadSource: 'spotlight' }));

    const newProductCorrelationId = uuid();

    // add correlationId to map for LDS lead submission
    dispatch(
      setProductCorrelationIdMapEntry({
        key: hit.program.id,
        value: newProductCorrelationId,
      })
    );

    productClicked({
      correlationId: newProductCorrelationId,
    });

    // Mobius Redirect will fire the OutcomeTracked APP_VIEWED event
    const { heclid } = await mobiusRedirect({
      idToken: getToken(),
      productCorrelationId: newProductCorrelationId,
      link: hit.url,
      trackingContextOverride: {
        formatType: 'app',
        formatSubtype: 'recommendation',
      },
    });
    // add heclid to map for Mobius track if API call succeeds
    dispatch(setHeclidMapEntry({ key: hit.program.id, value: heclid }));

    setRecommenderStep('PII_CONFIRMATION');
  };

  const onClose = () => {
    dispatch(removeProgramFromList(hit));
    if (closePopup) closePopup();
    elementClicked();
  };

  if (!schoolConfig.data) return null;

  const { schoolName, logo, spotlight, tags } = schoolConfig.data;

  return (
    <div className={styles.spotlightProgram}>
      <div className={styles.programDetails}>
        <h4>We&apos;ve found a great school for you!</h4>

        {/* Program Card */}

        <section className={styles.programCard}>
          <div className={styles.logo}>
            <img src={logo} alt={`${schoolName} Logo`} />
          </div>
          <div>
            <p>{hit.program.name}</p>
            <h5>{hit.school.name}</h5>
            <Tags tags={tags} />
          </div>
        </section>

        {/* Highlighted Block */}
        <section className={styles.content}>
          <h3>{spotlight?.header}</h3>
          <div className={styles.highlights}>
            <h5>{spotlight?.blocks?.[0].title}</h5>
            <ul>
              {(spotlight?.blocks?.[0]?.list ?? []).map(({ text }) => (
                <li key={text}>
                  <HiCheckCircle size="1.5rem" /> {text}
                </li>
              ))}
            </ul>
          </div>
        </section>

        {/* Actions */}
        <section className={styles.actions}>
          <p>Request a call from an Admin to learn more.</p>
          <div className={styles.sponsoredResultsPopover}>
            <SponsoredResultsPopover position="above" />
          </div>
          <button className={styles.requestInfo} type="button" onClick={handleRequestInfo}>
            Explore Program
          </button>
          <button className={styles.skipToResults} type="button" onClick={onClose}>
            Skip to all results <HiOutlineChevronDoubleRight />
          </button>
        </section>
      </div>

      {/* Image */}
      <div className={styles.spotlightImage}>
        <img
          src="https://res.cloudinary.com/highereducation/image/upload/v1681154641/Voyager/spotlight/spotlight-hero.jpg"
          alt={`${schoolName} Campus`}
        />
      </div>
    </div>
  );
};

export default SpotlightProgram;
