import { PageActions } from '@core/services/newRelic';
import { VoyagerResult } from '@core/ts/results';

const sortResultsByStateERPI = (results: VoyagerResult[], stateAbbr: string): VoyagerResult[] => {
  const sortedResults: VoyagerResult[] = [...results].sort((result1: VoyagerResult, result2: VoyagerResult) => {
    const a = result2?.meta?.bonsaiERPIState[stateAbbr?.toLowerCase()] ?? 0;
    const b = result1?.meta?.bonsaiERPIState[stateAbbr?.toLowerCase()] ?? 0;
    return a - b;
  });

  // Override __position from algolia to position in bonsai sort
  const positionedResults: VoyagerResult[] = sortedResults.map((result: VoyagerResult, index: number) => ({
    ...result,
    __position: index + 1,
  }));
  // Fire new relic event with a list of sorted id's
  PageActions.BonsaieRPISortEnabled({
    schoolIdList: JSON.stringify(positionedResults.map((result: VoyagerResult) => result.school.id)),
  });
  // Update the __position given by the position engine
  return positionedResults;
};

export default sortResultsByStateERPI;
