import { type FC } from 'react';
import { ImSpinner8 } from 'react-icons/im';

import { type VoyagerResult } from '@core/ts/results';

import { FlowType } from '../../types';
import SchoolInfoHeader from '../SchoolInfoHeader';
import styles from './SubmissionLoader.module.css';

type Props = {
  result: VoyagerResult;
  flowType: FlowType;
};
const SubmissionLoader: FC<Props> = ({ result, flowType }) => (
  <>
    {flowType === FlowType.POPUP && <SchoolInfoHeader result={result} />}
    <div className={styles.submitInfoContainer}>
      <ImSpinner8 size={28} className={styles.loadingSpinner} />
      <div className={styles.submitInfoText}>Submitting your info</div>
    </div>
  </>
);

export default SubmissionLoader;
