import { postGetLeadSchema } from '@core/services/leadDelivery';
import type { VoyagerResult } from '@core/ts/results';
import buildLeadObject, { type BuildLeadObjectInputs } from '@core/utils/buildLeadObject';

const schemaCache: Record<string, unknown> = {};

type GetSchemaRegistry = (params: {
  results: VoyagerResult[];
  inputs: BuildLeadObjectInputs;
}) => Promise<Record<string, unknown>>;

type FetchSchemaForSchool = (params: { schoolId: number; programId: number; body: unknown }) => Promise<unknown>;
const fetchSchemaForProgram: FetchSchemaForSchool = async ({ programId, schoolId, body }) => {
  if (schemaCache[programId]) {
    return;
  }

  const schema = await postGetLeadSchema({ schoolId, programId, body });
  schemaCache[programId] = schema;
};

const getSchemaRegistry: GetSchemaRegistry = async ({ results, inputs }) => {
  const body = {
    lead: buildLeadObject(inputs),
    trackingContext: {
      publisher: window.location.host,
      anonymousId: window._Cohesion.anonymousId,
      url: inputs?.sourceUrl?.value,
      experience: 'Voyager',
    },
  };

  await Promise.all(
    results.map(async (result) =>
      fetchSchemaForProgram({ schoolId: result.school.id, programId: result.program.id, body })
    )
  );

  return schemaCache;
};

export default getSchemaRegistry;
