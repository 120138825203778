import { type FC } from 'react';

import cn from '@core/utils/classNames';

import OutlineButton from '../../CtaButtons/OutlineButton';
import Button from '../../ui/Button';
import BaseCard from '../BaseCard';
import type { ProgramCardProps } from '../types';
import styles from './MatchesCard.module.css';

const NextStepsButton: FC = () => <OutlineButton>Next Steps</OutlineButton>;

const ApplicationCtaButton: FC = () => (
  <Button className={cn(styles.applyNowButton, styles.gapButton)}>
    <div>Apply Now</div> +
  </Button>
);

const MatchesCard: FC<ProgramCardProps> = ({ hasApplicationCta, hasProgramBeenSubmitted, ...props }) => {
  const SubmittedButton = hasApplicationCta ? ApplicationCtaButton : NextStepsButton;
  return (
    <BaseCard {...props}>
      {hasProgramBeenSubmitted ? (
        <SubmittedButton />
      ) : (
        <Button className={styles.gapButton} primary>
          <div>Explore Program</div> +
        </Button>
      )}
    </BaseCard>
  );
};

export default MatchesCard;
