import { MonarchClient, MonarchFailedRequestError } from '@redventures/monarch-sdk';
import { type FC, type ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { newRelicCustomAttribute, newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';

import { type MonarchEditableAsset, type MonarchPsychBannerTest } from './types';

export type Flags = {
  singleScreenPIITest?: 'test' | 'control';
  loading?: boolean;
  phoneValidation?: boolean;
  useContactDb?: boolean;
  useEAB?: boolean;
  testEAB?: boolean;
  cypressTest?: boolean;
  inflowConversionTest?: 'test' | 'control';
  resultsPageEngagementTest?: 'test' | 'control';
  voyagerEmailConsentBox?: boolean;
  useEMCC?: boolean;
  testEMCC?: boolean;
  personalizationQuestionPursuingHigherEducation?: MonarchEditableAsset | false | 'conditions_not_met';
  personalizationQuestionImportantFactors?: MonarchEditableAsset | false | 'conditions_not_met';
  userExperienceSurvey?: 'test' | 'control' | 'conditions_not_met';
  matchContextTest?: 'test' | 'control' | 'conditions_not_met';
  voyagerNoHighSchool?: boolean;
  voyagerGradResultsPage?: 'test' | 'control';
  conversionCtaCopyUnderGrad?: 'test' | 'control';
  bestMatchExperience?: 'test' | 'control';
  psychBannerTest?: MonarchPsychBannerTest;
};

export type FlagKey = keyof Flags;

export type QueryParams = Flags & {
  degree: string;
  category: string;
  subject: string;
};

const FeatureFlagsContext = createContext<any>({});

export const parseQueryparamValue = (value: string) => {
  if (value === 'true' || value === '1' || value === 'on') return true;

  if (value === 'false' || value === '0' || value === 'off') return false;

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const parseQueryParams = (): QueryParams => {
  const { searchParams } = new URL(window.location.href);

  const slugify = (str: string) =>
    str
      ?.replace(/[^a-z ]/gi, '')
      ?.replace(/\s+/g, '-')
      ?.toLowerCase();

  return Array.from(searchParams.keys()).reduce<QueryParams>((accumulator, key) => {
    if (key.startsWith('flag_')) {
      accumulator[key.replace('flag_', '')] = parseQueryparamValue(searchParams.get(key) as string);
    }

    if (key === 'dcs[degrees][]') {
      accumulator.degree = slugify(parseQueryparamValue(searchParams.get(key) as string));
    }

    if (key === 'dcs[categories][]') {
      accumulator.category = slugify(parseQueryparamValue(searchParams.get(key) as string));
    }

    if (key === 'dcs[subjects][]') {
      accumulator.subject = slugify(parseQueryparamValue(searchParams.get(key) as string));
    }

    return accumulator;
  }, {} as QueryParams);
};

const VOYAGER_FEATURE_FLAGS_RULESET_ID = 'b5108943-8cf9-4655-b483-99c2dfec10cd';
const MONARCH_SOURCE_ID = '321290ba-5196-4a62-b70e-b48ce0cb99af';
const MONARCH_CLIENT_TOKEN = '2d1ae152-a564-4db4-abcb-e6049147c2ef';

export const FeatureFlagsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [flags, setFlags] = useState<Flags>({ loading: true });

  useEffect(() => {
    const load = async () => {
      const monarchClient: MonarchClient = new MonarchClient(MONARCH_SOURCE_ID, MONARCH_CLIENT_TOKEN, {
        timeout: 5000,
      });

      const { degree, category, subject, ...queryParamFlags } = parseQueryParams();

      const monarchFlags = await monarchClient
        .evaluateRuleSet<Flags>(
          VOYAGER_FEATURE_FLAGS_RULESET_ID,
          { degree, category, subject, publisher: window.location.hostname.replace('www.', ''), ...queryParamFlags },
          { sessionId: window._Cohesion?.sessionId }
        )
        .catch((e: MonarchFailedRequestError) => {
          newRelicNoticeError(nrErrorMap.MONARCH_CLIENT, e.innerErr ?? e);
          return {};
        });

      const resolvedFlags = { ...monarchFlags, ...queryParamFlags };

      newRelicCustomAttribute(`voyager_degree`, degree);
      newRelicCustomAttribute(`voyager_category`, category);
      newRelicCustomAttribute(`voyager_subject`, subject);

      Object.keys(resolvedFlags).forEach((key) => {
        newRelicCustomAttribute(`flag_${key}`, resolvedFlags[key]);
      });
      setFlags({ ...resolvedFlags, loading: false });
      window.localStorage.setItem('featureFlags', JSON.stringify(resolvedFlags));
    };

    load().catch(() => setFlags({ loading: false }));
  }, []);

  return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};

export function useFeatureFlags<T = Flags>(): T {
  return useContext(FeatureFlagsContext);
}

export default FeatureFlagsProvider;
