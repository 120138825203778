import { type FC, type ReactNode, useEffect, type KeyboardEvent } from 'react';
import { HiXMark } from 'react-icons/hi2';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import cn from '@core/utils/classNames';

import styles from './Drawer.module.css';

type Props = {
  children: ReactNode;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  setOpen: (open: boolean) => void;
};

const Drawer: FC<Props> = ({ children, open, onOpen, onClose, setOpen }) => {
  const { elementClicked } = useElementEvents();
  useEffect(() => {
    if (open) {
      // when drawer is opened:
      document.body.style.overflow = 'hidden';
      onOpen?.();
    } else {
      // when drawer is closed:
      document.body.style.overflow = '';
    }
  }, [onOpen, open, setOpen]);

  const onDrawerClick = () => {
    elementClicked({
      webElement: {
        location: 'drawer',
        name: 'exit-button',
        htmlId: 'voyager-takeover-exit',
      },
    });
    setOpen(false);
    onClose?.();
  };

  const handleDrawerEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onDrawerClick();
    }
  };
  return (
    <>
      {open && (
        <div
          className={styles.overlay}
          role="button"
          onClick={onDrawerClick}
          tabIndex={0}
          onKeyDown={handleDrawerEnter}
        />
      )}
      <aside className={cn(styles.drawer, open && styles.opened)}>
        <div className={styles.content}>
          <button className={styles.closeBtn} type="button" onClick={onDrawerClick}>
            <HiXMark size={25} strokeWidth={1} />
          </button>
          {open && children}
        </div>
      </aside>
    </>
  );
};

export default Drawer;
