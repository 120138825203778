import { type IdSchema } from '@rjsf/utils';
import { type Dispatch, type SetStateAction, type FC } from 'react';

import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import type { VoyagerResult } from '@core/ts/results';

import useProgramSubmissionFlow from '../../hooks/useProgramSubmissionFlow';
import { FlowType, type ApplicationCta, type UserPreferencesAndMilitary } from '../../types';
import AdditionalQuestions2 from '../AdditionalQuestions2';
import Drawer from '../Drawer';
import DrawerExperienceHeaderDesktop from '../DrawerExperienceHeaderDesktop';
import DrawerExperienceHeaderMobile from '../DrawerExperienceHeaderMobile';
import ExitStrategyComponent from '../ExitStrategy';
import MobileStickyCta from '../MobileStickyCta';
import NotQualified from '../NotQualified';
import PiiConfirmation from '../PiiConfirmation';
import ProgramInfoTabs from '../ProgramInfoTabs';
import { Step, Steps } from '../Steps';
import { StepTypes } from '../Steps/types';
import SubmissionLoader from '../SubmissionLoader';
import styles from './DrawerExperience.module.css';

type Props = {
  result: VoyagerResult;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  prqSchema?: IdSchema;
  relatedSchoolResults: VoyagerResult[];
  userInputs: VoyagerInputs;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onEdit: (result: VoyagerResult) => void;
  onFlowExit: () => void;
  hasCurrentProgramBeenSubmitted: boolean;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const DrawerContent: FC<Omit<Props, 'open' | 'setOpen' | 'onClose'>> = ({
  result,
  onFormSubmit,
  relatedSchoolResults,
  prqSchema,
  userInputs,
  onProgramCtaClick,
  onFlowExit,
  onEdit,
  hasCurrentProgramBeenSubmitted,
  currentResultApplicationCta,
  isHeclidLoading,
  isCurrentResultApplicationCtaLoading,
  userPreferencesAndMilitary,
}) => {
  const lastPreference = userPreferencesAndMilitary?.[userPreferencesAndMilitary.length - 2]?.label ?? 'Affordability';

  const flowType = FlowType.DRAWER;

  const {
    goToPRQ,
    goToPogramSelect,
    handleProgramCtaClick,
    handlePRQFormSubmit,
    currentStepId,
    isSubmittingLead,
    handleFormSubmission,
    handleEditConfirm,
    handleEdit,
    pii,
    setPii,
    piiErrors,
  } = useProgramSubmissionFlow({
    userInputs,
    onFormSubmit,
    prqSchema,
    result,
    initialStep: StepTypes.PII_CONFIRMATION,
    onProgramCtaClick,
    hasCurrentProgramBeenSubmitted,
    onEdit,
  });

  const showMobileStickyCta = currentStepId === StepTypes.PII_CONFIRMATION;

  return (
    <div className={styles.submissionContainer}>
      <div className={styles.mobileHeader}>
        <DrawerExperienceHeaderMobile result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
      </div>
      <div className={styles.programInfoSectionDesktop}>
        <div className={styles.desktopHeader}>
          <DrawerExperienceHeaderDesktop result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
        </div>

        <ProgramInfoTabs
          showWhatsNextTab={hasCurrentProgramBeenSubmitted}
          result={result}
          relatedSchoolResults={relatedSchoolResults}
          lastUserPreference={lastPreference}
        />
      </div>

      <div className={styles.programSubmissionFlowContainer}>
        <Steps currentStepId={currentStepId}>
          <Step stepId={StepTypes.PII_CONFIRMATION}>
            <PiiConfirmation
              flowType={flowType}
              result={result}
              onFormSubmit={handleFormSubmission}
              onBackButtonClick={goToPogramSelect}
              onEdit={handleEdit}
              isHeclidLoading={isHeclidLoading}
              isSubmittingLead={isSubmittingLead}
              onEditConfirm={handleEditConfirm}
              pii={pii}
              piiErrors={piiErrors}
              setPii={setPii}
              isEditing={false}
            />
          </Step>
          <Step stepId={StepTypes.ADDITIONAL_QUESTIONS}>
            <AdditionalQuestions2
              flowType={flowType}
              selectedResult={result}
              prqSchema={prqSchema as IdSchema}
              onFormSubmit={handlePRQFormSubmit}
              eventingOverrides={{}}
              onBackButtonClick={() => handleProgramCtaClick(result, {})}
              result={result}
              isSubmittingLead={isSubmittingLead}
            />
          </Step>

          <Step stepId={StepTypes.EXIT_STRATEGY}>
            <ExitStrategyComponent
              onBackButtonClick={goToPRQ}
              result={result}
              firstName={userInputs?.firstName?.value}
              flowType={flowType}
              currentResultApplicationCta={currentResultApplicationCta}
              isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
            />
          </Step>

          <Step stepId={StepTypes.NOT_QUALIFIED}>
            <NotQualified flowType={flowType} result={result} onCtaClick={onFlowExit} />
          </Step>

          <Step stepId={StepTypes.LOADING}>
            <SubmissionLoader flowType={flowType} result={result} />
          </Step>
        </Steps>
        <div className={styles.programInfoSectionMobile}>
          <ProgramInfoTabs
            showWhatsNextTab={hasCurrentProgramBeenSubmitted}
            result={result}
            relatedSchoolResults={relatedSchoolResults}
            lastUserPreference={lastPreference}
          />
        </div>

        {showMobileStickyCta && <MobileStickyCta result={result} onSubmit={handleFormSubmission} onSkip={onFlowExit} />}
      </div>
    </div>
  );
};

const DrawerExperience: FC<Props> = ({ open, setOpen, onClose, ...otherprops }) => (
  <Drawer open={open} setOpen={setOpen} onClose={onClose}>
    <DrawerContent {...otherprops} />
  </Drawer>
);

export default DrawerExperience;
