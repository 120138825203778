import { useEffect, type FC } from 'react';
import { HiMapPin } from 'react-icons/hi2';
import { useInView } from 'react-intersection-observer';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import Popup from '@core/shared/components/Popup';
import type { VoyagerResult } from '@core/ts/results';

import Button from '../ui/Button';
import styles from './DuplicatePopup.module.css';

type Props = {
  show: boolean;
  onClose: () => void;
  result: VoyagerResult;
  otherProgramsForSchool: VoyagerResult[];
  onViewNextSteps: () => void;
};

const DuplicatePopup: FC<Props> = ({ show, onClose, result, otherProgramsForSchool, onViewNextSteps }) => {
  const { elementViewed } = useElementEvents();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  const handleView = () => {
    elementViewed({
      webElement: {
        location: 'modal',
        name: 'dupes-exit-experience',
        elementType: 'LINK',
        text: 'View next steps',
      },
    });

    elementViewed({
      webElement: {
        location: 'modal',
        name: 'dupe-info-drawer-close',
        elementType: 'BUTTON',
        text: 'close',
      },
    });
  };
  useEffect(() => {
    if (inView) {
      handleView();
    }
  }, [inView]);
  return (
    <Popup show={show} onClose={onClose}>
      <div className={styles.duplicatePopup} ref={ref}>
        <header className={styles.header}>
          <img
            src={`https://simple-storage-server.highereducation.com/${result.school.slug}.png?w=130&h=80`}
            alt={result?.school?.name}
            className={styles.logo}
          />
          <div className={styles.schoolNameAndLocation}>
            <h4>{result?.school?.name}</h4>
            {result?.school?.city && (
              <p>
                <HiMapPin />
                {result?.school?.city}, {result?.school?.state}
              </p>
            )}
          </div>
        </header>

        <h3 className={styles.mainHeading}>You&apos;ve already requested info from this school.</h3>

        <p>
          You requested information about: <b>{result.program.name}</b>
        </p>

        <p>
          {result.school.name} has many programs you may be interested in. Chat through different programs and
          opportunities with your school advisor.
        </p>

        <h4 className={styles.otherProgramsForSchoolHeader}>Other programs available:</h4>
        <ul className={styles.otherProgramsForSchoolList}>
          {otherProgramsForSchool?.map((result) => (
            <li key={result.program.id}>{result.program.name}</li>
          ))}
        </ul>

        <div className={styles.ctas}>
          <Button onClick={onClose} className={styles.close}>
            Close
          </Button>
          <Button onClick={onViewNextSteps} className={styles.viewNextSteps} primary>
            View Next Steps
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default DuplicatePopup;
