import { type FC } from 'react';

import styles from './AdvertisingSupportedCopy.module.css';

const AdvertisingSupportedCopy: FC = () => (
  <div className={styles.AdvertisingSupportedCopy}>
    All schools and programs featured on this page have been sponsored, meaning BestColleges receives compensation. This
    compensation may impact how, where, and in what order these sponsored schools appear. Program tags reflect your
    preferences for the schools that offer them, but the order of these programs is determined by a proprietary
    algorithm.
  </div>
);

export default AdvertisingSupportedCopy;
