import { GraduationYear } from '@core/ts/inputs';

type SendToCappexParams = {
  graduationYear: GraduationYear;
};
/**
 *
 * @param {SendToCappexParams} cappexInputs Cappex related input values
 * @param {string} cappexInputs.graduationYear User's graduation year for high school
 * @returns {boolean} Flag for whether or not to show the Cappex landing page
 */
const sendToCappex = ({ graduationYear }: SendToCappexParams): Boolean => {
  const today: Date = new Date();
  const curMonth: number = today.getMonth();
  const curYear: number = today.getFullYear();

  // Handles both number, string types
  const gradYear: number = parseInt(`${graduationYear}`, 10);

  // Edge case we get a non-numeric string
  if (Number.isNaN(gradYear)) return false;

  // IF user's highschool graduation date is in the distant future, we assume the prospect is underage and direct them to the Cappex landing page
  return (curMonth < 5 && gradYear > curYear) || (curMonth >= 5 && gradYear > curYear + 1);
};

export default sendToCappex;
