import { type SearchClient, type SearchIndex } from 'algoliasearch/lite';
import { useEffect, useRef, useState } from 'react';

import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import { ALGOLIA_BASE_INDEX_NAME, getSearchClient } from '@core/services/AlgoliaService/v2';
import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import type { VoyagerResult } from '@core/ts/results';

import fetchOtherProgramsForSchool from '../services/fetchOtherProgramsForSchool';

const handleError = (error: Error) => newRelicNoticeError(nrErrorMap.ALGOLIA_CLIENT, error);

const useFetchOtherProgramsForSchool = (inputs: VoyagerInputs) => {
  const { current: otherProgramsForSchoolCache } = useRef<Record<string, VoyagerResult[]>>({});
  const [searchIndex, setSearchIndex] = useState<SearchIndex | null>(null);

  useEffect(() => {
    if (inputs?.zip?.value && !searchIndex) {
      getSearchClient(inputs, false)
        .then((searchClient: SearchClient) => {
          const searchIndex = searchClient.initIndex(ALGOLIA_BASE_INDEX_NAME);
          setSearchIndex(searchIndex);
        })
        .catch(handleError);
    }
  }, [inputs]);

  const getOrFetchOtherProgramsForSchool = async (result: VoyagerResult) => {
    if (!searchIndex) {
      return undefined;
    }

    const otherProgramsForSchool = otherProgramsForSchoolCache[result.school.id];

    if (otherProgramsForSchool) {
      return otherProgramsForSchool;
    }

    otherProgramsForSchoolCache[result.school.id] = await fetchOtherProgramsForSchool({ searchIndex, result });

    return otherProgramsForSchoolCache[result.school.id];
  };

  return getOrFetchOtherProgramsForSchool;
};

export default useFetchOtherProgramsForSchool;
