import { type FC } from 'react';
import InputMask from 'react-input-mask';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import ErrorMessage from '@core/shared/components/ErrorMessage';
import cn from '@core/utils/classNames';

import styles from './PiiForm.module.css';
import type { PiiFormSectionTypes } from './type';
import { validateInputs } from './validateInputs';

const PiiFormSection: FC<PiiFormSectionTypes> = ({
  setEditMode,
  editMode,
  location,
  header = 'Your Information',
  piiLeadErrors = [],
  firstName,
  lastName,
  email,
  phone,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  onSubmit,
  formErrors,
  setFormErrors,
}) => {
  // flags
  const flags = useFeatureFlags();

  const hasLeadError = (targetField: string) => piiLeadErrors.findIndex(({ field }) => field === targetField) !== -1;
  const leadPhoneError = hasLeadError('phone');

  const { elementClicked } = useElementEvents({
    webElement: {
      elementType: 'card',
      location: 'drawer',
      name: 'edit-info',
      htmlId: 'voyager-takeover-edit-pii',
    },
  });
  const handleSetEditMode = () => {
    elementClicked();
    setEditMode(true);
  };

  const handleEditConfirm = async () => {
    elementClicked();
    setFormErrors([]);
    const errors = await validateInputs(firstName, lastName, email, phone);
    if (errors.length) {
      setFormErrors(errors);
      return;
    }

    setEditMode(false);
  };
  return (
    <>
      <div className={styles.header}>
        <h5>{header}</h5>
        <button
          data-testid={`${location}-popup-edit-review-pii-button`}
          className={formErrors && styles.buttonDisabled}
          type="button"
          onClick={() => (editMode ? handleEditConfirm() : handleSetEditMode())}
        >
          {!editMode ? 'Edit' : 'Done'}
        </button>
      </div>
      {!editMode ? (
        <div className={styles.infoGrid}>
          <b>Name:</b>
          <p>{`${firstName} ${lastName}`}</p>

          <b>Email:</b>
          <p>{email}</p>

          <b>Phone:</b>
          <p>{phone}</p>
        </div>
      ) : (
        <>
          {formErrors?.length !== 0 && (
            <>
              <p className={styles.formErrorText}>
                We were unable to save your information. Please correct the errors listed below.
              </p>
              {formErrors?.map((obj) => (
                <ErrorMessage isFieldGroup={false} message={`Make sure the ${obj.field} field is valid.`} />
              ))}
            </>
          )}

          <form onSubmit={onSubmit}>
            <input
              id="firstName"
              type="text"
              placeholder="First Name"
              data-testid="pii-form-first-name-input"
              defaultValue={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />

            <input
              type="text"
              placeholder="Last Name"
              data-testid="pii-form-last-name-input"
              defaultValue={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />

            <input
              type="email"
              placeholder="Email"
              data-testid="pii-form-email-input"
              defaultValue={email}
              onChange={(event) => setEmail(event.target.value)}
            />

            <InputMask
              className={leadPhoneError && styles.inputError}
              mask="(999) 999-9999"
              maskChar=""
              autoComplete="on"
              type="text"
              placeholder="Phone"
              data-testid="pii-form-phone-input"
              defaultValue={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
            {leadPhoneError && flags?.phoneValidation && (
              <div className={styles.piiLeadError}>
                <ErrorMessage isFieldGroup={false} message="Please provide your valid phone number." />
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
};

export { PiiFormSection };
