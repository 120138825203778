import { type IdSchema } from '@rjsf/utils';
import { type Dispatch, type SetStateAction, type FC } from 'react';
import { useSelector } from 'react-redux';

import { selectDcs } from '@core/reducers/dcsSlice';
import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import type { VoyagerResult } from '@core/ts/results';
import { isUnderGraduate } from '@core/utils/isUnderGraduate';

import { type ApplicationCta, type UserPreferencesAndMilitary } from '../../types';
import Drawer from '../Drawer';
import GradDrawer from './GradDrawer';
import UnderGradDrawer from './UnderGradDrawer';

type Props = {
  result: VoyagerResult;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  prqSchema?: IdSchema;
  relatedSchoolResults: VoyagerResult[];
  userInputs: VoyagerInputs;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onEdit: (result: VoyagerResult) => void;
  onFlowExit: () => void;
  hasCurrentProgramBeenSubmitted: boolean;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const DrawerContent: FC<Props> = (props) => {
  const {
    dcsDegrees: [degree],
  } = useSelector(selectDcs);
  const isGrad = !isUnderGraduate(degree);

  if (isGrad) {
    return <GradDrawer {...props} />;
  }
  return <UnderGradDrawer {...props} />;
};

const DrawerExperience: FC<Props> = (props) => {
  const { open, setOpen, onClose } = props;
  return (
    <Drawer open={open} setOpen={setOpen} onClose={onClose}>
      <DrawerContent {...props} />
    </Drawer>
  );
};

export default DrawerExperience;
