import { type IChangeEvent } from '@rjsf/core';
import { ErrorTransformer } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { type ReactNode, type FC, type FormEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import { selectHeclidMap, selectProductCorrelationIdMap } from '@core/reducers/eventingSlice';
import { selectAllInputs, setInput, setInputs } from '@core/reducers/inputsSlice';
import { addSubmission, removeProgramFromList } from '@core/reducers/matchesSlice';
import { submitLead } from '@core/services/leadDelivery';
import { PageActions, newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import { VoyagerResult } from '@core/ts/results';
import Diffy from '@core/utils/diffy';
import { phoneValidationFeatureFlagWrapper } from '@core/utils/functionWrappers/phoneValidationFeatureFlagWrapper';
import timer from '@core/utils/timer';

import { selectContactId } from '../../../reducers/contactSlice';
import BaseForm from './BaseForm';
import fields from './Fields';
import widgets from './Widgets';
import getTransformedUiSchema from './getTransformedUiSchema';
import { BaseFormProps } from './types';

type Props = {
  hit: VoyagerResult;
  originalSchema: any;
  prqSchema: any;
  children?: ReactNode;
  setPiiLeadErrors?: (errors: any) => void;
  setEditMode?: (editMode: boolean) => void;
  setStep?: (step: string) => void;
  formCorrelationId: string;
  onMount?: () => void;
  onFirstInteraction?: () => void;
  onSubmit?: () => void;
  onFormError: () => void;
  eventingOverrides?: Record<string, unknown>;
  Component?: FC<BaseFormProps>;
};

type OnSubmit = (data: IChangeEvent<any, any, any>, event: FormEvent<any>) => void;

const PostResultForm: FC<Props> = ({
  Component = BaseForm,
  hit,
  originalSchema,
  prqSchema,
  children = null,
  setPiiLeadErrors,
  setEditMode,
  setStep,
  onFormError,
  formCorrelationId,
  onMount,
  onFirstInteraction,
  eventingOverrides,
  onSubmit = () => {},
}) => {
  const [isFirstInteraction, setIsFirstInteraction] = useState<boolean>(true);
  const [submissionStatus, setSubmissionStatus] = useState<string | undefined>(undefined);
  const [uiSchema, setUiSchema] = useState<any>(null);
  const dispatch = useDispatch();
  const flags = useFeatureFlags();
  const inputs = useSelector(selectAllInputs);
  const heclidMap = useSelector(selectHeclidMap);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);

  const schoolConfig = useSchoolConfig(hit?.school?.id);

  const handleFirstInteraction = () => {
    if (isFirstInteraction) {
      setIsFirstInteraction(false);
      onFirstInteraction?.();
    }
  };

  const { formErrored } = useFormEvents();

  useEffect(() => {
    // Fetch the form field config for the program if available
    getTransformedUiSchema({ programId: hit?.program?.id, prqSchema }).then((transformedUiSchema) => {
      setUiSchema(transformedUiSchema);
    });

    handleFirstInteraction();
    onMount?.();
  }, []);

  const contactId = useSelector(selectContactId);

  const triggerLeadSubmission = async () => {
    setSubmissionStatus('LOADING');
    const actionParams = {
      ...flags,
      anonymousId: window._Cohesion.anonymousId,
      correlationId: productCorrelationIdMap[hit?.program?.id],
    };
    PageActions.LeadAttempt(actionParams);

    const leadInputs = {
      ...inputs,
      tcpaText: { key: 'tcpaText', value: schoolConfig?.data?.tcpa },
    };

    const res = await phoneValidationFeatureFlagWrapper({
      wrappedFunction: () =>
        submitLead(hit, leadInputs, heclidMap[hit.program.id], productCorrelationIdMap[hit.program.id], contactId),
      inputs: leadInputs,
      flag: !!flags?.phoneValidation,
    });

    if (res?.message === 'invalid_phone_line_type_intelligence') {
      setPiiLeadErrors?.((state) => [...state, { field: 'phone' }]);
      setEditMode?.(true);
      setSubmissionStatus('');
      onFormError?.();
      setStep?.('PII_CONFIRMATION');
      return;
    }
    if (res.message) {
      const error = JSON.parse(res.message);
      setSubmissionStatus('ERROR');
      formErrored({
        errorDetails: Object.values(error?.errors)?.toString(),
        errorMessage: error?.message,
        errorType: error?.status?.toString(),
      });
      // If a user submits a PRQ and they are unqualified, remove that unqualified property from the lead object
      // This will allow the user to submit the form again without the unqualified property

      dispatch(setInput({ key: Object.keys(error?.errors)?.toString(), value: undefined }));

      await timer(1800);
    } else {
      PageActions.LeadSuccess(actionParams);
      setSubmissionStatus('SUCCESS');
      await timer(1800);
      dispatch(addSubmission(hit));
    }

    dispatch(removeProgramFromList(hit));
    // update state -> remove programTrack
    dispatch(setInput({ key: 'programTrack', value: undefined }));
    onSubmit?.();
  };

  // This checks if we have all of the fields needed for the submission.
  // If we do, we automatically attempt to submit the lead
  useEffect(() => {
    if (hit?.program?.id) {
      const diffy = new Diffy(inputs);
      const diff = diffy.diffLdsSchema(originalSchema, hit?.leadSource);

      if (!diff && !submissionStatus) {
        triggerLeadSubmission(); // changes inputs
      }
    }
  }, [hit, inputs, submissionStatus]);

  // This updates the inputs, if all of the inputs are populated, the useEffect above will attempt the lead submission
  const handleSubmit: OnSubmit = async ({ formData }, e) => {
    e.preventDefault();
    if (formData === 'errors') return;

    // We need to get all of the properties in formData and convert them to a valid input for the setInputs dispatch
    // This includes flattening formData.location

    const currAcaAwardKey = uiSchema.currentAcademicAward?.['ui:options']?.currAcaAwardKey;

    const inputChanges = Object.entries(formData ?? {}).reduce((accumulator, [key, value]) => {
      if (key === 'location') {
        const locationData = Object.entries(formData.location ?? {}).map(([locationKey, locationValue]) => ({
          key: locationKey,
          value: locationValue,
        }));
        return [...accumulator, ...locationData];
      }
      if (key === 'currentAcademicAward') {
        // if IN_PROGRESS is selected, we populate inProgressAcademicAward
        if (value === 'IN_PROGRESS') {
          return [
            ...accumulator,
            {
              key: 'inProgressAcademicAward',
              value: {
                ...(inputs?.inProgressAcademicAward?.value ?? {}),
                [currAcaAwardKey]: value,
              },
            },
            {
              key: 'currentAcademicAward',
              value: {
                ...(inputs?.inProgressAcademicAward?.value ?? {}),
                [currAcaAwardKey]: null,
              },
            },
          ];
        }

        // else we populate the currentAcademicAward property
        return [
          ...accumulator,
          {
            key: 'currentAcademicAward',
            value: {
              ...(inputs?.currentAcademicAward?.value ?? {}),
              [currAcaAwardKey]: value,
            },
          },
          {
            key: 'inProgressAcademicAward',
            value: {
              ...(inputs?.inProgressAcademicAward?.value ?? {}),
              [currAcaAwardKey]: null,
            },
          },
        ];
      }

      return [
        ...accumulator,
        {
          key,
          value,
        },
      ];
    }, []);

    dispatch(setInputs(inputChanges));
  };

  // We need to memoize these to avoid unnecessary unmounts/mounts of the widget/fields
  const memoizedWidgets = useMemo(
    () => widgets(hit?.school?.slug, formCorrelationId, eventingOverrides),
    [hit?.school?.slug, formCorrelationId]
  );
  const memoizedFields = useMemo(
    () => fields(hit?.school?.slug, formCorrelationId, eventingOverrides),
    [hit?.school?.slug, formCorrelationId]
  );

  // Error message handler
  const transformErrors: ErrorTransformer = (errors) =>
    errors?.map((error) => {
      if (error?.params?.missingProperty) {
        error.message = `The ${error.params?.missingProperty
          .replace(/([A-Z])/g, ' $1')
          .trim()
          .toLowerCase()} field is required.`;
      } else {
        const providerProgramId = hit?.program?.id;
        newRelicNoticeError(nrErrorMap.POST_RESULT_FORM, error, { providerProgramId });
        error.message = 'Something went wrong, please try again later!';
      }
      return error;
    });

  // Don't render anything if no school.id is available
  if (!hit?.school?.id || !uiSchema) return null;

  const FormProps: BaseFormProps = {
    schema: prqSchema,
    widgets: memoizedWidgets,
    fields: memoizedFields,
    validator,
    uiSchema,
    onSubmit: handleSubmit,
    showErrorList: false,
    transformErrors,
    submissionStatus,
    hit,
  };

  return <Component {...FormProps} />;
};

export default PostResultForm;
