import { type FC } from 'react';
import { HiOutlineShieldCheck } from 'react-icons/hi';

import styles from './ExitStrategyCongratsBanner.module.css';

type Props = {
  firstName: string;
};

const ExitStrategyCongratsBanner: FC<Props> = ({ firstName }) => (
  <div className={styles.exitStrategyCongratsBanner}>
    <p className={styles.congratsTitle}>Congratulations, {firstName}!</p>

    <div className={styles.successTitle}>
      <span>
        <HiOutlineShieldCheck />
      </span>
      <p>Your info has been submitted successfully. A school advisor will call you soon.</p>
    </div>
  </div>
);

export default ExitStrategyCongratsBanner;
