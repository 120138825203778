import { useEffect, useState } from 'react';

type ReturnType = {
  isMobile: boolean;
};

const useCheckScreen = (): ReturnType => {
  const [width, setWidth] = useState(window?.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window?.innerWidth);
  };

  useEffect(() => {
    window?.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window?.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return { isMobile: width <= 768 };
};

export default useCheckScreen;
