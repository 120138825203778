import { type FC, type Dispatch, type SetStateAction } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import { FlowType } from '../../types';
import PiiConfirmationDrawer from './PiiConfirmationDrawer';
import PiiConfirmationPopup from './PiiConfirmationPopup';

type Props = {
  onFormSubmit: () => void;
  onBackButtonClick: () => void;
  flowType: FlowType;
  result: VoyagerResult;
  onEdit: () => void;
  isHeclidLoading: boolean;
  isSubmittingLead: boolean;
  onEditConfirm: () => void;
  pii: Record<string, string>;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  isEditing: boolean;
};

const PiiConfirmation: FC<Props> = ({
  result,
  flowType,
  onEdit,
  onFormSubmit,
  onBackButtonClick,
  isHeclidLoading,
  isSubmittingLead,
  onEditConfirm,
  piiErrors,
  pii,
  setPii,
  isEditing,
}) => {
  const PiiConfirmationComponent = flowType === FlowType.POPUP ? PiiConfirmationPopup : PiiConfirmationDrawer;
  return (
    <PiiConfirmationComponent
      onFormSubmit={onFormSubmit}
      isHeclidLoading={isHeclidLoading}
      pii={pii}
      result={result}
      piiErrors={piiErrors}
      setPii={setPii}
      isEditing={isEditing}
      onEdit={onEdit}
      onEditConfirm={onEditConfirm}
      onBackButtonClick={onBackButtonClick}
      isSubmittingLead={isSubmittingLead}
    />
  );
};

export default PiiConfirmation;
