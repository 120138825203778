import React, { useState, type MouseEventHandler } from 'react';
import { TiLocation } from 'react-icons/ti';

import LeadSubmittedCta from '@core/blocks/eduResults/components/LeadSubmittedCta';
import { Cta } from '@core/blocks/eduResults/hooks/useGetApplicationCtaData';
import type { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import usePsychCardTest from '../usePsychCardTest';
import styles from './UndergradPsychCard.module.css';

/** JIRA TICKET: https://redventures.atlassian.net/browse/EDUN-1171?atlOrigin=eyJpIjoiNDNiYjA4Y2Q1NWQ4NGE2OTliMGRmMzgxNTIzZDkyM2UiLCJwIjoiaiJ9 */

export type CardProps = {
  isCoveredByElement: boolean;
  isRecommender?: boolean;
  isSpotlight?: boolean;
  school: VoyagerResult['school'];
  program: VoyagerResult['program'];
  tags: {
    isPrivateTag: boolean;
    isOnlineTag: boolean;
    isNonprofitTag: boolean;
  };
  ctaLabel?: string;
  handleProductClick?: MouseEventHandler<HTMLButtonElement>;
  cardRef?: React.MutableRefObject<HTMLDivElement>;
  isProgramSubmitted?: boolean;
  cta: Cta;
  viewCorrelationId: string;
  position: number;
};

const UndergradPsychCard: React.FC<CardProps> = ({
  handleProductClick,
  cardRef,
  school,
  isRecommender,
  tags,
  program,
  isSpotlight,
  isProgramSubmitted,
  ctaLabel,
  cta,
  viewCorrelationId,
  position,
  isCoveredByElement,
}) => {
  // component state
  const [showDescription, setShowDescription] = useState(false);

  const handleDescriptionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    setShowDescription(!showDescription);
    e.stopPropagation();
  };

  const { PsychCardDescription, PsychCardDescriptionCta } = usePsychCardTest({
    showDescription,
    snippet: program?.snippet,
    onClick: handleDescriptionClick,
    viewCorrelationId,
    position,
    isCoveredByElement,
  });
  return (
    <button type="button" onClick={handleProductClick} className={cn(styles.card, isRecommender && styles.rec)}>
      <div ref={cardRef} className={styles.cardContent}>
        {showDescription ? (
          <p>{school.name}</p>
        ) : (
          <div className={styles.cardHeader}>
            <img
              src={`https://simple-storage-server.highereducation.com/${school.slug}.png?w=130&h=80`}
              alt={school.name}
            />

            <div className={styles.cardSchool}>
              <h3>{school.name}</h3>
              {school.city && school.state && (
                <div className={styles.cardLocation}>
                  <TiLocation size={16} />
                  <p>
                    {school.city}, {school.state}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={styles.cardSubheader}>
          <h4 className={styles.cardProgram}>{program.name}</h4>
          <ul className={styles.flagsList}>
            <li>{tags?.isPrivateTag ? <>Private</> : <>Public</>}</li>
            <li>{tags?.isOnlineTag ? <>Online</> : <>Oncampus</>}</li>
            <li>{tags?.isNonprofitTag ? <>Nonprofit</> : <>Forprofit</>}</li>
          </ul>
        </div>

        <section className={styles.cardBody}>
          {/* program description */}
          <PsychCardDescription>
            <p className={styles.cardBlurb}>{program.snippet}</p>
          </PsychCardDescription>
          {/* description expand/collapse trigger */}

          <PsychCardDescriptionCta />
        </section>

        {!isProgramSubmitted ? (
          <div data-testid="learn-more-btn" className={cn(styles.learnMoreButton, isSpotlight && styles.isSpotlight)}>
            {ctaLabel || 'Learn More'}
          </div>
        ) : (
          <LeadSubmittedCta cta={cta} />
        )}
      </div>
    </button>
  );
};

export default UndergradPsychCard;
