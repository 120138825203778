import type { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import { getSchoolConfig } from '@core/services/schoolConfig';

type SchoolConfigRegistry = {
  [schoolId: string]: SchoolConfigData;
};

const fetchSchoolConfigRegistry = async (schoolIds: number[]): Promise<SchoolConfigRegistry> => {
  const schoolConfigs = await Promise.all(schoolIds.map(getSchoolConfig));
  const schoolConfigRegistry = schoolConfigs.reduce((acc, config) => ({ ...acc, [config?.schoolId]: config }), {});
  return schoolConfigRegistry;
};

export default fetchSchoolConfigRegistry;
