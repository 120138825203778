import ProductFlags from '@edu-match/components/ProductFlags';
import { type FC, useState, useEffect, useMemo, useRef, type MutableRefObject, type MouseEventHandler } from 'react';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import useIntersectionObserver from '@core/hooks/useIntersectionObserver';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
// Reducers selectors/actions
import {
  setViewCorrelationIdMapEntry,
  selectListId,
  setProductCorrelationIdMapEntry,
} from '@core/reducers/eventingSlice';
import {
  selectSubmissions,
  setLeadDelivery,
  selectIsRecommenderPopupOpen,
  setShowDuplicatePopup,
} from '@core/reducers/matchesSlice';
import usePsychCardTest from '@core/shared/components/PsychCardTest/usePsychCardTest';
import type { Hit } from '@core/ts/algolia';
import type { CustomDimension } from '@core/ts/cohesion';
// Utils
import cn from '@core/utils/classNames';

import { SchoolConfigData } from '../ExitStrategy/type';
import styles from './Card.module.css';

type Props = {
  dataTestId: string;
  hit: Hit;
  eventingOverrides?: {
    customDimensions: CustomDimension[];
    product?: {
      location?: string;
      position?: number;
    };
  };
};

const Card: FC<Props> = ({ hit, eventingOverrides, dataTestId }) => {
  const { data }: SchoolConfigData = useSchoolConfig(hit.school.id);
  // boolean to show recommender popup
  const showRecommendedResults = useSelector(selectIsRecommenderPopupOpen);

  // Eventing State properties
  const listId = useSelector(selectListId);

  // Matches State properties
  const submissions = useSelector(selectSubmissions);

  // Action Dispatcher
  const dispatch = useDispatch();

  // component state
  const [showDescription, setShowDescription] = useState(false);

  const isProgramSubmitted = submissions.some((s) => s.program.id === hit.program.id);

  const viewCorrelationId = useMemo(() => uuid(), []);

  const cardRef = useRef<HTMLButtonElement>();

  const isInView = useIntersectionObserver(cardRef);

  // grabs product event
  const { productLoaded, productViewed, productClicked } = useProductEvents({
    listId,
    viewCorrelationId,
    product: {
      sku: String(hit.cap.id),
      variant: hit.program.degree.slug,
      productId: String(hit.program.id),
      name: hit.program.subject.slug,
      category: hit.program.category.slug,
      brand: hit.school.slug,
      position: hit.__position,
      location: 'voyager-results-page',
      formatType: 'app',
      formatSubtype: 'grid',
      positionEngine: 'algolia',
      ...eventingOverrides?.product,
    },
    customDimensions: eventingOverrides?.customDimensions,
  });

  useEffect(() => {
    if (!isProgramSubmitted && !showRecommendedResults) {
      productLoaded();
      dispatch(setViewCorrelationIdMapEntry({ key: [hit.program.id], value: viewCorrelationId }));
    }
  }, [showRecommendedResults]);

  // fire product clicked when is in view and the recommender popup is closed
  useEffect(() => {
    if (isInView && !showRecommendedResults && !isProgramSubmitted) {
      productViewed();
    }
  }, [isInView, showRecommendedResults]);

  const handleProductClick = () => {
    const isSchoolSubmitted = !!submissions?.find((submission) => submission.school.id === hit.school.id);
    // If school is already submitted, show duplicate popup
    if (isSchoolSubmitted) {
      dispatch(
        setLeadDelivery({
          show: false,
          currentPrograms: [hit],
          showExitStep: false,
        })
      );
      dispatch(setShowDuplicatePopup(true));
      return;
    }

    // If program hasn't been submitted, show lead delivery slide-out
    const productCorrelationId = uuid();
    dispatch(setProductCorrelationIdMapEntry({ key: hit.program.id, value: productCorrelationId }));
    productClicked({ correlationId: productCorrelationId });

    dispatch(
      setLeadDelivery({
        show: true,
        currentPrograms: [hit],
        showExitStep: false,
      })
    );
  };

  const handleDescriptionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    setShowDescription(!showDescription);
    e.stopPropagation();
  };

  const isPrivateTag = data?.tags?.isPrivate || hit?.school?.additional?.isPrivate;
  const isOnlineTag = data?.tags?.online || hit?.school?.additional?.isOnline;
  const isNonprofitTag = data?.tags?.notForProfit || hit?.school?.additional?.notForProfit;
  const tags = {
    isPrivate: isPrivateTag,
    isOnline: isOnlineTag,
    notForProfit: isNonprofitTag,
    isPublic: !isPrivateTag,
    forProfit: !isNonprofitTag,
    notOnline: !isOnlineTag,
  };

  const { PsychCardDescription, PsychCardDescriptionCta, isTestEnabled } = usePsychCardTest({
    showDescription,
    snippet: hit?.program?.snippet,
    onClick: handleDescriptionClick,
    viewCorrelationId,
    position: hit.__position,
    isCoveredByElement: showRecommendedResults || isProgramSubmitted,
  });

  return (
    <button
      type="button"
      onClick={handleProductClick}
      className={cn(styles.card, isTestEnabled && styles.psychCard)}
      ref={cardRef as MutableRefObject<HTMLButtonElement>}
      data-testid={dataTestId}
    >
      {/* top border */}
      <div />

      {/* header - includes logo and details sections */}
      <header className={cn(styles.cardHeader, showDescription && styles.hide)}>
        {/* logo */}
        <div className={styles.cardLogo}>
          <img
            src={`https://simple-storage-server.highereducation.com/${hit.school.slug}.png?w=130&h=80`}
            alt={hit.school.name}
          />
        </div>

        {/* location */}
        {hit.school.city && hit.school.state && (
          <p>
            {hit.school.city}, {hit.school.state}
          </p>
        )}
      </header>

      {/* content section */}
      <section className={cn(styles.cardContent, !showDescription && styles.spread)}>
        {/* program name */}
        <p title={hit.program.name}>{hit.program.name}</p>

        {/* school name */}
        <h3>{hit.school.name}</h3>
        <ProductFlags flags={tags} />

        {/* description */}

        {/** Psych Card Test */}
        <PsychCardDescription>
          <div className={cn(styles.cardDescription, showDescription && styles.expand)}>
            <p className={!showDescription ? styles.stretch : null}>{hit.program.snippet}</p>

            {/* gradient overlay */}
            <div className={showDescription ? styles.hide : null} />
          </div>
        </PsychCardDescription>
      </section>

      {/* description expand/collapse trigger */}

      <PsychCardDescriptionCta>
        <button type="button" className={styles.cardDescriptionToggle} onClick={handleDescriptionClick}>
          Description {showDescription ? '-' : '+'}
        </button>
      </PsychCardDescriptionCta>

      <button
        type="button"
        disabled={isProgramSubmitted}
        className={cn(styles.cardCta)}
        data-testid={`edu-match-request-info-${hit.__position}`}
        id="edu-match-request-info"
      >
        {!isProgramSubmitted && 'Explore Program'}
        {isProgramSubmitted && (
          <div className={styles.ctaText}>
            <div className={styles.iconContainer}>
              <HiOutlineCheckCircle size="1.5rem" />
              <div>Submitted</div>
            </div>
          </div>
        )}
      </button>
    </button>
  );
};

export { Card };
