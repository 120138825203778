import { ShowMore, type ShowMoreRef, type ShowMoreToggleLinesFn } from '@re-dev/react-truncate';
import { type FC, type ReactNode, useRef } from 'react';
import { HiMapPin } from 'react-icons/hi2';

import UserPreferencesPills from '../../UserPreferencesPills';
import styles from './BaseCard.module.css';
import type { ProgramCardProps } from './types';

type Props = {
  children?: ReactNode;
} & ProgramCardProps;

const BaseCard: FC<Props> = ({ result, children, userPreferencesAndMilitary }) => {
  const descriptionRef = useRef<ShowMoreRef>(null);

  // Custom buttons can be expanded and collapsed through this method
  const toggleLines: ShowMoreToggleLinesFn = (e) => {
    e.stopPropagation();
    descriptionRef.current?.toggleLines(e);
  };

  return (
    <div className={styles.programCard}>
      <section>
        <header className={styles.header}>
          <img
            src={`https://simple-storage-server.highereducation.com/${result.school.slug}.png?w=130&h=80`}
            alt={result.school.name}
            className={styles.logo}
          />
          <div className={styles.schoolNameAndLocation}>
            <h4>{result.school.name}</h4>
            {result?.school?.city && (
              <p>
                <HiMapPin />
                {result.school.city}, {result.school.state}
              </p>
            )}
          </div>
        </header>
        <UserPreferencesPills result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />

        <div className={styles.programInfo}>
          <h3 className={styles.programName}>{result.program.name}</h3>
          <span>
            <ShowMore
              ref={descriptionRef}
              lines={3}
              more={
                <button type="button" onClick={toggleLines}>
                  ...Read more
                </button>
              }
              less={
                <button type="button" onClick={toggleLines}>
                  Read less
                </button>
              }
            >
              {result?.program?.snippet}
            </ShowMore>
          </span>
        </div>
      </section>

      <div className={styles.programStatsAndCta}>
        <div className={styles.programStats} />
        {children}
      </div>
    </div>
  );
};

export default BaseCard;
