import { type FC } from 'react';

import Icon from '@core/shared/components/Icon';
import type { VoyagerResult } from '@core/ts/results';

import type { UserPreferencesAndMilitary } from '../../types';
import styles from './DrawerExperienceHeader.module.css';

type Props = {
  result: VoyagerResult;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
};

const DrawerExperienceHeader: FC<Props> = () => (
  <header className={styles.drawerExperienceHeader}>
    <div className={styles.piiInformationContainer}>
      <Icon name="yes" color="--primary-700" size="8" />
      <h2 className={styles.learnMore}>Want to Learn More?</h2>
    </div>
    <p className={styles.subtitle}>
      Submit your contact information to show this school you&apos;re interested! You can even start your application on
      the next screen.
    </p>
  </header>
);

export default DrawerExperienceHeader;
