import React, { type MouseEventHandler } from 'react';
import { TiLocation } from 'react-icons/ti';

import type { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import { Cta } from '../../../hooks/useGetApplicationCtaData';
import LeadSubmittedCta from '../../LeadSubmittedCta';
import styles from './Card.module.css';

export type CardProps = {
  isRecommender?: boolean;
  isSpotlight?: boolean;
  school: VoyagerResult['school'];
  program: VoyagerResult['program'];
  tags: {
    isPrivateTag: boolean;
    isOnlineTag: boolean;
    isNonprofitTag: boolean;
  };
  ctaLabel?: string;
  handleProductClick?: MouseEventHandler<HTMLButtonElement>;
  cardRef?: React.MutableRefObject<HTMLDivElement>;
  isProgramSubmitted?: boolean;
  cta: Cta;
};

const DefaultCard: React.FC<CardProps> = ({
  handleProductClick,
  cardRef,
  school,
  isRecommender,
  tags,
  program,
  isSpotlight,
  isProgramSubmitted,
  ctaLabel,
  cta,
}) => (
  <button type="button" onClick={handleProductClick} className={cn(styles.card, isRecommender && styles.rec)}>
    <div ref={cardRef} className={styles.cardContent}>
      <div className={styles.cardHeader}>
        <img
          src={`https://simple-storage-server.highereducation.com/${school.slug}.png?w=130&h=80`}
          alt={school.name}
        />
        <div className={styles.cardSchool}>
          <h3>{school.name}</h3>
          {school.city && school.state && (
            <div className={styles.cardLocation}>
              <TiLocation size={16} />
              <p>
                {school.city}, {school.state}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={styles.cardSubheader}>
        <h4 className={styles.cardProgram}>{program.name}</h4>
        <ul className={styles.flagsList}>
          <li>{tags?.isPrivateTag ? <>Private</> : <>Public</>}</li>
          <li>{tags?.isOnlineTag ? <>Online</> : <>Oncampus</>}</li>
          <li>{tags?.isNonprofitTag ? <>Nonprofit</> : <>Forprofit</>}</li>
        </ul>
      </div>

      <p className={styles.cardBlurb}>{program.snippet}</p>
      {!isProgramSubmitted ? (
        <div data-testid="learn-more-btn" className={cn(styles.learnMoreButton, isSpotlight && styles.isSpotlight)}>
          {ctaLabel || 'Learn More'}
        </div>
      ) : (
        <LeadSubmittedCta cta={cta} />
      )}
    </div>
  </button>
);

export default DefaultCard;
