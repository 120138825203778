import { FC, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import styles from './AccordionItem.module.css';

type Props = {
  title: string;
  description: string;
  index: number;
};
const AccordionItem: FC<Props> = ({ title, description, index }) => {
  // Index < 1 because we want the first accordion item to be open by default
  const [open, setOpen] = useState(index < 1);
  return (
    <>
      <button className={styles.accordionButton} type="button" onClick={() => setOpen(!open)}>
        <div className={styles.itemTitle}>
          <h3>{title}</h3>
          {open ? (
            <FiChevronUp className="text-secondary-700" size={32} />
          ) : (
            <FiChevronDown className="text-secondary-700" size={32} />
          )}
        </div>
        <p className={open ? styles.description : styles.closed}>
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </p>
      </button>
    </>
  );
};
export default AccordionItem;
