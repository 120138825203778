import { type ReactNode, type FC } from 'react';

import cn from '@core/utils/classNames';

import styles from './Pill.module.css';

type Props = {
  children?: ReactNode;
  color?: string;
  className?: string;
};

const Pill: FC<Props> = ({ color = '--primary-700', className = '', children }) => {
  const targetColor = color.includes('--') ? `var(${color})` : color;
  return (
    <div style={{ borderColor: targetColor, color: targetColor }} className={cn(styles.pill, className)}>
      <div className={styles.pillText}>{children}</div>
    </div>
  );
};

export default Pill;
