import { type Dispatch, type SetStateAction, type FC, useState } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import FormSubmissionButton from '../../../CtaButtons/FormSubmissionButton';
import FormCtaDisclaimer from '../../../FormCtaDisclaimer';
import PiiFormSection from '../../../PiiFormSection';
import styles from './PiiConfirmation.module.css';

type Props = {
  onFormSubmit: () => void;
  pii: Record<string, string>;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  isHeclidLoading: boolean;
  isSubmittingLead: boolean;
  result: VoyagerResult;
};

const UnderGradPiiConfirmation: FC<Props> = ({
  onFormSubmit,
  pii,
  piiErrors,
  setPii,
  isHeclidLoading,
  isSubmittingLead,
  result,
}) => (
  <form onSubmit={(e) => e.preventDefault()} data-tf-element-role="offer" className={styles.piiSection}>
    <div className={styles.formSection}>
      <div className={styles.formSectionLabel}>Confirm or edit your details</div>
      <div className={styles.formSectionInstructions}>
        Start a conversation with a school admin by submitting your contact information. Once you submit, you’ll be able
        to start your application.
      </div>

      <div className={styles.piiFormSection}>
        <PiiFormSection piiErrors={piiErrors} setPii={setPii} pii={pii} isEditing />
      </div>
    </div>

    <div className={styles.formSectionBottom}>
      <div className={styles.formCtaDisclaimer}>
        <FormCtaDisclaimer school={result?.school} />
      </div>

      <div className={styles.formCtaSectionDesktop}>
        <FormSubmissionButton
          isLoading={isHeclidLoading}
          isDisabled={isSubmittingLead}
          onClick={onFormSubmit}
          className={styles.submitButton}
          data-tf-element-role="submit"
        />
      </div>
    </div>
  </form>
);

export default UnderGradPiiConfirmation;
