import { type FC } from 'react';

import { BannerProps } from '@core/shared/features/UserExperienceSurvey';

import styles from './UserXpBanner.module.css';

const UserXpBanner: FC<BannerProps> = ({ onClick }) => (
  <section className={styles.userExperienceSurvey}>
    <div className={styles.userExperienceSurveyBanner}>
      <div className={styles.userExperienceSurveyBannerContainer}>
        <h2>Not what you expected?</h2>
        <button type="button" onClick={onClick}>
          Give feedback
        </button>
      </div>
    </div>
  </section>
);

export default UserXpBanner;
