import { type FC } from 'react';

import CustomTooltip from '@core/shared/components/CustomTooltip';

const tooltipFirstParagraph = `All schools and programs featured on this page have been sponsored, meaning BestColleges receives compensation. This compensation may impact how, where, and in what order these sponsored schools appear.`;
const tooltipSecondParagraph = `Program tags reflect your preferences for the schools that offer them, but the order of these programs is determined by a proprietary algorithm.`;

const HowWeSelectOurMatches: FC = () => (
  <CustomTooltip
    textColor="white"
    tooltipTitle="How We Select Your Matches"
    text={tooltipFirstParagraph}
    subtext={tooltipSecondParagraph}
    title="How We Select Your Matches"
  />
);

export default HowWeSelectOurMatches;
