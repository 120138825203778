import prospectScriptConfig from '@core/config/prospectScriptSchoolIds.json';
import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import { mobiusTrack } from '@core/services/mobius';
import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import type {
  LeadRequest,
  LeadResponse,
  ProgramContext,
  QualificationResults,
  SchoolContext,
} from '@core/ts/leadDelivery';
import { type EduRequestV2 } from '@core/ts/leadDelivery';
import { type VoyagerResult } from '@core/ts/results';
import buildLeadObject, { type BuildLeadObjectInputs } from '@core/utils/buildLeadObject';

const deliverySystemBaseUrl = import.meta.env.VITE_DELIVERY_SYSTEM_BASE_URL;
const deliverySystemApiKey = import.meta.env.VITE_DELIVERY_SYSTEM_API_KEY;
// These headers will be reused

export type ValidateZipSuccess = {
  stateAbbr: string;
  validZip: boolean;
};

export type ValidateZip = (zip: string) => Promise<ValidateZipSuccess | Error>;

export type MakeValidateZip = () => ValidateZip;

export type LeadDeliveryConfig = {
  url: string;
  apiKey: string;
};

const resolveUrl = () => ({
  url: deliverySystemBaseUrl,
  headers: { 'Content-Type': 'application/json', 'x-api-key': deliverySystemApiKey },
});

// V4 Qualify endpoint
export const qualifyEduRequest = async (requestBody: EduRequestV2): Promise<QualificationResults | Error> => {
  const { url, headers } = resolveUrl();
  const body = JSON.stringify(requestBody);
  try {
    const response = await fetch(`${url}/v4/request/qualify`, {
      method: 'POST',
      body,
      headers,
      redirect: 'follow',
    });

    const responseBody = await response.json();

    if (response.status !== 200) {
      throw new Error(JSON.stringify(responseBody));
    }

    return responseBody;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.V4_QUALIFY, error as Error);
    return error as Error;
  }
};

// LDS API call that validates a phone number string
/**
 *
 * @param phoneNumber user's phone number
 * @param useCarrier Whether or not use the paid version of twilio
 * @returns true if the provided phone number is valid
 */
export const validatePhone = async (phoneNumber: string, useCarrier: boolean = false) => {
  try {
    const { url, headers } = resolveUrl();
    const body = JSON.stringify({ phoneNumber, useCarrier });

    const response = await fetch(`${url}/v2/validate/phone`, {
      headers,
      body,
      method: 'POST',
      redirect: 'follow',
    });

    const responseBody = await response.json();

    if (response.status !== 200) {
      throw new Error(JSON.stringify(responseBody));
    }

    return responseBody;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.LDS_VALIDATE_PHONE, error as Error);
    return error;
  }
};

// LDS API call that validates a zip code string
const makeValidateZip: MakeValidateZip = () => {
  const cache: Record<string, string> = {};

  return async (zip: string) => {
    if (cache[zip]) {
      return cache[zip];
    }
    try {
      const body = JSON.stringify({ zip });
      const { url, headers } = resolveUrl();
      const response = await fetch(`${url}/v2/validate/zip`, {
        headers,
        body,
        method: 'POST',
        redirect: 'follow',
      });
      const responseBody = await response.json();

      if (response.status !== 200) {
        throw new Error(JSON.stringify(responseBody));
      }

      cache[zip] = responseBody;

      return responseBody;
    } catch (error) {
      newRelicNoticeError(nrErrorMap.LDS_VALIDATE_ZIP, error as Error);
      return error;
    }
  };
};

export const validateZip = makeValidateZip();

// LDS API call that validates a zip code string and returns restrictionLocation object
export const getRestrictionLocation = async (zip: string) => {
  const { stateAbbr } = (await validateZip(zip)) as ValidateZipSuccess;
  // hardcoding country value until we can get the value from API
  return stateAbbr ? { state: stateAbbr, country: 'US' } : null;
};

// validate if a string is in a valid email format
export const validateEmail = (email: string) => {
  /* eslint-disable no-useless-escape */
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
};

export const getLeadQualifications = async (inputs: VoyagerInputs) => {
  const body = JSON.stringify({
    lead: buildLeadObject(inputs as BuildLeadObjectInputs),
    trackingContext: {
      anonymousId: window._Cohesion.anonymousId,
    },
  });
  const { url, headers } = resolveUrl();
  try {
    const response = await fetch(`${url}/v3/lead/qualify`, {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
    });

    if (response.status !== 200) {
      throw new Error('Failed to get qualifications.');
    }

    return await response.json();
  } catch (error) {
    newRelicNoticeError(nrErrorMap.LDS_QUALIFICATIONS, error as Error);
    return error;
  }
};

export const getLeadSchema = async (
  { schoolId, programId }: { schoolId: SchoolContext['id']; programId: ProgramContext['providerProgramId'] },
  useDeliverySystem = false
) => {
  const { url, headers } = resolveUrl();
  try {
    const response = await fetch(`${url}/v3/schema/${schoolId}/${programId}`, {
      method: 'GET',
      headers,
      redirect: 'follow',
    });

    const responseBody = await response.json();

    if (response.status !== 200) {
      throw new Error(JSON.stringify(responseBody));
    }

    return responseBody;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.LDS_SCHEMA, error as Error);
    return error;
  }
};

export const postGetLeadSchema = async ({ schoolId, programId, body }) => {
  const { url, headers } = resolveUrl();
  try {
    const response = await fetch(`${url}/v3/schema/${schoolId}/${programId}`, {
      method: 'POST',
      headers,
      redirect: 'follow',
      body: JSON.stringify({ ...body }),
    });

    const responseBody = await response.json();

    if (response.status !== 200) {
      throw new Error(JSON.stringify(responseBody));
    }

    return responseBody;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.LDS_SCHEMA, error as Error);
    return error;
  }
};

export const submitLead = async (
  record: VoyagerResult,
  inputs,
  heclid: string,
  productCorrelationId: string,
  contactId?: string
): Promise<Partial<LeadResponse & Error>> => {
  try {
    // Hit the LDS schema endpoint to get school specific required fields
    const schema = await getLeadSchema({ schoolId: record.school.id, programId: record.program.id });

    // Build lead
    const lead = buildLeadObject(inputs, schema, record?.leadSource);

    // Trust Form token for South College and UAGC
    if (prospectScriptConfig.includes(record.school.id)) {
      lead.thirdPartyLeadId = inputs.activeProspectToken.value;
    }

    // Build body needed for the LDS v3 lead endpoint
    const payload: LeadRequest = {
      lead,
      trackingContext: {
        correlationId: productCorrelationId,
        publisher: window.location.host,
        heclid,
        anonymousId: window._Cohesion.anonymousId,
        url: inputs?.sourceUrl?.value,
        experience: 'Voyager',
      },
      schoolContext: {
        id: record.school.id,
        slug: record.school.slug,
        name: record.school.name,
      },
      programContext: {
        providerProgramId: record.program.id,
      },
    };

    if (contactId) {
      payload.contactContext = { contactId };
    }

    const body = JSON.stringify(payload);

    const { url, headers } = resolveUrl();
    // Hits the LDS v3 lead endpoint to try to submit the lead
    const response: LeadResponse = await fetch(`${url}/v3/lead`, {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
    });

    if (response.status !== 201) {
      const responseBody = await response.json();
      throw new Error(JSON.stringify(responseBody));
    }

    // Hits the mobius track endpoint to trigger the LEAD OutcomeTracked event
    await mobiusTrack(heclid, productCorrelationId);
    return response;
  } catch (error) {
    const parsedError = JSON.parse((error as Error).message);

    const errorType =
      parsedError.message === 'enrollment_qualification_not_met'
        ? nrErrorMap.LDS_LEAD_UNQUALIFIED
        : nrErrorMap.LDS_LEAD;

    newRelicNoticeError(errorType, parsedError, {
      errorDetails: JSON.stringify(parsedError.errors),
      heclid,
      correlationId: productCorrelationId,
      schoolId: record.school.id,
      programId: record.program.id,
    });
    return error as Error;
  }
};

export const submitLeadBME = async (
  record: VoyagerResult,
  inputs: VoyagerInputs,
  heclid: string,
  productCorrelationId: string,
  contactId?: string
): Promise<Partial<LeadResponse & Error>> => {
  try {
    // Hit the LDS schema endpoint to get school specific required fields
    const schema = await getLeadSchema({ schoolId: record.school.id, programId: record.program.id });

    // Build lead
    const lead = buildLeadObject(inputs as BuildLeadObjectInputs, schema, record?.leadSource);

    // Trust Form token for South College and UAGC
    if (prospectScriptConfig.includes(record.school.id)) {
      lead.thirdPartyLeadId = inputs.activeProspectToken.value;
    }

    // Build body needed for the LDS v3 lead endpoint
    const payload: LeadRequest = {
      lead,
      trackingContext: {
        correlationId: productCorrelationId,
        publisher: window.location.host,
        heclid,
        anonymousId: window._Cohesion.anonymousId,
        url: inputs?.sourceUrl?.value,
        experience: 'Voyager',
      },
      schoolContext: {
        id: record.school.id,
        slug: record.school.slug,
        name: record.school.name,
      },
      programContext: {
        providerProgramId: record.program.id,
      },
    };

    if (contactId) {
      payload.contactContext = { contactId };
    }

    const body = JSON.stringify(payload);

    const { url, headers } = resolveUrl();
    // Hits the LDS v3 lead endpoint to try to submit the lead
    const response: LeadResponse = await fetch(`${url}/v3/lead`, {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
    });

    if (response.status === 201) {
      // Hits the mobius track endpoint to trigger the LEAD OutcomeTracked event
      await mobiusTrack(heclid, productCorrelationId);
    } else {
      const responseBody = await response.json();

      const errorType =
        responseBody.message === 'enrollment_qualification_not_met'
          ? nrErrorMap.LDS_LEAD_UNQUALIFIED
          : nrErrorMap.LDS_LEAD;

      newRelicNoticeError(errorType, responseBody, {
        errorDetails: JSON.stringify(responseBody.errors),
        heclid,
        correlationId: productCorrelationId,
        schoolId: record.school.id,
        programId: record.program.id,
      });
    }

    return response;
  } catch (error) {
    return error as Error;
  }
};
