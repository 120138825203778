import { type FC } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';

import type { VoyagerResult } from '@core/ts/results';

import styles from './SchoolStats.module.css';

type Props = {
  result: VoyagerResult;
};

const SchoolStats: FC<Props> = ({ result }) => {
  const accreditedLabel = result?.school?.additional?.accreditator !== null ? 'Yes' : 'No';

  return (
    <div className={styles.dataAndInsightsContainer}>
      <div className={styles.dataAndInsightsBoxes}>
        <div className={styles.dataBox}>
          <div className={styles.dataValue}>120</div>
          <div className={styles.dataLabel}>Credits Needed</div>
        </div>
        <div className={styles.dataBox}>
          <div className={styles.dataValue}>{accreditedLabel}</div>
          <div className={styles.dataLabel}>
            Accredited
            <BsInfoCircleFill size={12} className={styles.infoIcon} />
          </div>
        </div>
        <div className={styles.dataBox}>
          <div className={styles.dataValue}>{result?.school?.additional?.studentToFacultyRatio}:1</div>
          <div className={styles.dataLabel}>Student to Faculty Ratio</div>
        </div>
        <div className={styles.dataBox}>
          <div className={styles.dataValue}>{result?.school?.additional?.percentOnlineEnrollment?.toFixed()}%</div>
          <div className={styles.dataLabel}>Students Enrolled Online</div>
        </div>
        <div className={styles.dataBox}>
          <div className={styles.dataValue}>{result?.school?.additional?.doesOfferDistanceEducationCourses}</div>
          <div className={styles.dataLabel}>Offers Distance Education</div>
        </div>
        <div className={styles.dataBox}>
          <div className={styles.dataValue}>{result?.school?.additional?.levelOfInstitution}</div>
          <div className={styles.dataLabel}>Level of Institution</div>
        </div>
      </div>
    </div>
  );
};

export default SchoolStats;
