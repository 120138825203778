import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import FormCtaButtonGroup from '../../FormCtaButtonGroup';
import FormCtaDisclaimer from '../FormCtaDisclaimer';
import styles from './FormCtaSection.module.css';

type Props = {
  onSubmitClick?: () => void;
  onEditClick?: () => void;
  onEditConfirmClick?: () => void;
  isEditing?: boolean;
  canEdit?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  result: VoyagerResult;
};

const FormCtaSection: FC<Props> = ({
  onSubmitClick,
  onEditClick,
  onEditConfirmClick,
  isEditing = false,
  canEdit = true,
  isLoading = false,
  isDisabled,
  result,
}) => (
  <div className={styles.ctaSection}>
    <FormCtaButtonGroup
      isLoading={isLoading}
      onSubmitClick={onSubmitClick}
      onEditClick={onEditClick}
      onEditConfirmClick={onEditConfirmClick}
      isEditing={isEditing}
      canEdit={canEdit}
      isDisabled={isDisabled}
    />
    <FormCtaDisclaimer tcpa={result?.school?.config?.tcpa} />
  </div>
);

export default FormCtaSection;
