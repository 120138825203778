import { type ButtonHTMLAttributes, type FC } from 'react';

import Button from '../../ui/Button';
import ButtonLoader from '../ButtonLoader';

type Props = {
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ApplicationCtaButton: FC<Props> = ({ isLoading = false, children, className, ...props }) => (
  <Button disabled={isLoading} className={className} {...props} primary>
    <ButtonLoader isLoading={isLoading}>{children}</ButtonLoader>
  </Button>
);

export default ApplicationCtaButton;
