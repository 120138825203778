import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { selectIsRecommenderPopupOpen, setIsRecommenderPopupOpen } from '@core/reducers/matchesSlice';
import Popup from '@core/shared/components/Popup';
import Recommender from '@core/shared/features/Recommender';
import type { Qualifications, VoyagerResult, VoyagerResultEnhancedSchoolData } from '@core/ts/results';

type RecommenderPopupProps = {
  hits: VoyagerResult[];
  eventingOverrides: any;
  enhancedSchoolData?: VoyagerResultEnhancedSchoolData;
  qualifications?: Qualifications;
};

const RecommenderPopup: FC<RecommenderPopupProps> = ({
  hits,
  qualifications,
  eventingOverrides,
  enhancedSchoolData,
}) => {
  const dispatch = useDispatch();
  const showRecommendedResults = useSelector(selectIsRecommenderPopupOpen);

  const { elementViewed, elementClicked } = useElementEvents({
    webElement: {
      elementType: 'exit-element',
      location: eventingOverrides?.location,
      name: 'thank-you-confirmation',
      htmlId: 'thank-you-button',
    },
  });

  // Only show the popup if results are available
  useEffect(() => {
    if (hits?.length > 0) {
      elementViewed();
    }
  }, [hits?.length]);

  // This onClose is fired when the user closes the modal via the `X` button or clicking outside of the modal
  const onClose = async () => {
    elementClicked();
    dispatch(setIsRecommenderPopupOpen(false));
  };

  return (
    <Popup show={showRecommendedResults} onClose={onClose}>
      <Recommender
        enhancedSchoolData={enhancedSchoolData as VoyagerResultEnhancedSchoolData}
        hits={hits}
        qualifications={qualifications}
        closePopup={() => dispatch(setIsRecommenderPopupOpen(false))}
        eventingOverrides={eventingOverrides}
      />
    </Popup>
  );
};

export { RecommenderPopup };
