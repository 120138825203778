import { type Dispatch, type SetStateAction, type FC } from 'react';
import { HiOutlinePencil } from 'react-icons/hi2';

import type { VoyagerResult } from '@core/ts/results';

import FormCtaDisclaimer from '../../FormCtaDisclaimer';
import FormCtaSection from '../../FormCtaSection';
import PiiFormSection from '../../PiiFormSection';
import styles from './PiiConfirmation.module.css';

type Props = {
  onFormSubmit: () => void;
  pii: Record<string, string>;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  isEditing: boolean;
  onEdit: () => void;
  onEditConfirm: () => void;
  isHeclidLoading: boolean;
  isSubmittingLead: boolean;
  result: VoyagerResult;
};

const PiiConfirmation: FC<Props> = ({
  onFormSubmit,
  pii,
  piiErrors,
  setPii,
  isEditing,
  onEdit,
  onEditConfirm,
  isHeclidLoading,
  isSubmittingLead,
  result,
}) => (
  <div className={styles.piiSection}>
    <div className={styles.formSection}>
      <div className={styles.formSectionHeader}>Want to Learn More?</div>
      <div className={styles.formSectionInstructions}>
        Submit your contact information to start a conversation with a school advisor. You can even start your
        application if you&apos;re ready.
      </div>

      <span>
        <p className={styles.formSectionLabelDesktop}>
          <HiOutlinePencil size={20} strokeWidth={2} /> Confirm or edit your details
        </p>
        <p className={styles.formSectionLabelMobile}>Confirm your contact details:</p>
      </span>

      <div className={styles.piiFormSectionDesktop}>
        <PiiFormSection piiErrors={piiErrors} setPii={setPii} pii={pii} isEditing={isEditing} />
      </div>

      <div className={styles.piiFormSectionMobile}>
        <PiiFormSection piiErrors={piiErrors} setPii={setPii} pii={pii} isEditing />
      </div>
    </div>

    <div className={styles.formCtaDisclaimerMobile}>
      <FormCtaDisclaimer tcpa={result?.school?.config?.tcpa} />
    </div>

    <div className={styles.formCtaSectionDesktop}>
      <FormCtaSection
        isLoading={isHeclidLoading}
        onEditClick={onEdit}
        onSubmitClick={onFormSubmit}
        onEditConfirmClick={onEditConfirm}
        isEditing={isEditing}
        isDisabled={isSubmittingLead}
        result={result}
      />
    </div>
  </div>
);

export default PiiConfirmation;
