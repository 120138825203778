import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import { type ApplicationCta, FlowType } from '../../types';
import ExitStrategyPopup from './ExitStrategyPopup';
import ExitStrategySidebar from './ExitStrategySidebar';

type Props = {
  flowType: FlowType;
  firstName: string;
  result: VoyagerResult;
  onBackButtonClick: () => void;
  currentResultApplicationCta: ApplicationCta;
  isCurrentResultApplicationCtaLoading?: boolean;
};

const ExitStrategyComponent: FC<Props> = ({ flowType, ...props }) => {
  const ExitStrategyComponent = flowType === FlowType.POPUP ? ExitStrategyPopup : ExitStrategySidebar;

  return <ExitStrategyComponent {...props} />;
};

export default ExitStrategyComponent;
