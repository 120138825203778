import addFormats from 'ajv-formats';
import Ajv from 'ajv/dist/2020';

import { PageActions } from '@core/services/newRelic';
import Lead from '@core/ts/leadDelivery';

import getLdsStartDate from './getLdsStartDate';

export enum LevelOfEducation {
  HIGHSCHOOL = 'highschool',
  GED = 'ged',
  ASSOCIATES = 'associates',
  BACHELORS = 'bachelors',
  MASTERS = 'masters',
  DOCTORATE = 'doctorate',
}

type BuildLeadObjectInputsValue<k, v = string> = {
  key: k;
  value: v;
};

export type BuildLeadObjectInputs = {
  [K in keyof Lead]: BuildLeadObjectInputsValue<K, Lead[K]>;
} & {
  graduationYear: BuildLeadObjectInputsValue<'graduationYear', string>;
  educationLevel: BuildLeadObjectInputsValue<'educationLevel', LevelOfEducation>;
  phone: BuildLeadObjectInputsValue<'phone', Lead['phoneNumber']>;
  address: BuildLeadObjectInputsValue<'address', string>;
  address2: BuildLeadObjectInputsValue<'address2', string>;
  city: BuildLeadObjectInputsValue<'city', string>;
  state: BuildLeadObjectInputsValue<'state', string>;
  stateAbbr: BuildLeadObjectInputsValue<'stateAbbr', string>;
  zip: BuildLeadObjectInputsValue<'zip', string>;
  country: BuildLeadObjectInputsValue<'country', string>;
  ipInferredPostalCode: BuildLeadObjectInputsValue<'ipInferredPostalCode', string>;
  startTime: BuildLeadObjectInputsValue<'startTime', Lead['startDate']>;
  militaryAffiliation: BuildLeadObjectInputsValue<'militaryAffiliation', Lead['militaryStatus']>;
  hasWorkExperience: BuildLeadObjectInputsValue<'hasWorkExperience', boolean>;
  hasProgramRelatedExperience: BuildLeadObjectInputsValue<'hasProgramRelatedExperience', boolean>;
  currentPermanentResidenceCountry: BuildLeadObjectInputsValue<
    'currentPermanentResidenceCountry',
    Lead['currentPermanentResidenceCountry']
  >;
  enrollmentYear: BuildLeadObjectInputsValue<'enrollmentYear', string>;
  sourceUrl?: BuildLeadObjectInputsValue<'sourceUrl', string>;
} & {
  [k in 'currentAcademicAward' | 'inProgressAcademicAward']: {
    value: Record<string, string>;
  };
};

type BuildLeadObject = (inputs: BuildLeadObjectInputs, schema?: any, leadSource?: Lead['leadSource']) => Lead;

// Builds a valid LDS v3 lead object to send as part of the payload to the /v3/lead endpoint
const buildLeadObject: BuildLeadObject = (inputs, schema, leadSource = null) => {
  // If currentAcademicAward is required, we will find the value needed by that program and try to find it in the user selections
  const currentAcademicAwardKey =
    schema?.properties?.currentAcademicAward?.anyOf?.[0]?.enum?.[0] ??
    Object.keys(inputs?.currentAcademicAward?.value ?? {})?.[0];
  const currentAcademicAwardValue = inputs?.currentAcademicAward?.value?.[currentAcademicAwardKey];
  const inProgressAcademicAwardValue = inputs?.inProgressAcademicAward?.value?.[currentAcademicAwardKey];

  const currentAcademicAwardValueMap = {
    COMPLETED: currentAcademicAwardKey,
    IN_PROGRESS: currentAcademicAwardKey,
    null: null,
  };

  const currentAcademicAward =
    currentAcademicAwardValueMap[currentAcademicAwardValue as keyof typeof currentAcademicAwardValueMap];
  const inProgressAcademicAward =
    currentAcademicAwardValueMap[inProgressAcademicAwardValue as keyof typeof currentAcademicAwardValueMap];

  // Verify that lead id token exist and send page action to New Relic if it doesn't
  if (!window.LeadiD?.token) {
    PageActions.JornayaTokenNotFound({ ...window._Cohesion?.webContext });
  }

  // Builds a valid LDS v3 lead object
  // LDS v3 Lead docs https://redventures.atlassian.net/wiki/spaces/EDU/pages/99386884955/School-agnostic+Fields+LDS+v3+lead

  const lead: Lead = {
    firstName: inputs.firstName?.value,
    lastName: inputs.lastName?.value,
    email: inputs.email?.value,
    phoneNumber: inputs.phone?.value,
    location: {
      address: inputs.address?.value,
      address2: inputs.address2?.value,
      city: inputs.city?.value,
      state: inputs.state?.value,
      stateAbbr: inputs.stateAbbr?.value,
      postalCode: inputs.zip?.value,
      country: inputs.country?.value,
      ipInferredPostalCode: inputs.ipInferredPostalCode?.value,
    },
    hsGradYear: inputs?.graduationYear?.value ? Number(inputs?.graduationYear?.value) : undefined,
    levelOfEducation: inputs.educationLevel?.value as unknown as Lead['levelOfEducation'],
    militaryStatus: inputs.militaryAffiliation?.value as unknown as Lead['militaryStatus'],
    startDate: getLdsStartDate(inputs?.startTime?.value as unknown as number),
    gpa: inputs.gpa?.value,
    /** @TODO Investigate why we are returning booleans here */
    tcpaText: String(inputs.tcpaText?.value),
    transferCredits: inputs.transferCredits?.value,
    thirdPartyLeadId: window.LeadiD?.token,
    yearsOfWorkExperience: inputs.hasWorkExperience?.value === false ? 0 : inputs.yearsOfWorkExperience?.value,
    yearsOfProgramRelatedExperience:
      inputs.hasProgramRelatedExperience?.value === false ? 0 : inputs.yearsOfProgramRelatedExperience?.value,
    currentAcademicAward,
    inProgressAcademicAward,
    currentLicense: ['NO_LICENSE', 'IN_PROGRESS', null, undefined].includes(inputs.currentLicense?.value)
      ? null
      : inputs.currentLicense?.value,
    inProgressLicense: inputs.inProgressLicense?.value,
    workExperienceField: inputs?.workExperienceField?.value,
    // This is fixed because a lead can only be submitted if the user consented to the program specific TCPA
    hasGivenTCPAConsent: true,
    hasGivenThirdPartySharingConsent: inputs?.hasGivenThirdPartySharingConsent?.value ?? false,
    hasGivenProspectSharingConsent: inputs?.hasGivenProspectSharingConsent?.value ?? false,
    undergraduateMajor: inputs?.undergraduateMajor?.value,
    inProgressUndergraduateMajor: inputs?.inProgressUndergraduateMajor?.value,
    learningPace: inputs?.learningPace?.value,
    programTrack: inputs?.programTrack?.value,
    currentCitizenship: inputs?.currentCitizenship?.value,
    currentPermanentResidenceCountry: inputs?.country?.value as Lead['currentPermanentResidenceCountry'],
    isInARelatedRole: inputs?.isInARelatedRole?.value,
    leadSource,
    tcpaEABConsentGiven: inputs?.tcpaEABConsentGiven?.value,
    tcpaEMCCConsentGiven: inputs?.tcpaEMCCConsentGiven?.value,
    someCollege: inputs?.someCollege?.value,
    is18YearsOrOlder: inputs?.is18YearsOrOlder?.value,
    lastAttendanceYear: inputs?.enrollmentYear?.value ? Number(inputs?.enrollmentYear?.value) : undefined,
  };

  if (lead.yearsOfWorkExperience) {
    lead.yearsOfWorkExperience = Number(lead.yearsOfWorkExperience);
  }

  if (lead.yearsOfProgramRelatedExperience) {
    lead.yearsOfProgramRelatedExperience = Number(lead.yearsOfProgramRelatedExperience);
  }

  if (lead.transferCredits) {
    lead.transferCredits = Number(lead.transferCredits);
  }

  if (schema) {
    // If schema is passed, we run ajv.validate to remove any unwanted properties from the lead object
    const ajv = new Ajv({ removeAdditional: true, allErrors: true });
    addFormats(ajv);
    const validate = ajv.compile(schema);
    validate(lead);
  }

  return lead;
};

export default buildLeadObject;
