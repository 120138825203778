import { type FC } from 'react';
import { HiMapPin } from 'react-icons/hi2';

import { VoyagerResult } from '@core/ts/results';

import styles from './NotQualifiedPopup.module.css';

type Props = {
  result: VoyagerResult;
  onCtaClick?: () => void;
};

const NotQualifiedPopup: FC<Props> = ({ result, onCtaClick }) => (
  <section className={styles.notQualifiedContainer}>
    <div className={styles.popupHeader}>
      <img
        src={`https://simple-storage-server.highereducation.com/${result?.school?.slug}.png?w=130&h=80`}
        alt={result?.school?.name}
      />
      <div>
        <h3>{result?.program?.name}</h3>
        <h1>{result?.school?.name}</h1>

        <p>
          <HiMapPin />
          {result?.school?.city}, {result?.school?.state}
        </p>
      </div>
    </div>
    <div className={styles.popupContentContainer}>
      <h1>Not Qualified</h1>
      <p>It looks like you may not qualify for this program based on your answers. </p>
      <p>
        That&apos;s ok! Review other schools within your match list to find a school that&apos;s better suited for you.
      </p>
      <div className={styles.buttonContainer}>
        <button type="button" className={styles.backButton} onClick={onCtaClick}>
          Back to All Results
        </button>
      </div>
    </div>
  </section>
);

export default NotQualifiedPopup;
