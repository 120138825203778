import { FC } from 'react';

import additionalContentFieldTypeDictionary, {
  AdditionalContentFieldType,
} from '../../utils/additionalContentFieldTypeDictionary';
import Fields from '../Fields';
import type { AdditionalContentField } from './types';

/**
 *
 * @param field field to traverse
 * @returns the number of fields that are relevant to fieldNumber
 */

/**
 *  Recursively go through all the fields before the provided index and include all relevant children in the fieldNumber
 * @param {Field[]} fields list of fields
 * @param {number} index index of the current field
 * @returns {number} fieldNumber
 */

type Props = {
  fields?: AdditionalContentField[];
};

const AdditionalContentFields: FC<Props> = ({ fields }) => (
  <>
    {fields?.map((field: AdditionalContentField, index) => {
      if (!additionalContentFieldTypeDictionary[field?.type as AdditionalContentFieldType]) {
        console.log(`Field type ${field.type} does not exist.`);
        return null;
      }
      const FieldComponent = additionalContentFieldTypeDictionary[field?.type as AdditionalContentFieldType];
      return (
        <div key={`${field.name}-${index}`}>
          <FieldComponent key={`${field.name}-${index}`} field={field} />
        </div>
      );
    })}
  </>
);

Fields.defaultProps = {
  fields: [],
};

export default AdditionalContentFields;
