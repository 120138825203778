import { type IdSchema } from '@rjsf/utils';
import { type FC, type Dispatch, type SetStateAction, useEffect } from 'react';
import { HiXMark } from 'react-icons/hi2';

import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import { type VoyagerResult } from '@core/ts/results';

import useProgramSubmissionFlow from '../../hooks/useProgramSubmissionFlow';
import { FlowType, type ApplicationCta, type UserPreferencesAndMilitary } from '../../types';
import AdditionalQuestions2 from '../AdditionalQuestions2';
import ExitStrategyComponent from '../ExitStrategy';
import NotQualified from '../NotQualified';
import PiiConfirmation from '../PiiConfirmation';
import ProgramSelect from '../ProgramSelect';
import { Step, Steps } from '../Steps';
import { StepTypes } from '../Steps/types';
import SubmissionLoader from '../SubmissionLoader';
import Popup from '../ui/Popup';
import styles from './PopupExperience.module.css';

type Props = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  spotlightMatch: VoyagerResult;
  recommenderMatches: VoyagerResult[];
  userInputs: VoyagerInputs;
  prqSchema?: IdSchema;
  result: VoyagerResult;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  onEdit: (result: VoyagerResult) => void;
  publisher: string;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  onPopupClose: () => void;
  degree: string;
  subject: string;
};

const PopupContent: FC<Omit<Props, 'show'>> = ({
  spotlightMatch,
  recommenderMatches,
  result,
  setShow,
  userInputs,
  prqSchema,
  onProgramCtaClick,
  onFormSubmit,
  onEdit,
  publisher,
  currentResultApplicationCta,
  isHeclidLoading,
  isCurrentResultApplicationCtaLoading,
  userPreferencesAndMilitary,
  onPopupClose,
  degree,
  subject,
}) => {
  const flowType = FlowType.POPUP;

  const {
    goToPRQ,
    goToPogramSelect,
    handleProgramCtaClick,
    handlePRQFormSubmit,
    currentStepId,
    isSubmittingLead,
    handleFormSubmission,
    handleEditConfirm,
    handleEdit,
    isEditing,
    pii,
    setPii,
    piiErrors,
  } = useProgramSubmissionFlow({
    userInputs,
    onFormSubmit,
    prqSchema,
    result,
    initialStep: StepTypes.PROGRAM_SELECT,
    onProgramCtaClick,
    onEdit,
  });

  const onFlowExit = async () => {
    setShow(false);
  };
  return (
    <div className={styles.popup}>
      <button className={styles.closeButton} type="button" onClick={onPopupClose}>
        <HiXMark size={10} strokeWidth={1} />
      </button>
      <Steps currentStepId={currentStepId}>
        <Step stepId={StepTypes.PROGRAM_SELECT}>
          <ProgramSelect
            spotlightMatch={spotlightMatch as VoyagerResult}
            recommenderMatches={recommenderMatches as VoyagerResult[]}
            onFlowExit={onFlowExit}
            firstName={userInputs?.firstName?.value}
            onProgramCtaClick={handleProgramCtaClick}
            publisherName={publisher}
            userPreferencesAndMilitary={userPreferencesAndMilitary}
            degree={degree}
            subject={subject}
          />
        </Step>
        <Step stepId={StepTypes.PII_CONFIRMATION}>
          <PiiConfirmation
            flowType={flowType}
            result={result}
            onFormSubmit={handleFormSubmission}
            onBackButtonClick={goToPogramSelect}
            onEdit={handleEdit}
            isHeclidLoading={isHeclidLoading}
            isSubmittingLead={isSubmittingLead}
            onEditConfirm={handleEditConfirm}
            pii={pii}
            piiErrors={piiErrors}
            setPii={setPii}
            isEditing={isEditing}
          />
        </Step>
        <Step stepId={StepTypes.ADDITIONAL_QUESTIONS}>
          <AdditionalQuestions2
            flowType={flowType}
            selectedResult={result}
            prqSchema={prqSchema as IdSchema}
            onFormSubmit={handlePRQFormSubmit}
            eventingOverrides={{}}
            onBackButtonClick={() => handleProgramCtaClick(result, {})}
            result={result}
            isSubmittingLead={isSubmittingLead}
          />
        </Step>

        <Step stepId={StepTypes.EXIT_STRATEGY}>
          <ExitStrategyComponent
            onBackButtonClick={goToPRQ}
            result={result}
            firstName={userInputs?.firstName?.value}
            flowType={flowType}
            currentResultApplicationCta={currentResultApplicationCta}
            isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
            showDrawerHeader={false}
            userPreferencesAndMilitary={userPreferencesAndMilitary}
          />
        </Step>

        <Step stepId={StepTypes.NOT_QUALIFIED}>
          <NotQualified flowType={flowType} result={result} onCtaClick={onFlowExit} />
        </Step>

        <Step stepId={StepTypes.LOADING}>
          <SubmissionLoader flowType={flowType} result={result} />
        </Step>
      </Steps>
    </div>
  );
};

const PopupExperience: FC<Props> = ({ show, ...otherProps }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [show]);

  return (
    <Popup show={show}>
      <PopupContent {...otherProps} />
    </Popup>
  );
};

export default PopupExperience;
