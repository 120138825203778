import { type FC, type ReactNode } from 'react';

import styles from './Popup.module.css';

type Props = {
  show: boolean;
  children: ReactNode;
};

const Popup: FC<Props> = ({ show, children }) =>
  show ? (
    <div className={styles.popup}>
      <div className={styles.overlay} />
      {children}
    </div>
  ) : null;

export default Popup;
