import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import { CTA_LABEL } from '../../constants';
import styles from './MobileCta.module.css';

type Props = {
  result: VoyagerResult;

  onSubmit: () => void;
  onSkip: () => void;
};

const MobileCta: FC<Props> = ({ result: { school }, onSubmit, onSkip }) => (
  <div className={styles.mobileCta}>
    <button
      className={styles.mobileSubmitInfoBtn}
      id="reward_click_popup"
      data-tf-element-role="submit"
      onClick={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      type="submit"
    >
      {CTA_LABEL}
    </button>
  </div>
);

export default MobileCta;
