import { FC, ButtonHTMLAttributes } from 'react';
import { HiChevronDoubleLeft } from 'react-icons/hi';

import styles from './DoubleChevronBackButton.module.css';

const DoubleChevronBackButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <button className={styles.doubleChevronBackButton} type="button" {...props}>
    <HiChevronDoubleLeft size={18} /> Back to your top matches
  </button>
);

export default DoubleChevronBackButton;
