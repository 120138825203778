import { type FC, type Dispatch, type SetStateAction, ReactNode } from 'react';

import { FlowType } from '@core/blocks/best-match-experience/types';
import type { VoyagerResult } from '@core/ts/results';

import PiiConfirmationDrawer from './PiiConfirmationDrawer';
import PiiConfirmationPopup from './PiiConfirmationPopup';

type Props = {
  onFormSubmit: () => void;
  onBackButtonClick: () => void;
  flowType: FlowType;
  result: VoyagerResult;
  onEdit: () => void;
  isHeclidLoading: boolean;
  isSubmittingLead: boolean;
  onEditConfirm: () => void;
  pii: Record<string, string>;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  isEditing: boolean;
  headerComponent?: ReactNode;
};

const PiiConfirmation: FC<Props> = (props) => {
  const { flowType } = props;
  const PiiConfirmationComponent = flowType === FlowType.POPUP ? PiiConfirmationPopup : PiiConfirmationDrawer;
  return <PiiConfirmationComponent {...props} />;
};

export default PiiConfirmation;
