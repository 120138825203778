import { FC } from 'react';
import { useSelector } from 'react-redux';

import { CompactCard } from '@core/blocks/edu-flow-2/components/CompactCard';
import { DefaultPageProps } from '@core/blocks/edu-flow/components/Page/DefaultPage';
import { selectLeadDelivery } from '@core/reducers/matchesSlice';

import AdditionalContentFields from '../AdditionalContentFields';
import StatCta from '../AdditionalContentFields/StatCta';
import BackButton from '../BackButton';
import Fields from '../Fields';
import ProgressBar from '../ProgressBar';
import PageHeader from '../QuestionHeader';
import SubmitButton from '../SubmitButton';
import styles from './Page.module.css';

const Page: FC<DefaultPageProps> = ({
  flow,
  page,
  prevPage,
  onPreviousPage,
  showContinueButton,
  buttonRef,
  question,
  fieldErrors,
  onContinue,
}) => {
  const { heading, preSubheading, postSubheading, showSelectedProgram, sideDisclaimer, bottomDisclaimer } = question;
  const { currentPrograms } = useSelector(selectLeadDelivery);

  const showProgressBar = flow && page && question.hideProgressBarAndBackButton !== true;
  const showNextQuestionButton =
    (question.showNextQuestionButton || showContinueButton) && question.showContinueButton !== false;

  return (
    <div className={styles.page}>
      {prevPage && question.hideProgressBarAndBackButton !== true && (
        <div data-testid="backbutton" className={styles.backButtonContainer}>
          <BackButton onClick={onPreviousPage} />
        </div>
      )}
      <div className={styles.pageContentContainer}>
        <div className={styles.pageContent}>
          <div>
            {showProgressBar && (
              <div data-testid="progressbar" className={styles.progressBarContainer}>
                <div className={styles.mobileBack}>
                  <BackButton onClick={onPreviousPage} />
                </div>
                <ProgressBar flow={flow} page={page} />
              </div>
            )}
            {showSelectedProgram && currentPrograms?.length ? (
              <div className={styles.schoolCardContainer}>
                <p>You selected:</p>
                <div className={styles.selectedProgramContainer}>
                  <CompactCard result={currentPrograms[0]} />
                </div>
              </div>
            ) : null}
            <PageHeader heading={heading} preSubheading={preSubheading} postSubheading={postSubheading} />
            <Fields onContinue={onContinue} fields={question.fields} heading={question.heading} errors={fieldErrors} />
          </div>
          <div data-testid="next-question-button-container" className={styles.submitButtonContainer}>
            {bottomDisclaimer && (
              <div className={styles.bottomDisclaimer} dangerouslySetInnerHTML={{ __html: bottomDisclaimer }} />
            )}
            {showNextQuestionButton && (
              <SubmitButton
                text={question.nextQuestionButtonLabel ? question.nextQuestionButtonLabel : 'Next Question'}
                disabled={!showContinueButton}
                onClick={onContinue}
                buttonRef={buttonRef}
              />
            )}
          </div>
        </div>
      </div>
      {question.hideSidebar !== true && (
        <div className={styles.pageSidebarContainer}>
          <div className={styles.pageSidebar}>
            {question.additionalContent ? <AdditionalContentFields fields={question.additionalContent} /> : <StatCta />}
            {question.sideDisclaimer && (
              <div className={styles.sideDisclaimer} dangerouslySetInnerHTML={{ __html: question.sideDisclaimer }} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
