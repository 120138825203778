import { type FC } from 'react';

import type { ApplicationCta } from '@core/blocks/best-match-experience/types';
import type { VoyagerResult } from '@core/ts/results';

import ApplicationCTA from '../../ApplicationCTA';
import SchoolInfoHeader from '../../SchoolInfoHeader';
import ExitStrategyCongratsBanner from '../ExitStrategyCongratsBanner';
import ExitStrategyContent from '../ExitStrategyContent';
import styles from './ExitStrategyPopup.module.css';

type Props = {
  firstName: string;
  result: VoyagerResult;
  currentResultApplicationCta: ApplicationCta;
  isCurrentResultApplicationCtaLoading?: boolean;
};

const ExitStrategyPopup: FC<Props> = ({
  firstName,
  result,
  currentResultApplicationCta,
  isCurrentResultApplicationCtaLoading,
}) => (
  <section className={styles.exitStrategyContainer}>
    <SchoolInfoHeader result={result} />
    <div className={styles.popupContentContainer}>
      <ExitStrategyCongratsBanner firstName={firstName} />
      {currentResultApplicationCta && currentResultApplicationCta?.url && (
        <div className={styles.applicationCtaPopupCard}>
          <ApplicationCTA
            result={result}
            cta={currentResultApplicationCta}
            isLoading={isCurrentResultApplicationCtaLoading}
          />
        </div>
      )}
      <ExitStrategyContent result={result} />
    </div>
  </section>
);

export default ExitStrategyPopup;
