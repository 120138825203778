import { type SearchIndex } from 'algoliasearch/lite';

import type { VoyagerResult } from '@core/ts/results';

type Params = {
  searchIndex: SearchIndex;
  result: VoyagerResult;
};

const fetchOtherProgramsForSchool = async ({ searchIndex, result }: Params): Promise<VoyagerResult[]> => {
  const filters = `program.degree.name:"${result.program.degree.name}" AND school.id:${result.school.id} AND NOT program.id:${result.program.id}`;

  const response = await searchIndex.search<VoyagerResult>('', {
    filters,
    hitsPerPage: 12,
  });

  return response?.hits ?? [];
};

export default fetchOtherProgramsForSchool;
