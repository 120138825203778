import { type ReactNode, type FC } from 'react';

import PulsingDotLoader from '@core/shared/components/PulsingDotLoader';

import styles from './ButtonLoader.module.css';

type Props = {
  isLoading?: boolean;
  children: ReactNode;
};

const ButtonLoader: FC<Props> = ({ isLoading, children }) =>
  isLoading ? (
    <div className={styles.dotsLoader}>
      <PulsingDotLoader />
    </div>
  ) : (
    <> {children}</>
  );

export default ButtonLoader;
