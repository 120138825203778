import { RecommenderPopup } from '@edu-match/components';
import EmbeddedRecommender from '@edu-match/components/EmbeddedRecommender';
import { type FC } from 'react';

import { ALGOLIA_BASE_INDEX_NAME } from '@core/services/AlgoliaService/v2';
import UserExperienceSurvey from '@core/shared/features/UserExperienceSurvey';
import type { Hit } from '@core/ts/algolia';
import cn from '@core/utils/classNames';
import { AddActiveProspectScript } from '@core/utils/scripts/AddActiveProspectScript';

import DuplicatePopup from '../edu-match/components/DuplicatePopup';
import styles from './EduResults.module.css';
import { NoResults } from './components/NoResults';
import ResultsList from './components/ResultsList';
import type { EduResultsProps } from './types';

const EduResultsUI: FC<EduResultsProps> = ({
  showEmbeddedRecommender,
  recommenderResults,
  recommenderData,
  userFirstName,
  isActiveProspectScriptRequired,
  subject,
  showDuplicatePopup,
  dcsConsolidationResults,
  showRecommenderPopup,
  searchClient,
  popupData,
  popupEventing,
  enhancedSchoolData,
  showAdVerbiageTest,
  exactResults,
  consolidatedResults,
  showUgToGradResultsPageABTest,
  unborrowedMatches,
  isGradResultsTest,
  showUserExperienceSurvey,
}) => {
  const exactMatchesHeader =
    showEmbeddedRecommender && Boolean(recommenderResults?.recommender?.data?.length)
      ? 'More Matches For You'
      : `Welcome to your results, ${userFirstName}!`;

  const exactMatchesSubheader = `${
    showEmbeddedRecommender
      ? ''
      : 'Pick your favorite school, set up a call with an admissions administrator, and start your application — all in one place.'
  } ${isGradResultsTest ? '' : `These schools offer programs in ${subject} or areas closely related to this subject.`}`;

  return (
    <div className={isGradResultsTest && styles.bgContainer}>
      {isActiveProspectScriptRequired && <AddActiveProspectScript />}
      <div className={cn(styles.eduResultsContainer, isGradResultsTest && styles.gradRPTest)}>
        {showEmbeddedRecommender && !!recommenderData?.length && (
          <div className={styles.eduResultsIntro}>
            <h1 className={styles.eduResultsHeader}>Welcome to your results, {userFirstName}!</h1>
            <p className={styles.eduResultsSubheader}>
              Pick your favorite school, set up a call with an admissions administrator, and start your application —
              all in one place.
            </p>
          </div>
        )}
        {showDuplicatePopup && (
          <DuplicatePopup searchClient={searchClient} indexName={ALGOLIA_BASE_INDEX_NAME} resultsRevamp />
        )}
        {showRecommenderPopup && !!popupData?.length && (
          <RecommenderPopup
            hits={popupData}
            eventingOverrides={popupEventing}
            enhancedSchoolData={enhancedSchoolData}
            showAdVerbiageTest={showAdVerbiageTest}
          />
        )}

        {showEmbeddedRecommender && !!recommenderData?.length && (
          <EmbeddedRecommender
            hits={recommenderData}
            newResults
            enhancedSchoolData={enhancedSchoolData}
            showAdVerbiageTest={showAdVerbiageTest}
          />
        )}

        {dcsConsolidationResults?.length > 0 ? (
          <ResultsList
            enhancedSchoolData={enhancedSchoolData}
            header={exactMatchesHeader}
            subheader={exactMatchesSubheader}
            results={exactResults}
            borrowedResults={consolidatedResults}
            isExactMatch
            showAdVerbiageTest={showAdVerbiageTest}
            showUgToGradResultsPageABTest={showUgToGradResultsPageABTest}
          />
        ) : (
          ((showEmbeddedRecommender && recommenderData?.length && recommenderData?.length < 1) ||
            !showEmbeddedRecommender) &&
          unborrowedMatches?.length < 1 && <NoResults />
        )}
        {unborrowedMatches?.length > 0 && (
          <ResultsList
            enhancedSchoolData={enhancedSchoolData}
            header="Other School Programs You Might Like"
            subheader={`These schools offer similar programs to ${subject}.`}
            results={unborrowedMatches}
            showAdVerbiageTest={showAdVerbiageTest}
            showUgToGradResultsPageABTest={showUgToGradResultsPageABTest}
          />
        )}
      </div>
      <div className={styles.banners}>
        {showUserExperienceSurvey && !showRecommenderPopup && <UserExperienceSurvey />}
      </div>
    </div>
  );
};

export default EduResultsUI;
