import { type FC, type MouseEventHandler, useEffect, useRef } from 'react';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useIntersectionObserver from '@core/hooks/useIntersectionObserver';
import cn from '@core/utils/classNames';

import styles from './PsychCardDescriptionCta.module.css';

type Props = {
  showDescription: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  viewCorrelationId: string;
  isCoveredByElement?: boolean;
  position?: number;
  doesDescriptionOverflow?: boolean;
};

const PsychCardDescriptionCta: FC<Props> = ({
  showDescription,
  onClick,
  viewCorrelationId,
  position,
  doesDescriptionOverflow,
  isCoveredByElement,
}) => {
  const descriptionButtonText = showDescription ? 'Read Less -' : 'Read More +';

  const ctaRef = useRef<HTMLButtonElement>(null);

  const isInView = useIntersectionObserver(ctaRef);

  const { elementClicked, elementViewed } = useElementEvents({
    correlationId: viewCorrelationId,
    webElement: {
      elementType: 'read-more-cta',
      text: 'Read More',
      location: 'program-card',
      htmlId: 'read-more-program-card-cta',
      name: 'read-more-cta',
      position: `${position}`,
    },
  });

  const handleDescriptionClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick?.(e);
    if (!showDescription) elementClicked();
  };

  useEffect(() => {
    /* only runs the element viewed once, 
    if many renders occur then we clear the current timeout call
     and make a new one until rendering stops.
    */
    let timeout: ReturnType<typeof setTimeout>;

    if (isInView && !isCoveredByElement) {
      timeout = setTimeout(() => {
        elementViewed();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isInView, isCoveredByElement, elementViewed]);

  return (
    <button
      ref={ctaRef}
      type="button"
      className={cn(styles.cardDescriptionToggle, !doesDescriptionOverflow && styles.hide)}
      onClick={handleDescriptionClick}
    >
      {descriptionButtonText}
    </button>
  );
};

export default PsychCardDescriptionCta;
