import { type ReactElement, type PropsWithChildren } from 'react';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';

type ReturnType = {
  SlideoutTitleEA: (props: PropsWithChildren) => ReactElement;
  SlideoutSubtitleEA: (props: PropsWithChildren) => ReactElement;
  PiiTitleEA: (props: PropsWithChildren) => ReactElement;
  CtaTitleEA: (props: PropsWithChildren) => ReactElement;
  CtaSubtitleEA: (props: PropsWithChildren) => ReactElement;
  CtaLabelEA: (props: PropsWithChildren) => ReactElement;
};

const useGradConversionEditableAsset = (): ReturnType => {
  const flags = useFeatureFlags();

  const isTestEnabled = !!flags.voyagerGradConversionCtaTest;

  // Attach NR alert for missing or incorrect parameters and build the EA component
  const buildEAComponent = (testValue?: string) => {
    if (isTestEnabled && !testValue) {
      newRelicNoticeError(
        nrErrorMap.EDITABLE_ASSET,
        new Error(`Missing or incorrect parameter in editable asset: voyagerGradConversionCtaTest`),
        {
          voyagerGradConversionCtaTest: flags.voyagerGradConversionCtaTest,
        }
      );
    }
    // use the test value if the test is enabled and the value is present else return the children (control behavior)
    return ({ children }: PropsWithChildren) => <>{isTestEnabled && testValue ? testValue : children}</>;
  };

  return {
    SlideoutTitleEA: buildEAComponent(flags?.voyagerGradConversionCtaTest?.slideoutTitle),
    SlideoutSubtitleEA: buildEAComponent(flags?.voyagerGradConversionCtaTest?.slideoutSubtitle),
    PiiTitleEA: buildEAComponent(flags?.voyagerGradConversionCtaTest?.piiTitle),
    CtaTitleEA: buildEAComponent(flags?.voyagerGradConversionCtaTest?.ctaTitle),
    CtaSubtitleEA: buildEAComponent(flags?.voyagerGradConversionCtaTest?.ctaSubtitle),
    CtaLabelEA: buildEAComponent(flags?.voyagerGradConversionCtaTest?.ctaLabel),
  };
};

export default useGradConversionEditableAsset;
