import Loading from '@edu-flow-2/components/Loading';
import { type IdSchema } from '@rjsf/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { selectDcs } from '@core/reducers/dcsSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import {
  selectExactMatches,
  selectRecommenderMatches,
  selectRelatedMatches,
  selectSpotlightMatch,
} from '@core/reducers/matchesSlice';
import { selectQueryParam } from '@core/reducers/queryParamsSlice';
import { saveContact } from '@core/services/hub';
import { submitLeadBME } from '@core/services/leadDelivery';
import { mobiusGetApplicationCta, mobiusRedirect } from '@core/services/mobius';
import { PageActions } from '@core/services/newRelic';
import UserExperienceSurvey from '@core/shared/features/UserExperienceSurvey';
import type { VoyagerResult } from '@core/ts/results';
import buildLeadObject, { type BuildLeadObjectInputs } from '@core/utils/buildLeadObject';
import Diffy from '@core/utils/diffy';
import getToken from '@core/utils/getToken';
import { isUnderGraduate } from '@core/utils/isUnderGraduate';

import styles from './BestMatchExperience.module.css';
import DrawerExperience from './components/DrawerExperience';
import DuplicatePopup from './components/DuplicatePopup';
import EmbeddedRecommender from './components/EmbeddedRecommender';
import PopupExperience from './components/PopupExperience';
import ResourcesBanner from './components/ResourcesBanner';
import ResultList from './components/ResultList';
import UserPreferencesBanner from './components/UserPreferencesBanner';
import UserXpBanner from './components/UserXpBanner';
import useFetchOtherProgramsForSchool from './hooks/useFetchOtherProgramsForSchool';
import { type EventingOverrides, productClicked } from './services/events/productEvents';
import type { ApplicationCta, Submission, UserPreferencesAndMilitary } from './types';

const BestMatchExperienceContainer = () => {
  const flags = useFeatureFlags();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isDuplicatePopupOpen, setIsDuplicatePopupOpen] = useState<boolean>(false);
  const [showUserXpBanner, setShowUserXpBanner] = useState<boolean>(false);
  const [currentResult, setCurrentResult] = useState<VoyagerResult | undefined>(undefined);
  const [otherProgramsForSelectedSchool, setOtherProgramsForSelectedSchool] = useState<VoyagerResult[] | undefined>(
    undefined
  );
  const [hasContactBeenSaved, setHasContactBeenSaved] = useState<boolean>(false);

  const [prqSchema, setPrqSchema] = useState<IdSchema>();
  const dcs = useSelector(selectDcs);
  const [degree] = dcs?.dcsDegrees ?? [];
  const [subject] = dcs?.dcsSubjects ?? [];
  const [category] = dcs?.dcsCategories ?? [];
  const publisher = useSelector((state) => selectQueryParam(state, 'publisher'));

  const inputs = useSelector(selectAllInputs);

  const userPreferences = useMemo(() => inputs['decision-factors']?.options ?? [], [inputs]);

  const userPreferencesAndMilitary: UserPreferencesAndMilitary = useMemo(
    () => [...userPreferences, { key: inputs.militaryAffiliation?.value.toString(), label: 'militaryAffiliation' }],
    [userPreferences, inputs.militaryAffiliation?.value]
  );

  const firstName = inputs?.firstName?.value;
  const [productCorrelationId, setProductCorrelationId] = useState<string>('');
  const [heclid, setHeclid] = useState<string>('');
  const isHeclidAvailable = Boolean(heclid?.length);
  const [contactId, setContactId] = useState<string>('');
  const [applicationCtaRecord, setApplicationCtaRecord] = useState<Record<string, ApplicationCta>>({});

  const isCurrentResultApplicationCtaLoading = Boolean(
    !applicationCtaRecord[currentResult?.program?.id ?? '']?.url?.length
  );
  const [submissions, setSubmissions] = useState<Submission[]>([]);

  const isGrad = !isUnderGraduate(degree);
  const showResourcesBanner = isGrad && submissions?.length >= 3;

  const spotlightMatch = useSelector(selectSpotlightMatch);
  const recommenderMatches = useSelector(selectRecommenderMatches);
  const exactMatches = useSelector(selectExactMatches);
  const relatedMatches = useSelector(selectRelatedMatches);
  const resultsCount = (exactMatches?.length ?? 0) + (relatedMatches?.length ?? 0);
  const hasExactMatches = Boolean(exactMatches?.length);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(recommenderMatches?.length > 0 || Boolean(spotlightMatch));

  const [loading, setLoading] = useState<boolean>(true);

  // show user xp banner after 2 minutes
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowUserXpBanner(true);
    }, 120000);

    return () => clearTimeout(timeout);
  }, []);

  // show loading for at least 3 seconds and until exact matches isn't undefined
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (loading && exactMatches) setLoading(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [loading, exactMatches]);

  const fetchOtherProgramsForSchool = useFetchOtherProgramsForSchool(inputs);

  const checkIfProgramHasBeenSubmitted = useCallback(
    (result?: VoyagerResult) => submissions?.some((submission) => submission.programId === result?.program?.id),
    [submissions]
  );

  const findProgramApplicationCtaInRecord = useCallback(
    (result?: VoyagerResult) => applicationCtaRecord[result?.program?.id ?? ''],
    [applicationCtaRecord]
  );

  const hasCurrentProgramBeenSubmitted = submissions.some(
    (submission) =>
      submission.programId === currentResult?.program?.id || submission.schoolId === currentResult?.school?.id
  );

  const currentResultApplicationCta = findProgramApplicationCtaInRecord(currentResult);

  const diffy = useMemo(() => new Diffy(inputs), [inputs]);

  const { elementViewed, elementClicked } = useElementEvents();

  useEffect(() => {
    if (!flags?.useContactDb || hasContactBeenSaved) return;

    saveContact({
      degree,
      category,
      subject,
      cohesionSessionId: window?._Cohesion?.sessionId,
      lead: buildLeadObject(inputs as BuildLeadObjectInputs),
      publisher,
    })
      .then((res) => {
        if (res?.data?.contactId) setContactId(res.data.contactId);
      })
      .then(() => setHasContactBeenSaved(true));
  }, [inputs, degree, subject, category, flags, publisher, hasContactBeenSaved]);

  const handleProgramCtaClick = useCallback(
    async (result: VoyagerResult, eventingOverrides: EventingOverrides = {}) => {
      setCurrentResult(result);

      fetchOtherProgramsForSchool?.(result).then((otherPrograms) => {
        setOtherProgramsForSelectedSchool(otherPrograms);
      });

      const isAlreadySubmitted = checkIfProgramHasBeenSubmitted(result);

      if (isAlreadySubmitted) {
        setIsDrawerOpen(true);
        return;
      }

      if (submissions.some((submission) => submission.schoolId === result?.school?.id)) {
        setIsDuplicatePopupOpen(true);
        setSubmissions([...submissions, { programId: result?.program?.id, schoolId: result?.school?.id }]);
        return;
      }

      if (!isAlreadySubmitted) {
        const newProductCorrelationId = uuid();
        setProductCorrelationId(newProductCorrelationId);

        productClicked(result, userPreferencesAndMilitary, {
          correlationId: newProductCorrelationId,
          location: eventingOverrides?.location,
          formatSubtype: eventingOverrides?.formatSubtype,
          formatType: eventingOverrides?.formatType,
          customDimensions: eventingOverrides?.customDimensions,
        });

        setPrqSchema(diffy.diffLdsSchema(result?.leadSchema, result?.leadSource));

        mobiusRedirect({
          idToken: getToken(),
          productCorrelationId: newProductCorrelationId,
          link: result?.url,
          trackingContextOverride: {
            formatType: 'app',
            formatSubtype: 'grid',
          },
        }).then(({ heclid, error }) => {
          if (!error) setHeclid(heclid);
        });

        mobiusGetApplicationCta({
          programId: result?.program?.id.toString(),
          correlationId: productCorrelationId,
          heclid,
        }).then(({ url, label }) => {
          setApplicationCtaRecord((prev) => ({ ...prev, [result?.program?.id]: { url, label } }));
        });

        if (!isPopupOpen) {
          setIsDrawerOpen(true);
          elementViewed({
            correlationId: result.eventing?.viewCorrelationId,
            webElement: {
              location: 'drawer',
              name: 'exit-button',
              htmlId: 'voyager-takeover-exit',
            },
          });
        }
      }
    },
    [
      fetchOtherProgramsForSchool,
      checkIfProgramHasBeenSubmitted,
      submissions,
      userPreferencesAndMilitary,
      diffy,
      productCorrelationId,
      heclid,
      isPopupOpen,
      elementViewed,
    ]
  );

  const handleEdit = useCallback(() => {
    elementClicked({
      correlationId: productCorrelationId,
      webElement: {
        elementType: 'card',
        location: 'drawer',
        htmlId: 'voyager-takeover-edit-pii',
        name: 'edit-info',
      },
    });
  }, [elementClicked, productCorrelationId]);

  // reset common state between different experiences
  const handleClose = useCallback(() => {
    setHeclid('');
    setCurrentResult(undefined);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsDrawerOpen(false);
    handleClose();
    setOtherProgramsForSelectedSchool(undefined);
  }, [handleClose]);

  const handlePopupClose = useCallback(() => {
    elementClicked({
      correlationId: productCorrelationId,
      webElement: {
        elementType: 'exit-element',
        location: 'popup',
        name: 'thank-you-confirmation',
        htmlId: 'thank-you-button',
      },
    });
    setIsPopupOpen(false);
    handleClose();
  }, [elementClicked, handleClose, productCorrelationId]);

  const handleDuplicatePopupClose = useCallback(() => {
    elementClicked({
      correlationId: productCorrelationId,
      webElement: {
        location: 'modal',
        name: 'dupe-info-drawer-close',
        elementType: 'BUTTON',
        text: 'close',
      },
      actionOutcome: 'CLOSE',
    });
    setIsDuplicatePopupOpen(false);
  }, [elementClicked, productCorrelationId]);

  const handleResourcesClick = useCallback(() => {
    elementClicked({
      correlationId: productCorrelationId,
      webElement: {
        elementType: 'pop-up',
        text: 'See resources',
        location: 'footer',
        htmlId: 'post-lead-resources-link',
        name: 'post-lead-resources',
      },
    });
  }, [elementClicked, productCorrelationId]);

  const handleViewNextSteps = useCallback(() => {
    setIsDuplicatePopupOpen(false);
    setIsDrawerOpen(true);
    elementClicked({
      correlationId: productCorrelationId,
      webElement: {
        location: 'modal',
        name: 'dupes-exit-experience',
        elementType: 'LINK',
        text: 'View next steps',
      },
      actionOutcome: 'OPEN',
    });
  }, [elementClicked, productCorrelationId]);

  const handleLeadSubmission = useCallback(
    async (result: VoyagerResult): Promise<number | undefined> => {
      const actionParams = {
        ...flags,
        anonymousId: window._Cohesion.anonymousId,
        correlationId: productCorrelationId,
      };
      PageActions.LeadAttempt(actionParams);
      const leadInputs = {
        ...inputs,
        tcpaText: { key: 'tcpaText', value: result?.school?.config?.tcpa },
      };
      const res = await submitLeadBME(result, leadInputs, heclid, productCorrelationId, contactId);

      if (res.status === 201) {
        PageActions.LeadSuccess(actionParams);
        setSubmissions([...submissions, { programId: result?.program?.id, schoolId: result?.school?.id }]);
      }

      return res.status;
    },
    [contactId, flags, heclid, inputs, productCorrelationId, submissions]
  );

  if (loading) {
    return <Loading />;
  }

  const embeddedRecommenderProductEventing: EventingOverrides = {
    location: 'voyager-recommender-embedded',
    formatSubtype: 'grid',
    customDimensions: [{ key: 'isUserRequested', value: 'true' }],
  };

  const exactMatchesProductEventing: EventingOverrides = {
    location: 'voyager-results-page',
    formatSubtype: 'grid',
    customDimensions: [{ key: 'isUserRequested', value: 'true' }],
  };

  const relatedMatchesProductEventing: EventingOverrides = {
    location: 'voyager-related-results-page',
    formatSubtype: 'grid',
    customDimensions: [{ key: 'isUserRequested', value: 'false' }],
  };

  const embeddedRecommenderClick = (result: VoyagerResult) =>
    handleProgramCtaClick(result, embeddedRecommenderProductEventing);
  const exactMatchesClick = (result: VoyagerResult) => handleProgramCtaClick(result, exactMatchesProductEventing);
  const relatedMatchesClick = (result: VoyagerResult) => handleProgramCtaClick(result, relatedMatchesProductEventing);
  return (
    <>
      <UserPreferencesBanner
        hasExactMatches={hasExactMatches}
        resultsCount={resultsCount}
        degreePill={degree}
        subjectPill={subject}
        userPreferences={userPreferences}
        firstName={firstName}
      />
      <div className={styles.bestMatchExperience}>
        <EmbeddedRecommender
          firstName={firstName}
          subject={subject}
          results={recommenderMatches}
          onProgramCardClick={embeddedRecommenderClick}
          isCoveredByElement={isPopupOpen}
          userPreferencesAndMilitary={userPreferencesAndMilitary}
          handleCheckIfProgramIsSubmitted={checkIfProgramHasBeenSubmitted}
        />
        <div className={styles.results}>
          <ResultList
            title="More Matches"
            description={`These schools offer programs in <b>${subject}</b> or are as closely related to this subject.`}
            results={exactMatches}
            onProgramCardClick={exactMatchesClick}
            handleCheckIfProgramIsSubmitted={checkIfProgramHasBeenSubmitted}
            handleFindProgramApplicationCta={findProgramApplicationCtaInRecord}
            isCoveredByElement={isPopupOpen}
            userPreferencesAndMilitary={userPreferencesAndMilitary}
            eventingOverrides={exactMatchesProductEventing}
          />
          <ResultList
            title="Additional Programs"
            description="These schools offer programs you might be interested in."
            results={relatedMatches}
            onProgramCardClick={relatedMatchesClick} // this is used on the card ctas
            handleCheckIfProgramIsSubmitted={checkIfProgramHasBeenSubmitted}
            handleFindProgramApplicationCta={findProgramApplicationCtaInRecord}
            isCoveredByElement={isPopupOpen}
            userPreferencesAndMilitary={userPreferencesAndMilitary}
            eventingOverrides={relatedMatchesProductEventing}
          />
        </div>

        <DrawerExperience
          open={isDrawerOpen}
          setOpen={setIsDrawerOpen}
          onClose={handleDrawerClose}
          isHeclidLoading={!isHeclidAvailable}
          onFlowExit={handleDrawerClose}
          userInputs={inputs}
          onProgramCtaClick={handleProgramCtaClick}
          prqSchema={prqSchema}
          relatedSchoolResults={otherProgramsForSelectedSchool as VoyagerResult[]}
          result={currentResult as VoyagerResult}
          onFormSubmit={handleLeadSubmission}
          onEdit={handleEdit}
          hasCurrentProgramBeenSubmitted={hasCurrentProgramBeenSubmitted}
          currentResultApplicationCta={currentResultApplicationCta}
          isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
          userPreferencesAndMilitary={userPreferencesAndMilitary}
        />

        <PopupExperience
          isHeclidLoading={!isHeclidAvailable}
          onProgramCtaClick={handleProgramCtaClick}
          userInputs={inputs}
          show={isPopupOpen}
          setShow={setIsPopupOpen}
          onPopupClose={handlePopupClose}
          onFormSubmit={handleLeadSubmission}
          spotlightMatch={spotlightMatch as VoyagerResult}
          recommenderMatches={recommenderMatches as VoyagerResult[]}
          result={currentResult as VoyagerResult}
          prqSchema={prqSchema}
          onEdit={handleEdit}
          publisher={publisher}
          currentResultApplicationCta={currentResultApplicationCta}
          isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
          userPreferencesAndMilitary={userPreferencesAndMilitary}
          degree={degree}
          subject={subject}
        />
        {currentResult && (
          <DuplicatePopup
            onViewNextSteps={handleViewNextSteps}
            show={isDuplicatePopupOpen}
            onClose={handleDuplicatePopupClose}
            result={currentResult}
            otherProgramsForSchool={otherProgramsForSelectedSchool as VoyagerResult[]}
          />
        )}
        {showResourcesBanner && <ResourcesBanner handleResourcesClick={handleResourcesClick} />}
        {showUserXpBanner && submissions.length <= 0 && (
          <div className={styles.userExperienceSurveyBanner}>
            <UserExperienceSurvey BannerComponent={UserXpBanner} isCoveredByElement={isPopupOpen || isDrawerOpen} />
          </div>
        )}
      </div>
    </>
  );
};

export default BestMatchExperienceContainer;
