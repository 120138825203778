import { FaRegClock } from 'react-icons/fa';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import styles from './SchoolIntro.module.css';

export default function SchoolDescription({ result }: { result: VoyagerResult }) {
  const { school, program } = result;
  const flags = useFeatureFlags();
  const isConversionCtaTest = flags?.conversionCtaCopyUnderGrad === 'test';
  return (
    <div className={styles.schoolIntro}>
      <span className={cn(styles.highlightsProgram, isConversionCtaTest && styles.gradRpTestProgramName)}>
        {program.name}
      </span>
      <h2 className={cn(styles.highlightsTitle, isConversionCtaTest && styles.gradRpTestHighlightTitle)}>
        School Highlights
      </h2>
      <div className={styles.flagsContainer}>
        {school?.config?.showNextStartDate && (
          <p className={styles.acceptingStudentsCta}>
            <FaRegClock size="20px" /> Now Accepting Students
          </p>
        )}
      </div>
      <p className={styles.highlightsContentIntro}>{program.snippet}</p>
    </div>
  );
}
