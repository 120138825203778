import { type FC } from 'react';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';

import { removeProgramFromList } from '@core/reducers/matchesSlice';
import type { VoyagerResult } from '@core/ts/results';

import styles from './CompactCard.module.css';

type Props = {
  result: VoyagerResult;
  showClose?: boolean;
};

const CompactCard: FC<Props> = ({ result, showClose = true }) => {
  const dispatch = useDispatch();
  const clickHandler = () => {
    dispatch(removeProgramFromList(result));
  };

  if (!result) return null;

  return (
    <div className={styles.card}>
      <div className={styles.logo}>
        <img
          src={`https://simple-storage-server.highereducation.com/${result?.school?.slug}.png?w=130&h=80`}
          alt={result?.school?.name}
        />
      </div>
      <span className={styles.subtitle}>{result?.program?.name}</span>
      {showClose && (
        <button type="button" className={styles.removeBtn} onClick={clickHandler}>
          <BsFillXCircleFill />
        </button>
      )}
    </div>
  );
};

export { CompactCard };
