import { type FC } from 'react';
import { HiOutlineCursorClick } from 'react-icons/hi';
import { TbBuildingBank, TbClipboardCheck } from 'react-icons/tb';

import usePageHeadingParser from '@core/blocks/edu-flow/components/Page/hooks/usePageHeadingParser';
import { MonarchPsychBanner1 } from '@core/context/types';
import cn from '@core/utils/classNames';

import styles from './PsychBanner1.module.css';

const PsychBanner1: FC<{
  header?: string;
  desktopSubheader?: string;
  mobileSubheader?: string;
  imageUrl?: string;
  listItem1Text?: string;
  listItem2Text?: string;
  listItem3Text?: string;
  isGrad?: boolean;
}> = ({
  header = "Hi, {firstName}! Let's Choose Your Psychology Program",
  desktopSubheader = 'Select a program and connect with an advisor for more details about accreditation, faculty, internships, and more.',
  mobileSubheader = 'Connect with an advisor for info on accreditation, faculty and internships.',
  imageUrl = 'https://res.cloudinary.com/highereducation/image/upload/v1739214820/BME/Voyager_Psych_Banner.png',
  listItem1Text = 'Compare psych programs.',
  listItem2Text = 'Connect with your top choice.',
  listItem3Text = 'Start your psych degree.',
  isGrad,
}: MonarchPsychBanner1) => {
  const parsePageHeading = usePageHeadingParser();

  return (
    <div className={cn(styles.psychBanner1, isGrad ? styles.paddingGrad : styles.paddingUG)}>
      <div className={styles.headingContainer}>
        <h1 dangerouslySetInnerHTML={{ __html: parsePageHeading(header) }} />
        <p className={styles.desktopSubheader} dangerouslySetInnerHTML={{ __html: desktopSubheader }} />
        <p className={styles.mobileSubheader} dangerouslySetInnerHTML={{ __html: mobileSubheader }} />
      </div>
      <div className={styles.imgListContainer}>
        <div className={styles.imageWrapper}>
          <img src={imageUrl} alt="Woman smiling in a cozy living room looking at her phone and laptop" />
        </div>
        <ul className={styles.listItems}>
          <li>
            <div className={styles.iconWrapper}>
              <TbBuildingBank color="#fff" size={16.5} />
            </div>
            <span dangerouslySetInnerHTML={{ __html: listItem1Text }} />
          </li>
          <li>
            <div className={styles.iconWrapper}>
              <TbClipboardCheck color="#fff" size={16.5} />
            </div>
            <span dangerouslySetInnerHTML={{ __html: listItem2Text }} />
          </li>
          <li>
            <div className={styles.iconWrapper}>
              <HiOutlineCursorClick color="#fff" size={16.5} />
            </div>
            <span dangerouslySetInnerHTML={{ __html: listItem3Text }} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PsychBanner1;
