import { motion } from 'framer-motion';
import { type FC, useEffect, useState } from 'react';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@core/shared/components/Tabs';
import type { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import ExitStrategyContent from '../ExitStrategy/ExitStrategyContent';
import ProgramOverview from '../ProgramOverview';
import SchoolHighlights from '../SchoolHighlights';
import SchoolStats from '../SchoolStats';
import styles from './ProgramInfoTabs.module.css';

const tabs = [
  { id: 'overview', label: 'Overview', position: '1' },
  { id: 'school-highlights', label: 'School Highlights', position: '2' },
  { id: 'school-stats', label: 'School Stats', position: '3' },
];

const WhatsNextTab = { id: 'whats-next', label: "What's Next", position: '4' };

type ProgramTabsTriggerProps = {
  activeTab: string;
  id: string;
  label: string;
};

const ProgramTabsTrigger: FC<ProgramTabsTriggerProps> = ({ activeTab, id, label }) => (
  <TabsTrigger key={id} value={id} className={cn(styles.tabsTrigger, activeTab === id && styles.active)}>
    {activeTab === id && (
      <>
        <motion.span
          layoutId="activeLine"
          className={styles.activeLine}
          transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
        />
      </>
    )}
    {label}
  </TabsTrigger>
);

type ProgramInfoTabsProps = {
  result: VoyagerResult;
  relatedSchoolResults?: VoyagerResult[];
  showWhatsNextTab: boolean;
  lastUserPreference: string;
};
const ProgramInfoTabs: FC<ProgramInfoTabsProps> = ({
  result,
  relatedSchoolResults,
  showWhatsNextTab,
  lastUserPreference,
}) => {
  const showSchoolHighlights = result.school.config?.valueProps?.find(
    ({ title, description }: { title: string; description: string }) => title?.length && description?.length
  );

  const [activeTab, setActiveTab] = useState(showSchoolHighlights ? 'school-highlights' : 'overview');
  const [canRedirectToWhatsNext, setCanRedirectToWhatsNext] = useState(true);

  useEffect(() => {
    if (showWhatsNextTab && canRedirectToWhatsNext) {
      setActiveTab(WhatsNextTab.id);
      setCanRedirectToWhatsNext(false);
    }
  }, [showWhatsNextTab, canRedirectToWhatsNext]);

  const { elementClicked } = useElementEvents();

  const tabClicked = (activeTab: string) => {
    setActiveTab(activeTab);
    elementClicked({
      correlationId: result.eventing?.viewCorrelationId,
      webElement: {
        position: tabs.find((tab) => tab.id === activeTab)?.position,
        elementType: 'button',
        text: activeTab,
        location: 'drawer',
        name: 'program-info',
        htmlId: 'program-info-cta',
      },
    });
  };

  return (
    <Tabs value={activeTab} onValueChange={tabClicked}>
      <TabsList className={styles.tabsList}>
        {tabs.map(({ id, label }) =>
          id === 'school-highlights' && !showSchoolHighlights ? null : (
            <ProgramTabsTrigger activeTab={activeTab} id={id} label={label} key={id} />
          )
        )}

        {showWhatsNextTab && (
          <ProgramTabsTrigger activeTab={activeTab} id={WhatsNextTab.id} label={WhatsNextTab.label} />
        )}
      </TabsList>

      <div className={styles.programInfoContainer}>
        <TabsContent value="overview">
          <ProgramOverview
            result={result}
            otherProgramsForSchool={relatedSchoolResults}
            lastUserPreference={lastUserPreference}
          />
        </TabsContent>

        {showSchoolHighlights && (
          <TabsContent value="school-highlights">
            <SchoolHighlights result={result} />
          </TabsContent>
        )}

        <TabsContent value="school-stats">
          <SchoolStats result={result} />
        </TabsContent>

        {showWhatsNextTab && (
          <TabsContent value="whats-next">
            <ExitStrategyContent result={result} />
          </TabsContent>
        )}
      </div>
    </Tabs>
  );
};
export default ProgramInfoTabs;
