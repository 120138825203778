import { type IdSchema } from '@rjsf/utils';
import { type Dispatch, type SetStateAction, type FC } from 'react';

import SchoolDetails from '@core/blocks/eduResults/components/SchoolDetails';
import useCheckScreen from '@core/blocks/eduResults/hooks/useCheckScreen';
import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import type { VoyagerResult } from '@core/ts/results';

import useProgramSubmissionFlow from '../../../hooks/useProgramSubmissionFlow';
import { FlowType, type ApplicationCta, type UserPreferencesAndMilitary } from '../../../types';
import AdditionalQuestions2 from '../../AdditionalQuestions2';
import DrawerExperienceHeader from '../../DrawerExperienceHeaderDesktop';
import ExitStrategyComponent from '../../ExitStrategy';
import ExitStrategyContent from '../../ExitStrategy/ExitStrategyContent';
import ExitStrategyDrawerHeader from '../../ExitStrategy/ExitStrategyDrawerHeader';
import MobileCta from '../../MobileCta';
import NotQualified from '../../NotQualified';
import PiiConfirmation from '../../PiiConfirmation';
import SchoolHighlightsV3 from '../../SchoolHighlights/v3';
import SchoolIntro from '../../SchoolIntro';
import { Step, Steps } from '../../Steps';
import { StepTypes } from '../../Steps/types';
import SubmissionLoader from '../../SubmissionLoader';
import styles from './DrawerExperience.module.css';

type Props = {
  result: VoyagerResult;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  prqSchema?: IdSchema;
  relatedSchoolResults: VoyagerResult[];
  userInputs: VoyagerInputs;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onEdit: (result: VoyagerResult) => void;
  onFlowExit: () => void;
  hasCurrentProgramBeenSubmitted: boolean;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const UnderGradDrawer: FC<Omit<Props, 'open' | 'setOpen' | 'onClose'>> = ({
  result,
  onFormSubmit,
  relatedSchoolResults,
  prqSchema,
  userInputs,
  onProgramCtaClick,
  onFlowExit,
  onEdit,
  hasCurrentProgramBeenSubmitted,
  currentResultApplicationCta,
  isHeclidLoading,
  isCurrentResultApplicationCtaLoading,
  userPreferencesAndMilitary,
}) => {
  const flowType = FlowType.DRAWER;

  const {
    goToPRQ,
    goToPogramSelect,
    handleProgramCtaClick,
    handlePRQFormSubmit,
    currentStepId,
    isSubmittingLead,
    handleFormSubmission,
    handleEditConfirm,
    handleEdit,
    pii,
    setPii,
    piiErrors,
  } = useProgramSubmissionFlow({
    userInputs,
    onFormSubmit,
    prqSchema,
    result,
    initialStep: StepTypes.PII_CONFIRMATION,
    onProgramCtaClick,
    hasCurrentProgramBeenSubmitted,
    onEdit,
  });

  const isExitStrategyStep = currentStepId === StepTypes.EXIT_STRATEGY;
  const isAdditionalQuestionsStep = currentStepId === StepTypes.ADDITIONAL_QUESTIONS;
  const { school, program } = result;
  const { isMobile } = useCheckScreen();

  return (
    <div className={styles.submissionContainer}>
      <div className={styles.schoolDetailsHeader}>{!isExitStrategyStep && <SchoolDetails school={school} />}</div>

      <div className={styles.submissionContainerInner}>
        <div className={styles.programInfoSectionDesktop}>
          {!isExitStrategyStep && (
            <div className={styles.schoolInfoContainer}>
              <DrawerExperienceHeader result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
              <SchoolIntro result={result} />
            </div>
          )}

          {isExitStrategyStep ? (
            <div>
              <ExitStrategyDrawerHeader result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
              <ExitStrategyContent result={result} />
            </div>
          ) : (
            <div className={styles.schoolInfoContainer}>
              <SchoolHighlightsV3 result={result} />
            </div>
          )}
        </div>
        <div className={styles.programSubmissionFlowContainer}>
          <Steps currentStepId={currentStepId}>
            <Step stepId={StepTypes.PII_CONFIRMATION} className={styles.piiConfirmationStep}>
              <h2 className={styles.programName}>{result?.program?.name}</h2>
              <PiiConfirmation
                flowType={flowType}
                result={result}
                onFormSubmit={handleFormSubmission}
                onBackButtonClick={goToPogramSelect}
                onEdit={handleEdit}
                isHeclidLoading={isHeclidLoading}
                isSubmittingLead={isSubmittingLead}
                onEditConfirm={handleEditConfirm}
                pii={pii}
                piiErrors={piiErrors}
                setPii={setPii}
                isEditing={false}
              />
            </Step>
            <Step stepId={StepTypes.ADDITIONAL_QUESTIONS}>
              <AdditionalQuestions2
                flowType={flowType}
                selectedResult={result}
                prqSchema={prqSchema as IdSchema}
                onFormSubmit={handlePRQFormSubmit}
                eventingOverrides={{}}
                onBackButtonClick={() => handleProgramCtaClick(result, {})}
                result={result}
                isSubmittingLead={isSubmittingLead}
              />
            </Step>

            <Step stepId={StepTypes.EXIT_STRATEGY}>
              <div className={styles.exitStrategyStepContainer}>
                <ExitStrategyComponent
                  onBackButtonClick={goToPRQ}
                  result={result}
                  firstName={userInputs?.firstName?.value}
                  flowType={flowType}
                  currentResultApplicationCta={currentResultApplicationCta}
                  isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
                  userPreferencesAndMilitary={userPreferencesAndMilitary}
                  showDrawerHeader={isMobile}
                />
              </div>
            </Step>

            <Step stepId={StepTypes.NOT_QUALIFIED}>
              <NotQualified flowType={flowType} result={result} onCtaClick={onFlowExit} />
            </Step>

            <Step stepId={StepTypes.LOADING}>
              <SubmissionLoader flowType={flowType} result={result} />
            </Step>
          </Steps>
          <div className={styles.programInfoSectionMobile}>
            {!isExitStrategyStep && (
              <>
                <div className={styles.schoolIntro}>
                  {!isAdditionalQuestionsStep && (
                    <MobileCta result={result} onSubmit={handleFormSubmission} onSkip={onFlowExit} />
                  )}
                </div>
              </>
            )}
            {isExitStrategyStep ? (
              <ExitStrategyContent result={result} />
            ) : (
              <>
                <DrawerExperienceHeader result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
                <SchoolIntro result={result} />
                <SchoolHighlightsV3 result={result} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderGradDrawer;
