import { type ChangeEvent, type FC, type ReactNode, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';

type Props = {
  fieldName: string;
  fieldLabel: string;
  defaultValue: string;
  inputName: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
};
const InputFieldWithFieldEventing: FC<Props> = ({
  defaultValue,
  fieldName,
  fieldLabel,
  onChange,
  inputName,
  isDisabled,
}) => {
  const [canFireFieldInputted, setCanFireFieldInputted] = useState(true);
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldInputted } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: true,
      fieldType: 'contact',
      fieldValue: 'SANITIZED',
    },
  });

  const onFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);

    if (canFireFieldInputted) {
      fieldInputted({
        userInputField: {
          fieldName,
          fieldLabel,
        },
      });
      setCanFireFieldInputted(false);
    }
  };
  const handleFocus = () => {
    setCanFireFieldInputted(true);
  };

  return inputName !== 'phone' ? (
    <input
      name={inputName}
      type="text"
      id={inputName}
      data-testid={`pii-form-${inputName}-input`}
      onChange={onFormChange}
      onFocus={handleFocus}
      aria-label={inputName}
      defaultValue={defaultValue}
      disabled={isDisabled}
      data-tf-element-role={`consent-grantor-${inputName}`}
    />
  ) : (
    <InputMask
      name={inputName}
      id="phone"
      mask="(999) 999-9999"
      maskChar=""
      autoComplete="on"
      type="text"
      placeholder="Phone"
      data-testid="pii-form-phone-input"
      defaultValue={defaultValue}
      onChange={onFormChange}
      onFocus={handleFocus}
      aria-label="phone"
      disabled={isDisabled}
    />
  );
};
export default InputFieldWithFieldEventing;
