import { type FC, type FormEvent, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import SlideUpPopup from '@core/shared/components/SlideUpPopup';
import { ElementEventProperties } from '@core/ts/cohesion';

import styles from './UserExperienceSurvey.module.css';
import { userInputFieldPayload, elementEventPayloads } from './eventingPayloads';

const options = [
  'I was expecting to see other schools here.',
  "There wasn't enough information to make a decision.",
  "I'd like to do more research directly on the school's website.",
  'Other',
];

export type BannerProps = {
  onClick: () => void;
};

const DefaultBanner: FC<BannerProps> = ({ onClick }) => (
  <section className={styles.userExperienceSurvey}>
    <div className={styles.userExperienceSurveyBanner}>
      <div className={styles.userExperienceSurveyBannerContainer}>
        <h2>Not what you expected?</h2>
        <button type="button" onClick={onClick}>
          Give feedback
        </button>
      </div>
    </div>
  </section>
);

type Props = {
  BannerComponent?: FC<BannerProps>;
  isCoveredByElement?: boolean;
};

const UserExperienceSurvey: FC<Props> = ({ BannerComponent = DefaultBanner, isCoveredByElement }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasElementBeenViewed, setHasElementBeenViewed] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [otherInput, setOtherInput] = useState('');
  const [otherChecked, setOtherChecked] = useState(false);
  const [canFireFieldInputted, setCanFireFieldInputted] = useState(true);
  const { current: correlationId } = useRef(uuid());

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId,
  });
  const { fieldViewed, fieldSelected } = useFieldEvents({
    correlationId,
    userInputField: {
      autofilled: false,
      fieldName: 'survey-feedback',
      fieldLabel: 'Give us your feedback',
      fieldType: 'checkbox',
    },
  });

  const { fieldInputted } = useFieldEvents({
    correlationId,
    userInputField: {
      autofilled: false,
      fieldName: 'survey-feedback-other',
      fieldLabel: 'Give us your feedback',
      fieldType: 'checkbox',
      fieldNumber: 4,
      fieldValue: otherInput,
    },
  });

  useEffect(() => {
    if (!isCoveredByElement && !isModalOpen && !hasElementBeenViewed) {
      elementViewed(elementEventPayloads.giveFeedbackButton);
      setHasElementBeenViewed(true);
    }
  }, [elementViewed, isCoveredByElement]);

  const handleClickToOpenModal = () => {
    elementClicked(elementEventPayloads.giveFeedbackButton);
    setIsModalOpen(true);
    elementViewed(elementEventPayloads.popupCloseButton);
    elementViewed(elementEventPayloads.popupCancelButton);
    elementViewed(elementEventPayloads.popupSubmitButton);
    fieldViewed();
  };

  const handleClose = (elementClickedEventPayload: ElementEventProperties) => {
    elementClicked(elementClickedEventPayload);
    setIsModalOpen(false);
  };

  const handleChange = (event: FormEvent<HTMLInputElement>, label: string) => {
    if (label === 'Other') {
      setOtherChecked(event.currentTarget.checked);
    }
    const fieldSelectedPayload = {
      userInputField: {
        ...userInputFieldPayload,
        fieldLabel: label,
        fieldValue: String(event.currentTarget.checked),
      },
    };
    fieldSelected(fieldSelectedPayload);
  };

  const handleOtherInputChange = (event: FormEvent<HTMLInputElement>) => {
    setOtherInput(event.currentTarget.value);
  };

  const handleOtherInputBlur = (event: FormEvent<HTMLInputElement>) => {
    const fieldInputtedPayload = {
      userInputField: {
        ...userInputFieldPayload,
        fieldValue: event.currentTarget.value,
      },
    };
    if (canFireFieldInputted) {
      fieldInputted(fieldInputtedPayload);
      setCanFireFieldInputted(false);
    }
  };

  const handleFocus = () => {
    setCanFireFieldInputted(true);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    elementClicked(elementEventPayloads.popupSubmitButton);
    setIsFormSubmitted(true);
  };

  return (
    <>
      {!isFormSubmitted && <BannerComponent onClick={handleClickToOpenModal} />}

      <SlideUpPopup show={isModalOpen} onClose={() => handleClose(elementEventPayloads.popupXButton)}>
        <div className={styles.userExperienceSurveyPopup}>
          {!isFormSubmitted && (
            <>
              <h2>How can we improve your experience?</h2>
              <h3>Please select all that apply.</h3>
              <form onSubmit={handleSubmit}>
                <div className={styles.inputs}>
                  {options.map((option) => (
                    <label htmlFor={option} key={option}>
                      <input type="checkbox" id={option} name={option} onChange={(e) => handleChange(e, option)} />
                      {option}
                      {option === 'Other' && otherChecked && (
                        <input
                          type="text"
                          placeholder="Please specify"
                          value={otherInput}
                          className={styles.inputOther}
                          onChange={handleOtherInputChange}
                          onBlur={handleOtherInputBlur}
                          onFocus={handleFocus}
                          minLength={3}
                          required
                        />
                      )}
                    </label>
                  ))}
                </div>
                <div className={styles.buttonGroup}>
                  <button
                    className={styles.secondaryBtn}
                    type="button"
                    onClick={() => handleClose(elementEventPayloads.popupCancelButton)}
                  >
                    Cancel
                  </button>
                  <button className={styles.primaryBtn} type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </>
          )}

          {isFormSubmitted && (
            <>
              <h2>Thanks for your feedback.</h2>
              <p>We&apos;ll review your response. We&apos;re always looking for ways we can improve your experience.</p>
              <div className={styles.buttonGroup}>
                <button
                  className={styles.primaryBtn}
                  type="button"
                  onClick={() => handleClose(elementEventPayloads.popupCloseButton)}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </SlideUpPopup>
    </>
  );
};

export default UserExperienceSurvey;
