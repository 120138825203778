import { validateEmail, validatePhone } from '@core/services/leadDelivery';

// Checks whether a given string is empty or consists entirely of whitespace characters
const isEmptyString = (str?: String) => str === null || str?.match(/^ *$/) !== null;

/*  Validate inputs for PII form. 
Verifies that first name, last name, email, and phone are not empty strings.
 Also verifies that first name and last name do not contain numbers, and that email and phone are valid. */
export const validateInputs = async (firstName?: string, lastName?: string, email?: string, phone?: string) => {
  const formErrorArray = [];
  const inputArray = [
    { field: 'first name', value: firstName },
    { field: 'last name', value: lastName },
    { field: 'email', value: email },
    { field: 'phone', value: phone },
  ];

  for (const input of inputArray) {
    if (isEmptyString(input.value)) {
      formErrorArray.push({ field: input.field });
    }
  }

  if (firstName && /\d/.test(firstName)) {
    formErrorArray.push({ field: 'first name' });
  }

  if (lastName && /\d/.test(lastName)) {
    formErrorArray.push({ field: 'last name' });
  }

  if (email && !validateEmail(email)) {
    formErrorArray.push({ field: 'email' });
  }

  if (phone) {
    const { validPhoneNumber } = await validatePhone(phone);

    if (!validPhoneNumber) {
      formErrorArray.push({ field: 'phone' });
    }
  }

  return formErrorArray;
};
