import { type FC } from 'react';

import FormSubmissionButton from '../CtaButtons/FormSubmissionButton';
import OutlineButton from '../CtaButtons/OutlineButton';
import styles from './FormCtaButtonGroup.module.css';

type Props = {
  onSubmitClick?: () => void;
  onEditClick?: () => void;
  onEditConfirmClick?: () => void;
  isEditing?: boolean;
  canEdit?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const FormCtaButtonGroup: FC<Props> = ({
  onSubmitClick,
  onEditClick,
  onEditConfirmClick,
  isEditing,
  canEdit = true,
  isLoading,
  isDisabled,
}) => (
  <div className={styles.ctaButtons}>
    {isEditing ? (
      <OutlineButton className={styles.editConfirmButton} onClick={onEditConfirmClick}>
        Confirm Details
      </OutlineButton>
    ) : (
      <>
        <FormSubmissionButton
          disabled={isDisabled || isLoading}
          className={canEdit ? styles.submitButton : styles.stretchButton}
          isLoading={isLoading}
          onClick={onSubmitClick}
          data-tf-element-role="submit"
        />

        {canEdit && (
          <OutlineButton className={styles.editButton} onClick={onEditClick}>
            Edit
          </OutlineButton>
        )}
      </>
    )}
  </div>
);

export default FormCtaButtonGroup;
