import Form from '@rjsf/core';
import { ErrorTransformer, type IdSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { type ReactNode, useMemo, type FC, FormEvent, useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import { selectAllInputs, setInputs } from '@core/reducers/inputsSlice';
import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import fields from '@core/shared/features/PostResultForm/Fields';
import widgets from '@core/shared/features/PostResultForm/Widgets';
import getTransformedUiSchemaLocal from '@core/shared/features/PostResultForm/getTransformedUiSchemaLocal';
import type { VoyagerResult } from '@core/ts/results';

import styles from './InflowPostResultForm.module.css';

type Props = {
  className: string;
  selectedResult: VoyagerResult;
  prqSchema: IdSchema;
  onFormSubmit: () => void;
  eventingOverrides?: Record<string, unknown>;
  children: ReactNode;
  result: VoyagerResult;
};

const PostResultQuestionForm: FC<Props> = ({
  className,
  selectedResult,
  prqSchema,
  onFormSubmit,
  eventingOverrides,
  children,
  result,
}) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.3 });
  const formRef = useRef<Form<any, IdSchema, any>>(null);
  const formCorrelationId = useMemo(() => uuid(), []);
  const { formViewed, formStarted, formSubmitted } = useFormEvents({
    correlationId: formCorrelationId,
    formContext: {
      formName: 'voyager',
      formVersion: 'dynamic-post-flow',
      formType: 'post-flow',
      formBrand: result.school?.slug,
      formId: '1015',
    },
    stepContext: {},
  });

  useEffect(() => {
    if (inView) {
      formViewed();
      formStarted();
    }
  }, [inView]);

  const [triggerLeadSubmission, setTriggerLeadSubmission] = useState(false);
  const dispatch = useDispatch();
  const inputs = useSelector(selectAllInputs);

  useEffect(() => {
    if (triggerLeadSubmission) onFormSubmit();
  }, [triggerLeadSubmission]);

  const uiSchema = useMemo(
    () => getTransformedUiSchemaLocal({ programId: selectedResult.program.id, prqSchema }),
    [selectedResult?.program?.id, prqSchema]
  );

  const memoizedWidgets = useMemo(
    () => widgets(selectedResult?.school?.slug, formCorrelationId, eventingOverrides),
    [selectedResult?.school?.slug, formCorrelationId]
  );
  const memoizedFields = useMemo(
    () => fields(selectedResult?.school?.slug, formCorrelationId, eventingOverrides),
    [selectedResult?.school?.slug, formCorrelationId]
  );

  const transformErrors: ErrorTransformer = (errors) =>
    errors?.map((error) => {
      if (error?.params?.missingProperty) {
        error.message = `The ${error.params?.missingProperty
          .replace(/([A-Z])/g, ' $1')
          .trim()
          .toLowerCase()} field is required.`;
      } else {
        const providerProgramId = selectedResult?.program?.id;
        newRelicNoticeError(nrErrorMap.POST_RESULT_FORM, error, { providerProgramId });
        error.message = 'Something went wrong, please try again later!';
      }
      return error;
    });

  const handleSubmit = async ({ formData }: { formData: any }, e: FormEvent) => {
    e.preventDefault();
    if (formData === 'errors') return;

    // We need to get all of the properties in formData and convert them to a valid input for the setInputs dispatch
    // This includes flattening formData.location

    const currAcaAwardKey = uiSchema.currentAcademicAward?.['ui:options']?.currAcaAwardKey;

    const inputChanges = Object.entries(formData ?? {}).reduce((accumulator: any, [key, value]) => {
      if (key === 'location') {
        const locationData = Object.entries(formData.location ?? {}).map(([locationKey, locationValue]) => ({
          key: locationKey,
          value: locationValue,
        }));
        return [...accumulator, ...locationData];
      }
      if (key === 'currentAcademicAward') {
        // if IN_PROGRESS is selected, we populate inProgressAcademicAward
        if (value === 'IN_PROGRESS') {
          return [
            ...accumulator,
            {
              key: 'inProgressAcademicAward',
              value: {
                ...(inputs?.inProgressAcademicAward?.value ?? {}),
                [currAcaAwardKey as string]: value,
              },
            },
            {
              key: 'currentAcademicAward',
              value: {
                ...(inputs?.inProgressAcademicAward?.value ?? {}),
                [currAcaAwardKey as string]: null,
              },
            },
          ];
        }

        // else we populate the currentAcademicAward property
        return [
          ...accumulator,
          {
            key: 'currentAcademicAward',
            value: {
              ...(inputs?.currentAcademicAward?.value ?? {}),
              [currAcaAwardKey as string]: value,
            },
          },
          {
            key: 'inProgressAcademicAward',
            value: {
              ...(inputs?.inProgressAcademicAward?.value ?? {}),
              [currAcaAwardKey as string]: null,
            },
          },
        ];
      }

      return [
        ...accumulator,
        {
          key,
          value,
        },
      ];
    }, []);

    dispatch(setInputs(inputChanges));
    setTriggerLeadSubmission(true);
    formSubmitted();
  };

  useEffect(() => {
    // it's necessary for the active prospect script to be able to populate thirdPartyLeadId
    if (formRef?.current) {
      formRef?.current?.formElement?.current.setAttribute('data-tf-element-role', 'offer');
    }
  }, [formRef]);

  return (
    <div className={styles.prqForm} ref={ref}>
      <Form
        ref={formRef}
        schema={prqSchema}
        widgets={memoizedWidgets}
        fields={memoizedFields}
        validator={validator}
        uiSchema={uiSchema}
        className={className ?? styles.form}
        onSubmit={handleSubmit}
        showErrorList={false}
        transformErrors={transformErrors}
      >
        <div className={styles.submitBtnContainer}>
          {!children ? (
            <button type="submit" className={styles.submitBtn}>
              Submit
            </button>
          ) : (
            <>{children}</>
          )}
        </div>
      </Form>
    </div>
  );
};

export default PostResultQuestionForm;
