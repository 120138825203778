import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import styles from './ExitStrategyContent.module.css';

type Props = {
  result: VoyagerResult;
};

const ExitStrategyContent: FC<Props> = ({ result }) => (
  <div className={styles.exitStrategyContentContainer}>
    <div>
      <h2>What to expect from your application:</h2>
      <ul>
        <li>Most applications don&apos;t take long to complete.</li>
        <li>
          Be prepared to highlight your strengths, achievements, and individuality to show the school who you are.
        </li>
        <li>Depending on the school and degree level, you may be asked to provide your GPA and test scores.</li>
        <li>After submitting your application, track its status online. The school should have a decision soon.</li>
      </ul>
    </div>
    <div>
      <h2 className={styles.secondTitle}>Preparing for a chat with your advisor:</h2>
      <ul>
        <li>
          Generally, you can expect to be called immediately or within 2 business days, depending on the availability of
          the school&apos;s counselors. If you miss your call, {result?.school?.name} will try again.
        </li>
        <li>Calls average 20-30 minutes, and you&apos;ll typically receive a follow-up email afterwords.</li>
        <li>Be prepared to talk about your work and school history.</li>
        <li>Write down a few questions before the call</li>
      </ul>
    </div>
    <div>
      <h2 className={styles.secondTitle}>Here&apos;s what other students are asking:</h2>
      <ul>
        <li>What type of courses will I be taking for my degree?</li>
        <li>What scholarships and financial aid are available?</li>
        <li>How can I estimate my tuition cost, including books and fees?</li>
      </ul>
    </div>
  </div>
);

export default ExitStrategyContent;
