import { useEffect, type FC } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';

import {
  type EventingOverrides,
  productLoaded,
} from '@core/blocks/best-match-experience/services/events/productEvents';
import { type UserPreferencesAndMilitary } from '@core/blocks/best-match-experience/types';
import { type VoyagerResult } from '@core/ts/results';

import AdvertisingSupportedCopy from '../../AdvertisingSupportedCopy';
import Pill from '../../Pill';
import ProgramCard from '../../ProgramCard';
import SponsoredResultsPopover from '../../SponsoredResultsPopover';
import Button from '../../ui/Button';
import styles from './SpotlightProgramSelect.module.css';

type Props = {
  spotlightMatch: VoyagerResult;
  onFlowExit: () => void;
  firstName?: string;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onView: (result: VoyagerResult) => void;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  degree: string;
  subject: string;
  eventingOverrides: EventingOverrides;
};

const SpotlightProgramSelect: FC<Props> = ({
  spotlightMatch,
  onProgramCtaClick,
  onFlowExit,
  firstName,
  onView,
  userPreferencesAndMilitary,
  degree,
  subject,
  eventingOverrides,
}) => {
  useEffect(() => {
    productLoaded(spotlightMatch, userPreferencesAndMilitary, eventingOverrides);
  }, []);

  return (
    <>
      <div className={styles.programSelectHeaderSection}>
        <div className={styles.programSelectPersonalizedTitle}>You’ve got matches, {firstName}!</div>
        <div className={styles.programSelectIntroductionTitle}>
          These are your <br /> top recommendations based on:
        </div>
        <div className={styles.programSelectDataPills}>
          {degree && <Pill>{degree}</Pill>}
          {subject && <Pill>{subject}</Pill>}
        </div>
        <div className={styles.programSelectAddVerbiageMobile}>
          <SponsoredResultsPopover position="above" spanText="Learn More About Our Partners" />
        </div>
      </div>
      <div className={styles.programSelectResultsSection}>
        <div className={styles.programSelectResultsAndInfo}>
          <ProgramCard
            type="spotlight"
            result={spotlightMatch}
            onView={onView}
            userPreferencesAndMilitary={userPreferencesAndMilitary}
            onClick={() => onProgramCtaClick(spotlightMatch)}
          />

          <button onClick={() => onProgramCtaClick(spotlightMatch)} className={styles.mobileCta} type="button">
            <div className={styles.ctaLabel}>Explore Program</div>+
          </button>

          <div className={styles.programSelectSchoolInfo}>
            <p>{spotlightMatch?.school?.config?.spotlight?.header}</p>

            <div className={styles.programSelectSchoolHighlights}>
              <div className={styles.programSelectSchoolHighlightsTitle}>School Highlights</div>
              <ul className={styles.programSelectSchoolHighlightsList}>
                {spotlightMatch?.school?.config?.spotlight?.blocks?.[0]?.list?.map(({ text }: any) => (
                  <li className={styles.programSelectSchoolHighlightsListItem}>
                    <IoCheckmarkCircle size={18} />
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.programSelectCtaSection}>
          <div className={styles.programSelectExploreSkip}>
            <button onClick={() => onProgramCtaClick(spotlightMatch)} className={styles.cta} type="button">
              <div className={styles.ctaLabel}>Explore Program</div>+
            </button>
            <Button onClick={onFlowExit} className={styles.programSelectSkipToAllResults} type="button">
              Skip to all results
            </Button>
          </div>
          <div className={styles.programSelectAddVerbiageDesktop}>
            <SponsoredResultsPopover position="above" spanText="Learn More About Our Partners" />
          </div>
        </div>
        <div className={styles.AdvertisingSupportedCopyMobile}>
          <AdvertisingSupportedCopy />
        </div>
      </div>
    </>
  );
};

export default SpotlightProgramSelect;
