import { type ButtonHTMLAttributes, type FC } from 'react';

import cn from '@core/utils/classNames';

import Button from '../../ui/Button';
import styles from './OutlineButton.module.css';

const OutlineButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, className = '', ...props }) => (
  <Button className={cn(styles.outlineButton, className)} {...props}>
    {children}
  </Button>
);

export default OutlineButton;
