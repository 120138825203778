import { type Dispatch, type SetStateAction, type FC, useState, ReactNode } from 'react';

import { CompactCard } from '@core/blocks/best-match-experience/components/CompactCard';
import type { VoyagerResult } from '@core/ts/results';

import FormSubmissionButton from '../../../CtaButtons/FormSubmissionButton';
import FormCtaDisclaimer from '../../../FormCtaDisclaimer';
import PiiFormSection from '../../../PiiFormSection';
import styles from './PiiConfirmation.module.css';

type Props = {
  onFormSubmit: () => void;
  pii: Record<string, string>;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  onEdit: () => void;
  onEditConfirm: () => void;
  isHeclidLoading: boolean;
  isSubmittingLead: boolean;
  result: VoyagerResult;
  headerComponent?: ReactNode;
  isEditing?: boolean;
};

const GradPiiConfirmation: FC<Props> = ({
  onFormSubmit,
  pii,
  piiErrors,
  setPii,
  onEdit,
  onEditConfirm,
  isHeclidLoading,
  isSubmittingLead,
  result,
  headerComponent,
  isEditing,
}) => (
  <form onSubmit={(e) => e.preventDefault()} data-tf-element-role="offer" className={styles.piiSection}>
    <div className={styles.piiFormHeaderSection}>
      {headerComponent}
      <CompactCard result={result} showClose={false} />
      <span className={styles.prePii}>Before submitting, please confirm your info:</span>
      <div className={styles.piiFormSection}>
        <div className={styles.piiFormSectionHeader}>
          <span>Your information</span>
          <button
            type="button"
            className={styles.piiFormEditButton}
            onClick={() => {
              if (isEditing) {
                onEditConfirm();
              } else {
                onEdit();
              }
            }}
          >
            {isEditing ? 'Done' : 'Edit'}
          </button>
        </div>
        <PiiFormSection piiErrors={piiErrors} setPii={setPii} pii={pii} isEditing={isEditing} />
      </div>
    </div>

    <div className={styles.piiFormBottomSection}>
      <div className={styles.formCtaDisclaimer}>
        <FormCtaDisclaimer school={result?.school} />
      </div>

      <div className={styles.formCtaSection}>
        <span>Double check your information before submitting </span>
        <FormSubmissionButton
          isLoading={isHeclidLoading}
          isDisabled={isSubmittingLead}
          onClick={onFormSubmit}
          className={styles.submitButton}
          data-tf-element-role="submit"
        />
      </div>
    </div>
  </form>
);

export default GradPiiConfirmation;
