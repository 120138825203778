import getRelatedFilters from '@core/blocks/edu-match/utils/getRelatedFilters';
import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import {
  ALGOLIA_BASE_INDEX_NAME,
  ALGOLIA_RECOMMENDED_INDEX_NAME,
  ALGOLIA_RELATED_INDEX_NAME,
  getSearchClient,
} from '@core/services/AlgoliaService/v2';
import { VoyagerResult } from '@core/ts/results';
import getFilters from '@core/utils/spotlight/getFilters';

type Params = {
  dcs: {
    dcsDegrees: string[];
    dcsCategories: string[];
    dcsSubjects: string[];
  };
  inputs: VoyagerInputs;
  configFilters: string[];
};

type Results = {
  recommendedHits: VoyagerResult[];
  exactHits: VoyagerResult[];
  relatedHits: VoyagerResult[];
};

type FetchAlgoliaHits = (params: Params) => Promise<Results>;

const fetchAlgoliaHits: FetchAlgoliaHits = async ({ dcs, inputs, configFilters }) => {
  const searchClient = await getSearchClient(inputs);

  const recommendedIndex = searchClient.initIndex(ALGOLIA_RECOMMENDED_INDEX_NAME);
  const exactIndex = searchClient.initIndex(ALGOLIA_BASE_INDEX_NAME);
  const relatedIndex = searchClient.initIndex(ALGOLIA_RELATED_INDEX_NAME);

  const [recommendedResults, exactResults, relatedResults] = await Promise.all([
    recommendedIndex.search<VoyagerResult>('', {
      filters: getFilters({ dcs, configFilters, additionalFilters: "program.providerName:'TwoU'" }),
      hitsPerPage: 1,
    }),

    exactIndex.search<VoyagerResult>('', {
      filters: getFilters({ dcs, configFilters }),
      hitsPerPage: 51,
    }),

    relatedIndex.search<VoyagerResult>('', {
      filters: getRelatedFilters({ degrees: dcs.dcsDegrees, subjects: dcs.dcsSubjects, configFilters }),
      hitsPerPage: 12,
    }),
  ]);

  return {
    recommendedHits: recommendedResults.hits,
    exactHits: exactResults.hits,
    relatedHits: relatedResults.hits,
  };
};

export default fetchAlgoliaHits;
