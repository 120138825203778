import { type FC } from 'react';

import styles from './NotQualifiedDrawer.module.css';

type Props = {
  onCtaClick: () => void;
};

const NotQualifiedDrawer: FC<Props> = ({ onCtaClick }) => (
  <div className={styles.notQualifiedDrawer}>
    <section className={styles.content}>
      <h3>Not Qualified</h3>
      <p>It looks like you may not qualify for this program based on your answers. </p>
      <p>
        That&apos;s ok! Review other schools within your match list to find a school that&apos;s better suited for you.
      </p>
    </section>

    <button type="button" className={styles.backButton} onClick={onCtaClick}>
      Back to All Results
    </button>
  </div>
);

export default NotQualifiedDrawer;
