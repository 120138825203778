import { HiX } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import Overlay from '@core/blocks/edu-match/components/Overlay';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';
import cn from '@core/utils/classNames';

import styles from './Popup.module.css';
import type { TPopup } from './types';

const Popup: TPopup = ({ show, onClose, children, closeOnOverlayClick = false }) => {
  const overlayClickHandler = closeOnOverlayClick ? onClose : undefined;
  const dcsDegrees = useSelector(selectDcsDegrees);
  const flags = useFeatureFlags();

  // Want the popup size to be smaller for undergrad DCSs
  const isUndergrad = dcsDegrees.includes("Associate's") || dcsDegrees.includes("Bachelor's");

  const isGradResultsTest = flags?.voyagerGradResultsPage === 'test';

  const isBestMatchExperience = flags?.bestMatchExperience === 'test';

  if (!show) return null;

  return (
    <div className={styles.popupContainer}>
      <Overlay clickHandler={overlayClickHandler} />
      <div
        className={cn(styles.popup, (isUndergrad || isGradResultsTest) && !isBestMatchExperience && styles.revampPopup)}
      >
        {onClose && (
          <button data-testid="popup-close-btn" type="button" className={styles.closeBtn} onClick={onClose}>
            <HiX size="1.5rem" />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Popup;
