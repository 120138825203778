import { GetGeoAndInputCandidates } from './types';

const getGeoAndInputCandidates: GetGeoAndInputCandidates = ({ geoAndInputRules, currentState, inputs }) => {
  if (!geoAndInputRules?.length || !currentState) {
    return [];
  }

  // 2 conditions:
  //  the state matches the current state
  //  the user input for `inputKey` matches the rule `inputValue`
  const match = geoAndInputRules.find(
    ({ state, inputKey, inputValue }) =>
      state.toUpperCase() === currentState.toUpperCase() && inputs[inputKey]?.value === inputValue
  );

  // if one matches, we return the candidates
  // if not, we return an empty array
  return (match?.schoolIds ?? []).map((schoolId) => ({ schoolId, rule: 'multi' }));
};

export default getGeoAndInputCandidates;
