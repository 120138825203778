/* eslint-disable react/no-danger */

/**
 * A "Page" is each screen in the flow. It has a heading, options and action buttons.
 */
import Cookies from 'js-cookie';
import { type FC, type RefObject, useEffect, useRef, useState } from 'react';
// Icons
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import getBestMatchExperienceData from '@core/blocks/best-match-experience/services/getBestMatchExperienceData';
import getQuestionById from '@core/blocks/edu-flow/utils/getQuestionById';
import useSetBaseIndex from '@core/blocks/edu-match/hooks/useSetBaseIndex';
import { DEBUG_PARAMS } from '@core/constants';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import useIdentifyEvents from '@core/hooks/cohesion/useIdentifyEvents';
import useAlgoliaClient from '@core/hooks/useAlgoliaClient';
import useFilterConfig from '@core/hooks/useFilterConfig';
import useQueryParams from '@core/hooks/useQueryParams';
import { selectDcs } from '@core/reducers/dcsSlice';
import {
  selectFormCorrelationId,
  selectFormContext,
  selectHasFiredFormSubmitted,
  setHasFiredFormSubmitted,
  selectStepContext,
} from '@core/reducers/eventingSlice';
import { type VoyagerInputs, selectAllInputs } from '@core/reducers/inputsSlice';
import {
  selectBaseIndex,
  setExactMatches,
  setPrefetchedLocations,
  setRecommenderMatches,
  setRelatedMatches,
  setSpotlightMatch,
} from '@core/reducers/matchesSlice';
import { selectQueryParam } from '@core/reducers/queryParamsSlice';
import { type RootState } from '@core/store';

import { getCookies } from '../../utils/getCookies';
import DefaultPage, { DefaultPageProps } from './DefaultPage';
import type { PageProps, PageType, ConditionalRoute, QuestionType } from './types';
import getConditionBehavior from './utils/getConditionBehavior';
import getNextStep from './utils/getNextStep';
import getValidationErrors from './utils/getValidationErrors';
import inputFormatter from './utils/inputFormatter';
import sendToCappex from './utils/sendToCappex';

const getConditionalRoute = (question: QuestionType, inputs: VoyagerInputs): ConditionalRoute | undefined =>
  question.conditionalRoutes?.find((conditionalRoute) =>
    inputs[conditionalRoute?.name as string]?.value?.includes(conditionalRoute.value)
  );

const Page: FC<PageProps> = ({ flow, page, index, PageComponent = DefaultPage, hasUpdatedStepContext }) => {
  const queryParams = useQueryParams();
  const [validating, setValidating] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<Error[]>([]);
  const [showAnimation, setShowAnimation] = useState<DefaultPageProps['showAnimation']>('FORWARD');
  const [displayCookiesPopup, setDisplayCookiesPopup] = useState(false);
  const buttonRef = useRef(null);

  // Eventing State properties
  const formContext = useSelector(selectFormContext);
  const stepContext = useSelector(selectStepContext);
  const hasFiredFormSubmitted = useSelector(selectHasFiredFormSubmitted);
  const formCorrelationId = useSelector(selectFormCorrelationId);

  // Inputs State properties
  const inputs = useSelector(selectAllInputs);

  // DCS State properties
  const { dcsDegrees, dcsCategories, dcsSubjects } = useSelector(selectDcs);

  // Algolia Search Client
  const baseIndex = useSelector(selectBaseIndex);
  const { searchClient } = useAlgoliaClient({ applyQualifications: true });
  useSetBaseIndex();

  // DCS state properties
  const dcs = useSelector(selectDcs);

  // Cookies
  const localInput = getCookies();

  // results debug
  const resultsDebug = useSelector((state) => selectQueryParam(state, DEBUG_PARAMS.RESULTS));

  // Action Dispatcher
  const dispatch = useDispatch();

  const flags = useFeatureFlags();

  // Question for Page
  const flowQuestion = getQuestionById(page.questionId, flags);

  // get winning question between preamp and flow
  const question = flowQuestion;

  const store = useSelector((state: RootState) => state);

  // Conditional action [CONTINUE,SHOW]
  const conditionalAction = getConditionBehavior(store, question?.conditionalContinue);

  // Grabs flow events
  const { formContinued, formSubmitted } = useFormEvents({
    formContext,
    stepContext,
    field: [
      {
        fieldType: 'inferred',
        fieldName: 'degree',
        fieldValue: dcsDegrees.toString(),
        fieldSource: 'voyager-entry-point',
      },
      {
        fieldType: 'inferred',
        fieldName: 'degree_encoded',
        fieldValue: encodeURIComponent(dcsDegrees.toString()),
        fieldSource: 'voyager-entry-point',
      },
      {
        fieldType: 'inferred',
        fieldName: 'category',
        fieldValue: dcsCategories.toString(),
        fieldSource: 'voyager-entry-point',
      },
      {
        fieldType: 'inferred',
        fieldName: 'category_encoded',
        fieldValue: encodeURIComponent(dcsCategories.toString()),
        fieldSource: 'voyager-entry-point',
      },
      {
        fieldType: 'inferred',
        fieldName: 'subject',
        fieldValue: dcsSubjects.toString(),
        fieldSource: 'voyager-entry-point',
      },
      {
        fieldType: 'inferred',
        fieldName: 'subject_encoded',
        fieldValue: encodeURIComponent(dcsSubjects.toString()),
        fieldSource: 'voyager-entry-point',
      },
    ],
    correlationId: formCorrelationId,
  });

  // Set up the identify event for tracking the user's information for our email retargeting campaign
  const { identify } = useIdentifyEvents({
    traits: {
      email: inputs?.email?.value,
      firstName: inputs?.firstName?.value,
      // storing the dcs query parameters in the website property to add to the CTA in the email so they have their dcs when they return
      website: `dcs[degrees][]=${dcs.dcsDegrees[0]}&dcs[categories][]=${dcs.dcsCategories[0]}&dcs[subjects][]=${dcs.dcsSubjects[0]}`,
    },
  });

  const { configFilters } = useFilterConfig();

  // If we're in the bootcamps flow we want to prefetch
  // the locations used on the "In-Person" drowpdown (learning format step)
  useEffect(() => {
    if (dcsDegrees.includes('Bootcamps')) {
      const getData = async () => {
        try {
          const index = searchClient.initIndex(baseIndex);
          const results = await index.searchForFacetValues('program.miscellaneous.location', '');
          dispatch(setPrefetchedLocations(results.facetHits));
        } catch (error) {
          dispatch(setPrefetchedLocations([]));
        }
      };

      if (searchClient) {
        getData();
      }
    }
  }, [searchClient]);

  useEffect(() => {
    window.scrollTo(0, 0); // this helps with mobile ux so they aren't stuck at the bottom of the page

    window.newrelic?.addPageAction?.('VoyagerPageLoaded', {
      pageSlug: page.slug,
      questionId: page.questionId,
      stepNumber: page.stepNumber,
      ...window._Cohesion?.webContext,
    });
  }, [page]);

  // Conditional Continue - Automatically advance the page
  // Advance the page forward when a certain condition is met based on user selection (if the action is set to continue)
  useEffect(() => {
    //  Handle continue overrides our results debug redirect to the results page
    if (resultsDebug) return;

    if (conditionalAction === 'CONTINUE') {
      handleContinue();
    }
  }, [inputs]);

  useEffect(() => {
    if (queryParams.returning && Object.entries(localInput).length === 0) {
      setDisplayCookiesPopup(true);
    }
  }, []);

  // Set up navigation for handling previous/next actions
  const navigate = useNavigate();

  // Get the previous page object
  const prevPage: PageType = flow[index - 1];

  // Get the next page object unless it is explicitly overwritten
  // It usually will get overwritten to go from the last screen to a results screen
  const nextPage: PageType = flow[index + 1];

  // Conditional Routing
  // If this Page has conditional routes defined we will iterate over them.
  // If a condition is met we will use the qualifying `path`
  // Otherwise we will use nextPage.slug
  const conditionalRoute: ConditionalRoute | undefined = getConditionalRoute(question, inputs);
  const nextPageRoute = conditionalRoute?.path || page.overrideNextStep || nextPage.slug;

  // What happens when someone clicks the "previous" button
  const handlePrevious = () => {
    navigate(-1);

    setShowAnimation('BACK');
  };
  // Validate the page and either show errors or navigate to next page
  const handleContinue = async () => {
    setValidating(true);

    // Catches all of the field validation errors in the current page
    const errors = await getValidationErrors(inputs, question.fields);

    setValidating(false);

    // If there are NO validation errors, we fire formSubmitted (if last step) or formContinued if not
    // and navigate to the next page
    if (!errors.length) {
      if (page?.overrideNextStep && !hasFiredFormSubmitted) {
        formSubmitted();
        if (inputs?.emailConsent?.value) {
          identify();
        }
        // Store the user's selections in cookies so when they click the email cta
        // their stuff is auto filled (as long as they are coming from the same device)
        Cookies.set('voyagerInputs', JSON.stringify(inputFormatter(inputs)), { expires: 3 });

        dispatch(setHasFiredFormSubmitted());
      }

      formContinued();

      // Handles Logic that Returns the Next Step after the Current Page
      const { i, nextQuestion } = getNextStep({
        store,
        flow,
        index,
      });

      if (i > 1 && nextQuestion) {
        // Verify properties in the skip question and find correct navigation url
        const skipConditionalRoute = getConditionalRoute(nextQuestion, inputs);
        const skipPageRoute = skipConditionalRoute?.path || flow[index + i]?.slug || nextPage.overrideNextStep;
        // Navigate to the next question after the skip if any
        navigate(skipPageRoute as string);
      } else navigate(page.overrideNextStep || nextPageRoute);

      setShowAnimation('FORWARD');

      /* Cappex Landing Page Logic */
      if (
        sendToCappex({ educationLevel: inputs?.educationLevel?.value, graduationYear: inputs?.graduationYear?.value })
      ) {
        navigate('/cappex');
      }
    }
    setFieldErrors(errors);

    if (flags.bestMatchExperience === 'test' && page.slug.includes('zip')) {
      getBestMatchExperienceData({ inputs, dcs, configFilters }).then((data) => {
        dispatch(setExactMatches(data.exactMatches));
        dispatch(setRecommenderMatches(data.recommenderMatches));
        dispatch(setRelatedMatches(data.relatedMatches));
        dispatch(setSpotlightMatch(data.spotlightMatch));
      });
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        (buttonRef as RefObject<HTMLButtonElement>)?.current?.click();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  // This determines whether to show the continue button or not
  // Is there a next page? We _don't_ want to show the continue button if conditional continue is set up for this page
  // However, we DO want to show the continue button if they hit the back button and the value is already set
  // Or, if the operator is "EQUALS" since there are other options they can select

  const showContinueButton = Boolean((nextPage && !question.conditionalContinue) || conditionalAction);

  if (!hasUpdatedStepContext) return null;

  const props: DefaultPageProps = {
    showAnimation,
    question,
    fieldErrors,
    flow,
    showContinueButton,
    validating,
    onAnimationEnd: () => setShowAnimation('NONE'),
    onContinue: handleContinue,
    onPreviousPage: handlePrevious,
    buttonRef,
    page,
    prevPage,
    displayCookiesPopup,
    cookiesPopupStateSetter: setDisplayCookiesPopup,
  };
  return <PageComponent {...props} />;
};

export default Page;
