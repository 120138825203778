import { type RefObject, type FC } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';

import cn from '@core/utils/classNames';

import styles from './PsychCardDescription.module.css';

type Props = {
  itemsList: string[];
  showDescription: boolean;
  descriptionRef: RefObject<HTMLDivElement>;
  header?: string;
};

/** JIRA TICKET: https://redventures.atlassian.net/browse/EDUN-1171?atlOrigin=eyJpIjoiNDNiYjA4Y2Q1NWQ4NGE2OTliMGRmMzgxNTIzZDkyM2UiLCJwIjoiaiJ9 */

const PsychCardDescription: FC<Props> = ({ header, itemsList, showDescription, descriptionRef }) => (
  <>
    {/* description */}

    <div className={styles.cardDescriptionContainer}>
      <h4>{header}</h4>
      <div ref={descriptionRef} className={cn(styles.cardDescription, showDescription && styles.expand)}>
        <ul className={!showDescription ? styles.stretch : null}>
          {itemsList.map((bullet) => (
            <li>
              <BsCheckCircleFill size={16} />
              <p dangerouslySetInnerHTML={{ __html: bullet }} />
            </li>
          ))}
        </ul>

        {/* gradient overlay */}
        <div className={showDescription ? styles.hide : styles.gradient} />
      </div>
    </div>
  </>
);

export default PsychCardDescription;
