import { useState, type FC, type ReactNode } from 'react';
import { HiXMark } from 'react-icons/hi2';

import cn from '@core/utils/classNames';

import styles from './CustomTooltip.module.css';

type Props = {
  text: string | ReactNode;
  subtext?: string | ReactNode;
  position?: string;
  title?: string;
  tooltipTitle: string | ReactNode;
  textColor?: string;
  className?: string;
};

const CustomTooltip: FC<Props> = ({
  title,
  tooltipTitle,
  text,
  subtext,
  position = 'below',
  textColor,
  className = '',
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const toggleTooltip = () => setIsTooltipOpen(!isTooltipOpen);
  const handletoggleTooltip = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsTooltipOpen(!isTooltipOpen);
    }
  };

  return (
    <div
      className={styles.tooltipWrapper}
      role="button"
      onClick={toggleTooltip}
      tabIndex={0}
      onKeyDown={handletoggleTooltip as () => void}
    >
      <p className={cn(styles.tooltipButtonText, textColor === 'white' ? styles.white : styles.gray)}>{tooltipTitle}</p>
      {isTooltipOpen && (
        <div className={cn(styles.tooltip, position === 'below' ? styles.below : styles.above, className)}>
          <div className={styles.tooltipTitle}>{title}</div>
          <div className={styles.tooltipContent}>
            <span>{text}</span>
            <span>{subtext}</span>
            <button type="button" className={styles.tooltipButton} onClick={toggleTooltip}>
              <HiXMark size={10} strokeWidth={1} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
