import { type FC } from 'react';

import { replaceCTALabelInTCPA } from '../../utils/tcpa';
import styles from './TCPA.module.css';

type Props = {
  tcpa: string;
};

const TCPA: FC<Props> = ({ tcpa }) => (
  <div className={styles.piiConfirmationTermsAndConditions}>
    <div className={styles.piiConfirmationTermsAndConditionsTitle}> Terms and Conditions </div>

    <p dangerouslySetInnerHTML={{ __html: replaceCTALabelInTCPA(tcpa) ?? '' }} />
  </div>
);

export default TCPA;
