import { type FC } from 'react';
import { HiMapPin } from 'react-icons/hi2';

import type { VoyagerResult } from '@core/ts/results';

import styles from './SchoolInfoHeader.module.css';

type Props = {
  result?: VoyagerResult;
};

const SchoolInfoHeader: FC<Props> = ({ result }) => (
  <div className={styles.programInfoHeader}>
    <div className={styles.schoolInfoSection}>
      <div className={styles.schoolInfoSectionColumn}>
        <div className={styles.schoolLogo}>
          <img
            src={`https://simple-storage-server.highereducation.com/${result?.school?.slug}.png?w=130&h=80`}
            alt={result?.school?.name}
          />
        </div>
        <div className={styles.nameAndLocationSection}>
          <div className={styles.programName}>{result?.program?.name}</div>

          <div className={styles.schoolName}>{result?.school?.name}</div>
          <div className={styles.locationInfo}>
            <HiMapPin /> {result?.school?.city}, {result?.school?.state}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SchoolInfoHeader;
