// Components
import { MobileDrawer } from '@edu-match/components';
import { useEffect, useState } from 'react';
import { type SearchResults, SearchState } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { selectDcs } from '@core/reducers/dcsSlice';
// Reducers selectors/actions
import { setListId } from '@core/reducers/eventingSlice';
import { selectRelatedMatches, selectUniqueMatches } from '@core/reducers/matchesSlice';
import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
import { Hit } from '@core/ts/algolia';
import { type Qualifications } from '@core/ts/results';

import MatchContextPills from '../MatchContextPills';
import { NoResults } from '../NoResults';
import DefaultExactMatches from './DefaultExactMatches';
import ExactMatches from './ExactMatches';
import styles from './Results.module.css';
import RevampExactMatches from './RevampExactMatches';

type Props = {
  searchResults: SearchResults<Hit>;
  isSearchStalled: boolean;
  searchState: SearchState;
  hasEmbeddedRecommenderResults?: boolean;
  qualifications?: Qualifications;
};

const Results = connectStateResults(
  ({ searchResults, isSearchStalled, searchState, hasEmbeddedRecommenderResults, qualifications }: Props) => {
    const [needListIdChange, setNeedListIdChange] = useState(false);
    // unique matches from DCS consolidation
    const uniqueMatches = useSelector(selectUniqueMatches);
    const { dcsDegrees } = useSelector(selectDcs);
    const isBootcamps = dcsDegrees[0] === 'Bootcamps';
    // Action Dispatcher
    const dispatch = useDispatch();
    const flags = useFeatureFlags();
    const {
      dcsDegrees: [degree],
    } = useSelector(selectDcs);

    // Selectors
    const relatedMatches = useSelector(selectRelatedMatches);

    // listId is used for eventing. it should change every time that algolia gives us a new results set
    // When the searchState changes we set the `needListIdUpdate` to true
    // so that the next time that `searchResults` change, we change the listId
    useEffect(() => {
      setNeedListIdChange(true);
    }, [searchState]);

    // Updating the listId only if the `needListIdChange` flag is set to true
    useEffect(() => {
      if (needListIdChange) {
        dispatch(setListId(uuid()));
        setNeedListIdChange(false);
      }
    }, [searchResults]);

    const ExactMatchesComponent = degree === 'Bootcamps' ? RevampExactMatches : DefaultExactMatches;

    return (
      <>
        {isBootcamps && <MobileDrawer />}
        {/* if no exact, related or unique matches, and app is not in loading state */}
        {!hasEmbeddedRecommenderResults &&
        searchResults?.nbHits === 0 &&
        uniqueMatches?.length === 0 &&
        relatedMatches?.length === 0 &&
        !isSearchStalled ? (
          <>
            {/* no results
if no hits, and app is not in loading state */}
            <NoResults />
          </>
        ) : (
          <>
            {/* Results  */}

            <div className={styles.sponsoredResultsRow}>
              {flags.matchContextTest === 'test' && (
                <MatchContextPills hits={searchResults?.hits} qualifiers={qualifications?.qualified} />
              )}
              {searchResults?.nbHits > 0 && !isBootcamps && <SponsoredResultsPopover />}
            </div>
            <ExactMatches searchState={searchState} ExactMatchesComponent={ExactMatchesComponent} />
          </>
        )}
      </>
    );
  }
);

export { Results };
