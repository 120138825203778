import { type FC, type MouseEventHandler, type PropsWithChildren, useRef } from 'react';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';

import PsychCardDescription from './PsychCardDescription';
import PsychCardDescriptionCta from './PsychCardDescriptionCta';
import { highLightKeywords, paragraphToSentencesArray } from './utils';

type Props = {
  showDescription: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  viewCorrelationId: string;
  position?: number;
  snippet: string;
  isCoveredByElement: boolean;
} & PropsWithChildren;

type ReturnType = {
  PsychCardDescription: FC<PropsWithChildren>;
  PsychCardDescriptionCta: FC<PropsWithChildren>;
  isTestEnabled: boolean;
};

/**
 * Hook to handle the PsychCard test
 * @param {Props} props
 * @returns {ReturnType} return the PsychCardDescription and PsychCardDescriptionCta HOCs
 */
const usePsychCardTest = ({ snippet, showDescription, ...otherProps }: Props): ReturnType => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const flags = useFeatureFlags();

  const isTestEnabled = !!flags?.psychCardTest?.length;

  const snippetWithHighlightedKeywords = highLightKeywords(snippet, flags?.psychCardKeywords);

  const itemsList = paragraphToSentencesArray(snippetWithHighlightedKeywords);

  // check if the description overflows the container. The fixed height in the styles is 56px.
  const doesDescriptionOverflow = Number(descriptionRef.current?.scrollHeight) > 56;

  // return the test components only if the test is enabled
  return {
    isTestEnabled,
    PsychCardDescription: ({ children }: PropsWithChildren) =>
      isTestEnabled ? (
        <PsychCardDescription
          {...otherProps}
          itemsList={itemsList}
          // we need to pass the ref to the description component to check if the description overflows the container
          descriptionRef={descriptionRef}
          header={flags?.psychCardTest}
          showDescription={showDescription || !doesDescriptionOverflow}
        />
      ) : (
        <>{children}</>
      ),
    PsychCardDescriptionCta: ({ children }: PropsWithChildren) =>
      isTestEnabled ? (
        <PsychCardDescriptionCta
          doesDescriptionOverflow={doesDescriptionOverflow}
          {...otherProps}
          showDescription={showDescription}
        />
      ) : (
        <>{children}</>
      ),
  };
};

export default usePsychCardTest;
