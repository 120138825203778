import determineEnv, { Environments } from '@core/blocks/edu-match/utils/determineEnv';
import type { NewRelic, NoticeErrorTypes } from '@core/ts/newRelic';

export const nrErrorMap = {
  // lds
  LDS_SCHEMA: 'voyager_lds_schema' as NoticeErrorTypes,
  LDS_LEAD: 'voyager_lds_lead' as NoticeErrorTypes,
  LDS_LEAD_UNQUALIFIED: 'voyager_lds_lead_unqualified' as NoticeErrorTypes,
  LDS_VALIDATE_PHONE: 'voyager_lds_validate_phone' as NoticeErrorTypes,
  LDS_VALIDATE_ZIP: 'voyager_lds_validate_zip' as NoticeErrorTypes,
  LDS_QUALIFICATIONS: 'voyager_lds_qualifications' as NoticeErrorTypes,

  // mobius
  MOBIUS_REDIRECT: 'voyager_mobius_redirect' as NoticeErrorTypes,
  MOBIUS_TRACK: 'voyager_mobius_track' as NoticeErrorTypes,
  MOBIUS_BROWSER_LOCATION: 'voyager_mobius_browser_location' as NoticeErrorTypes,
  MOBIUS_ALGOLIA_CREDENTIALS: 'voyager_mobius_algolia_credentials' as NoticeErrorTypes,
  MOBIUS_DCS: 'voyager_mobius_dcs' as NoticeErrorTypes,
  MOBIUS_TOKEN: 'voyager_mobius_token' as NoticeErrorTypes,
  MOBIUS_GET_APPLICATION_LINK: 'voyager_mobius_get_application_link' as NoticeErrorTypes,

  // algolia
  ALGOLIA_CLIENT: 'voyager_algolia_client' as NoticeErrorTypes,

  // school config
  SCHOOL_CONFIG: 'voyager_school_config' as NoticeErrorTypes,

  SPOTLIGHT_ALGOLIA: 'voyager_spotlight_algolia' as NoticeErrorTypes,

  // Monarch
  MONARCH_CLIENT: 'voyager_monarch_client' as NoticeErrorTypes,

  // error boundary
  ERROR_BOUNDARY: 'voyager_error_boundary' as NoticeErrorTypes,

  // post result form
  POST_RESULT_FORM: 'post_result_form' as NoticeErrorTypes,

  CONTACT_DB: 'contact_db' as NoticeErrorTypes,

  VOYAGER_RESULTS_LOAD_ERROR: 'voyager_results_load_error' as NoticeErrorTypes,

  V4_QUALIFY: 'voyager_v4_qualify' as NoticeErrorTypes,

  EDITABLE_ASSET: 'voyager_editable_asset' as NoticeErrorTypes,
};

export enum NrPageActions {
  PREAMPINITIALIZED = 'PreampInitialized',
  FORMCONTINUED = 'FormContinued',
  FORMSTARTED = 'FormStarted',
  FORMSUBMITTED = 'FormSubmitted',
  FORMVIEWED = 'FormViewed',
  JORNAYATOKENNOTFOUND = 'JornayaTokenNotFound',
  BONSAI_ERPI_SORT_ENABLED = 'BonsaieRPISortEnabled',
  SPOTLIGHT_NO_RESULTS = 'SpotlightNoResults',
  CAPPEX_PAGE_TRIGGERED = 'CappexPageTriggered',
  RESULTS_LOADED = 'ResultsLoaded',
  LEAD_ATTEMPT = 'LeadAttempt',
  LEAD_SUCCESS = 'LeadSuccess',
  APPLICATION_CTA_VIEWED = 'ApplicationCtaViewed',
  APPLICATION_CTA_CLICKED = 'ApplicationCtaClicked',
  LDS_INVALID_PHONE_LINE_TYPE_INTELLIGENCE = 'LeadInvalidPhoneLineTypeIntelligence',
  CONTACT_SAVED = 'ContactSaved',
  SHOW_TOPMATCHES = 'ShowTopMatches',
}

const env = determineEnv();
let nrQueue = [];

window.addEventListener('DOMContentLoaded', () => {
  if (nrQueue.length) {
    nrQueue.forEach((fn) => fn());
    nrQueue = [];
  }
});

function callNewrelic(action: keyof NewRelic, ...args: Array<unknown>): void {
  if (env === Environments.DEVELOPMENT) {
    console.groupCollapsed(
      '%cNewRelicEvent:',
      'background: #16a085; color: #fff;  padding: 2px 4px; font-weight: 700; border-radius: 4px;',
      action
    );

    console.log('action: ', action);
    console.log('args: ', args);
    console.groupEnd();
  }

  if (typeof window.newrelic !== 'undefined') {
    // @ts-expect-error
    window.newrelic[action](...args);
  } else {
    // @ts-expect-error
    nrQueue.push(() => window.newrelic[action](...args));
  }
}

export function newRelicCustomAttribute(name: string, value: string | number | boolean): void {
  callNewrelic('setCustomAttribute', name, value);
}

export function newRelicAddRelease(tag: string): void {
  callNewrelic('addRelease', tag, tag);
}

export const newRelicNoticeError = (errorType: NoticeErrorTypes, error: object, attributes?: object) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  callNewrelic('noticeError', error, {
    errorType,
    voyager: true,
    ...attributes,
  });
};

export const newRelicAddPageAction = <T extends NrPageActions>(actionName: `Voyager${T}`, attributes?: object) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  callNewrelic('addPageAction', actionName, attributes);
};

export const PageActions = {
  PreampInitialized: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.PREAMPINITIALIZED>('VoyagerPreampInitialized', attributes),
  FormContinued: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.FORMCONTINUED>('VoyagerFormContinued', attributes),
  FormStarted: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.FORMSTARTED>('VoyagerFormStarted', attributes),
  FormSubmitted: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.FORMSUBMITTED>('VoyagerFormSubmitted', attributes),
  FormViewed: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.FORMVIEWED>('VoyagerFormViewed', attributes),
  SpotlightResultNotFound: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.SPOTLIGHT_NO_RESULTS>('VoyagerSpotlightNoResults', attributes),
  JornayaTokenNotFound: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.JORNAYATOKENNOTFOUND>('VoyagerJornayaTokenNotFound', attributes),
  BonsaieRPISortEnabled: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.BONSAI_ERPI_SORT_ENABLED>('VoyagerBonsaieRPISortEnabled', attributes),
  CappexPageTriggered: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.CAPPEX_PAGE_TRIGGERED>('VoyagerCappexPageTriggered', attributes),
  ResultsLoaded: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.RESULTS_LOADED>('VoyagerResultsLoaded', attributes),
  LeadAttempt: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.LEAD_ATTEMPT>('VoyagerLeadAttempt', attributes),
  LeadSuccess: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.LEAD_SUCCESS>('VoyagerLeadSuccess', attributes),
  ApplicationCtaViewed: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.APPLICATION_CTA_VIEWED>('VoyagerApplicationCtaViewed', attributes),
  ApplicationCtaClicked: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.APPLICATION_CTA_CLICKED>('VoyagerApplicationCtaClicked', attributes),
  InvalidPhoneLineTypeIntelligence: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.LDS_INVALID_PHONE_LINE_TYPE_INTELLIGENCE>(
      'VoyagerLeadInvalidPhoneLineTypeIntelligence',
      attributes
    ),
  ContactSaved: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.CONTACT_SAVED>('VoyagerContactSaved', attributes),
  showTopMatches: (attributes?: Record<string, unknown>) =>
    newRelicAddPageAction<NrPageActions.SHOW_TOPMATCHES>('VoyagerShowTopMatches', attributes),
};

export const newRelicInit = (formCorrelationId: string, tag: string) => {
  callNewrelic('addRelease', tag, tag);

  const { webContext } = window._Cohesion;

  const attributes = {
    voyagerFormCorrelationId: formCorrelationId,
    ...webContext,
  };

  // loop through attributes and add them to NR with setCustomAttribute()
  for (const key in attributes) {
    if (Object.prototype.hasOwnProperty.call(attributes, key)) {
      newRelicCustomAttribute(key, attributes[key]);
    }
  }
};
