import { ChangeEvent, type FC, InputHTMLAttributes, useState } from 'react';

import cn from '@core/utils/classNames';

import styles from './RoundedCheckbox.module.css';

const RoundedCheckbox: FC<Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>> = ({
  className = '',
  ...otherProps
}) => (
  <div className={cn(styles.container, className)}>
    <input id="rounded-checkbox" type="checkbox" {...otherProps} className={styles.checkbox} />
    <div className={styles.checkmark}>
      <div className={styles.innerBox} />
    </div>
  </div>
);

export default RoundedCheckbox;
