import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';
import ProgramSelect from '@core/shared/features/Recommender/ProgramSelect';
import RecommenderExitStrategy from '@core/shared/features/Recommender/RecommenderExitStrategy';
import SubmissionQueue from '@core/shared/features/Recommender/SubmissionQueue';
import { Qualifications, VoyagerResult, VoyagerResultEnhancedSchoolData } from '@core/ts/results';

import SpotlightProgramGradRPTest from '../../UGToGradResultsPageABTest/SpotlightProgram';
import PiiConfirmation from './PiiConfirmation';
import ExitStrategyRevamp from './Revamp/ExitStrategyRevamp';
import PiiConfirmationRevamp from './Revamp/PiiConfirmationRevamp';
import ProgramSelectRevamp from './Revamp/ProgramSelectRevamp';
import SpotlightProgramRevamp from './Revamp/SpotlightProgramRevamp';
import SpotlightProgram from './SpotlightProgram';

type Props = {
  enhancedSchoolData?: VoyagerResultEnhancedSchoolData;
  hits: VoyagerResult[];
  qualifications?: Qualifications;
  closePopup: () => void;
  eventingOverrides: { [key: string]: any };
};
const Recommender: FC<Props> = ({ hits, qualifications, closePopup, eventingOverrides, enhancedSchoolData }) => {
  const dcsDegrees = useSelector(selectDcsDegrees);
  const [piiLeadErrors, setPiiLeadErrors] = useState<{ field: string }[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [recommenderStep, setRecommenderStep] = useState('PROGRAM_SELECT');
  const flags = useFeatureFlags();

  const isUGToGradResultsPageABTest = flags?.voyagerGradResultsPage === 'test';

  const isSpotlightResult = eventingOverrides?.location === 'voyager-spotlight-pop-up';

  if (!hits?.length) return null;

  // Tests styles from UG in Grad Results
  const SpotlightProgramComponent = isUGToGradResultsPageABTest ? SpotlightProgramGradRPTest : SpotlightProgramRevamp;

  const renderMapControl = {
    PROGRAM_SELECT: isSpotlightResult ? (
      <SpotlightProgram
        hit={hits[0]}
        setRecommenderStep={setRecommenderStep}
        eventingOverrides={eventingOverrides}
        closePopup={closePopup}
      />
    ) : (
      <ProgramSelect
        setRecommenderStep={setRecommenderStep}
        hits={hits}
        qualifications={qualifications}
        closePopup={closePopup}
        eventingOverrides={eventingOverrides}
      />
    ),
    PII_CONFIRMATION: (
      <PiiConfirmation
        piiLeadErrors={piiLeadErrors}
        setRecommenderStep={setRecommenderStep}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    ),
    SUBMISSIONS: (
      <SubmissionQueue
        setPiiLeadErrors={setPiiLeadErrors}
        piiLeadErrors={piiLeadErrors}
        setRecommenderStep={setRecommenderStep}
        closePopup={closePopup}
        setEditMode={setEditMode}
      />
    ),
    EXIT_STRATEGY: <RecommenderExitStrategy eventingOverrides={eventingOverrides} closePopup={closePopup} />,
  };

  const renderMapRevamp = {
    PROGRAM_SELECT: isSpotlightResult ? (
      <SpotlightProgramComponent
        hit={hits[0]}
        setRecommenderStep={setRecommenderStep}
        eventingOverrides={eventingOverrides}
        closePopup={() => {}}
      />
    ) : (
      <ProgramSelectRevamp
        enhancedSchoolData={enhancedSchoolData}
        setRecommenderStep={setRecommenderStep}
        hits={hits}
        closePopup={closePopup}
        eventingOverrides={eventingOverrides}
      />
    ),
    PII_CONFIRMATION: <PiiConfirmationRevamp setRecommenderStep={setRecommenderStep} piiLeadErrors={piiLeadErrors} />,
    SUBMISSIONS: (
      <SubmissionQueue
        setPiiLeadErrors={setPiiLeadErrors}
        piiLeadErrors={piiLeadErrors}
        setRecommenderStep={setRecommenderStep}
        closePopup={closePopup}
        setEditMode={setEditMode}
      />
    ),
    EXIT_STRATEGY: <ExitStrategyRevamp eventingOverrides={eventingOverrides} closePopup={closePopup} />,
  };

  const renderMap =
    dcsDegrees.includes("Associate's") || dcsDegrees.includes("Bachelor's") || isUGToGradResultsPageABTest
      ? renderMapRevamp
      : renderMapControl;
  return <div className="recommender-form">{renderMap[recommenderStep as keyof typeof renderMap]}</div>;
};

export default Recommender;
