import { track } from '@core/services/cohesion';
import { VoyagerResult } from '@core/ts/results';

import { type UserPreferencesAndMilitary } from '../../types';
import pillCustomDimensions from '../../utils/pillCustomDimensions';

export type EventingOverrides = {
  location?: string;
  formatSubtype?: string;
  customDimensions?: { key: string; value: string }[];
  formatType?: string;
  correlationId?: string;
  position?: number;
};

const buildProductPayload = (
  result: VoyagerResult,
  userPreferencesAndMilitary: UserPreferencesAndMilitary,
  eventingOverrides?: EventingOverrides,
  correlationId?: string
) => {
  const payload = {
    listId: result?.eventing?.listId,
    viewCorrelationId: result?.eventing?.viewCorrelationId,
    product: {
      sku: String(result.cap.id),
      variant: result.program.degree.slug,
      productId: String(result.program.id),
      name: result.program.subject.slug,
      category: result.program.category.slug,
      brand: result.school.slug,
      position: eventingOverrides?.position ?? result?.eventing?.position,
      location: eventingOverrides?.location ?? 'voyager-results-page',
      formatType: eventingOverrides?.formatType ?? 'app',
      formatSubtype: eventingOverrides?.formatSubtype ?? 'grid',
      positionEngine: 'algolia',
    },
    customDimensions: [
      ...(eventingOverrides?.customDimensions ?? []),
      ...(pillCustomDimensions(result, userPreferencesAndMilitary) ?? []),
    ],
    correlationId,
  };
  return payload;
};

const productViewed = (
  result: VoyagerResult,
  userPreferencesAndMilitary: UserPreferencesAndMilitary,
  eventingOverrides?: EventingOverrides
) => {
  track({
    eventName: 'ProductViewed',
    properties: buildProductPayload(result, userPreferencesAndMilitary, eventingOverrides),
  });
};

const productLoaded = (
  result: VoyagerResult,
  userPreferencesAndMilitary: UserPreferencesAndMilitary,
  eventingOverrides?: EventingOverrides
) => {
  track({
    eventName: 'ProductLoaded',
    properties: buildProductPayload(result, userPreferencesAndMilitary, eventingOverrides),
  });
};

const productClicked = (
  result: VoyagerResult,
  userPreferencesAndMilitary: UserPreferencesAndMilitary,
  eventingOverrides?: EventingOverrides
) => {
  track({
    eventName: 'ProductClicked',
    properties: buildProductPayload(
      result,
      userPreferencesAndMilitary,
      eventingOverrides,
      eventingOverrides?.correlationId
    ),
  });
};

export { productViewed, productLoaded, productClicked };
