import { type FC } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';

import styles from './ErrorMessage.module.css';

type Props = {
  message: string;
};

const ErrorMessage: FC<Props> = ({ message }) => (
  <div className={styles.error}>
    <RiErrorWarningFill size={22} /> {message}
  </div>
);
export default ErrorMessage;
