import { type FC } from 'react';

import HowWeSelectOurMatches from '../HowWeSelectOurMatchesCallout';
import Pill from '../Pill';
import styles from './UserPreferencesBanner.module.css';

type Props = {
  resultsCount: number;
  degreePill?: string;
  subjectPill?: string;
  userPreferences?: [{ key: string; label: string }];
  firstName?: string;
  hasExactMatches?: boolean;
};

type NoResultsProps = {
  firstName?: string;
};
const NoResults: FC<NoResultsProps> = ({ firstName }) => (
  <div className={styles.noResults}>
    <h2>{firstName ? `Sorry, ${firstName}!` : 'Sorry!'} We couldn&apos;t find a direct match for your search.</h2>
  </div>
);

const UserPreferencesBanner: FC<Props> = ({
  resultsCount,
  degreePill,
  subjectPill,
  userPreferences,
  firstName,
  hasExactMatches,
}) => (
  <>
    <section className={styles.userPreferencesBanner}>
      <div className={styles.banner}>
        <div className={styles.leftSide}>
          <p className={styles.leftSideWhiteText}>Results based on my preferences:</p>
          <div className={styles.pills}>
            {degreePill && (
              <Pill color="white" className="text-white">
                {degreePill}
              </Pill>
            )}
            {subjectPill && <Pill color="white">{subjectPill}</Pill>}
            {userPreferences?.map((option: { key: string; label: string }) =>
              option.label === 'Ability to transfer credits' ? (
                <Pill color="white" key={option.key} className="text-white">
                  Ability to transfer credits
                </Pill>
              ) : (
                <Pill color="white" key={option.key} className="text-white">
                  {option.label}
                </Pill>
              )
            )}
          </div>
        </div>
        <div className={styles.rightSide}>
          <span className={styles.resultsTitle}>{resultsCount} total results</span>

          <HowWeSelectOurMatches />
        </div>
      </div>
      <div>{!hasExactMatches && <NoResults firstName={firstName} />}</div>
    </section>
  </>
);

export default UserPreferencesBanner;
