import { type Dispatch, type SetStateAction, type FC } from 'react';
import { HiOutlinePencil } from 'react-icons/hi';

import FormCtaButtonGroup from '@core/blocks/best-match-experience/FormCtaButtonGroup';
import type { VoyagerResult } from '@core/ts/results';

import DoubleChevronBackButton from '../../CtaButtons/DoubleChevronBackButton';
import PiiFormSection from '../../PiiFormSection';
import PopupFormTemplate from '../../PopupFormTemplate';
import styles from './PiiConfirmation.module.css';

type Props = {
  onFormSubmit?: () => void;
  result?: VoyagerResult;
  onEditConfirm?: () => void;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  pii: Record<string, string>;
  isEditing: boolean;
  onEdit: () => void;
  onBackButtonClick?: () => void;
  isHeclidLoading?: boolean;
  isSubmittingLead: boolean;
};

const PiiConfirmationPopup: FC<Props> = ({
  onFormSubmit,
  onEditConfirm,
  result,
  pii,
  piiErrors,
  setPii,
  isEditing,
  onEdit,
  onBackButtonClick,
  isHeclidLoading,
  isSubmittingLead,
}) => (
  <PopupFormTemplate result={result}>
    <div className={styles.piiConfirmationLearnMoreSection}>
      <div className={styles.piiConfirmationLearnMoreSectionTitle}>Want to learn more?</div>
      <p>
        Submit your contact information to start a conversation with a school advisor. You can even start your
        application if you’re ready.
      </p>

      <div className={styles.piiConfirmationLearnMoreSectionBackButtonDesktop}>
        <DoubleChevronBackButton onClick={onBackButtonClick} />
      </div>
    </div>

    <div className={styles.piiConfirmationFormPiiSection}>
      <div className={styles.piiConfirmationFormPiiSectionTitle}>
        <HiOutlinePencil size={20} strokeWidth={2} />
        Confirm your contact details:
      </div>
      <PiiFormSection piiErrors={piiErrors} setPii={setPii} pii={pii} isEditing={isEditing} />
      <div className={styles.piiConfirmationFormPiiSectionCtaButtons}>
        <FormCtaButtonGroup
          isDisabled={isSubmittingLead}
          onEditClick={onEdit}
          onSubmitClick={onFormSubmit}
          onEditConfirmClick={onEditConfirm}
          isEditing={isEditing}
          isLoading={isHeclidLoading}
        />
      </div>
      <div className={styles.piiConfirmationLearnMoreSectionBackButtonMobile}>
        <DoubleChevronBackButton onClick={onBackButtonClick} />
      </div>
    </div>
  </PopupFormTemplate>
);

export default PiiConfirmationPopup;
