// Edu Match App
import EduMatch from '@edu-match/EduMatch';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';

import BestMatchExperienceContainer from '../best-match-experience';
import EduResults from '../eduResults/EduResults';

const EduMatchRouter = () => {
  // eslint-disable-next-line camelcase
  const dcsDegrees = useSelector(selectDcsDegrees);
  const flags = useFeatureFlags();

  const LegacyResultComponent =
    (dcsDegrees.includes("Associate's") ||
      dcsDegrees.includes("Bachelor's") ||
      // Test EDU Results page on Grad results page
      flags?.voyagerGradResultsPage === 'test') &&
    !flags?.cypressTest
      ? EduResults
      : EduMatch;

  const isBestMatchExperience = flags?.bestMatchExperience === 'test';
  const ResultsComponent = isBestMatchExperience ? BestMatchExperienceContainer : LegacyResultComponent;

  return (
    <Routes>
      <Route
        path="/results"
        element={<ResultsComponent experience={isBestMatchExperience ? 'bme' : 'edu-results'} />}
      />
    </Routes>
  );
};
export default EduMatchRouter;
