import { type FC } from 'react';

import usePageHeadingParser from '@core/blocks/edu-flow/components/Page/hooks/usePageHeadingParser';
import { MonarchPsychBanner2 } from '@core/context/types';

import styles from './PsychBanner2.module.css';

const PsychBanner2: FC<MonarchPsychBanner2> = ({
  header,
  postHeader,
  subheader,
  listItem1Label,
  listItem1Value,
  listItem2Label,
  listItem2Value,
}) => {
  const parsePageHeading = usePageHeadingParser();
  return (
    <div className={styles.psychBanner}>
      <div className={styles.headingContainer}>
        <h1 className={styles.header}> {parsePageHeading(header)}</h1>
        <h1 className={styles.postHeader}>{parsePageHeading(postHeader)}</h1>
        <p className={styles.subheader}>{subheader}</p>
      </div>
      <div className={styles.dataPoints}>
        <div className={styles.dataPoint}>
          <p className={styles.dataPointValue}>{listItem1Value}</p>
          <p className={styles.dataPointLabel}>{listItem1Label}</p>
        </div>
        <div className={styles.dataPoint}>
          <p className={styles.dataPointValue}>{listItem2Value}</p>
          <p className={styles.dataPointLabel}>{listItem2Label}</p>
        </div>
      </div>
    </div>
  );
};

export default PsychBanner2;
