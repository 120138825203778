import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import UserPreferencesPills from '../../../UserPreferencesPills';
import type { UserPreferencesAndMilitary } from '../../../types';
import styles from './ExitStrategyDrawerHeader.module.css';

type Props = {
  result: VoyagerResult;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  isGrad?: boolean;
};

const ExitStrategyDrawerHeader: FC<Props> = ({ result, userPreferencesAndMilitary, isGrad }) => {
  const { program, school } = result;
  return (
    <>
      <h2 className={styles.programNameHeader}>{program.name}</h2>
      <div className={cn(styles.exitStrategySchoolDetails, isGrad && styles.gradExitStrategySchoolDetails)}>
        <div className={styles.schoolLogoNameContainer}>
          <img
            src={`https://simple-storage-server.highereducation.com/${school.slug}.png?w=130&h=80`}
            alt={school.name}
          />
          <div>
            <h3>{school.name}</h3>
            {school.state && school.city && (
              <p>
                {school.city}, {school.state}
              </p>
            )}
          </div>
        </div>
        <UserPreferencesPills result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
      </div>
    </>
  );
};

export default ExitStrategyDrawerHeader;
