import { FC, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';

import { Field } from '@core/blocks/edu-flow-2/components/Fields/types.d';
import { MonarchRequest } from '@core/hooks/monarch/monarch';
import { selectAllTestResults } from '@core/reducers/monarchSlice';
import ErrorMessage from '@core/shared/components/ErrorMessage';

import fieldTypeDictionary, { FieldType } from '../../utils/fieldTypeDictionary';
import getFieldError from '../../utils/getFieldError';
import { ConditionalType } from './ConditionalField/types';

/**
 *
 * @param field field to traverse
 * @returns the number of fields that are relevant to fieldNumber
 */
const findFieldNumberRelevantField = (field: ConditionalType) => {
  if (!['infoPanel', 'callout', 'conditional'].includes(field.type as string)) return 1;
  let results = 0;
  field.conditional?.content?.fields?.forEach((field) => {
    results += findFieldNumberRelevantField(field as ConditionalType);
  });

  return results;
};

/**
 *  Recursively go through all the fields before the provided index and include all relevant children in the fieldNumber
 * @param {Field[]} fields list of fields
 * @param {number} index index of the current field
 * @returns {number} fieldNumber
 */
const determineFieldNumber = (fields: any[], index: number): number => {
  if (!fields[index]) return 0;

  return findFieldNumberRelevantField(fields[index]) + determineFieldNumber(fields, index - 1);
};
type Props = {
  fields?: Field[];
  errors?: Error[];
  heading: string;
  fieldNumber?: number;
  onContinue?: MouseEventHandler<HTMLButtonElement>;
};

const Fields: FC<Props> = (props) => {
  const { fields, errors, heading, fieldNumber, onContinue } = props;

  const monarchMap = useSelector(selectAllTestResults);

  return (
    <>
      {fields?.map((field: Field, index: number) => {
        if (!fieldTypeDictionary[field?.type as FieldType]) {
          console.log(`Field type ${field.type} does not exist.`);
          return null;
        }
        const FieldComponent = fieldTypeDictionary[field?.type as FieldType];

        const showMonarchTest = monarchMap?.[field?.monarchTest as MonarchRequest['id']];
        // Have to solve fieldError type
        const fieldError = getFieldError(field, errors);

        const headingWithoutHTML = heading?.replace(/<[^>]+>/g, '');
        if (field?.monarchTest && !showMonarchTest) return null;

        return (
          <div key={`${field.name}-${index}`}>
            <FieldComponent
              heading={headingWithoutHTML}
              key={field.name}
              field={field}
              onContinue={onContinue as () => void}
              // fieldError={fieldError}
              fieldNumber={fieldNumber ? fieldNumber + index : determineFieldNumber(fields, index)}
            />
            {!field.hideError && fieldError && <ErrorMessage message={fieldError.message} />}
          </div>
        );
      })}
    </>
  );
};

Fields.defaultProps = {
  fields: [],
  errors: [],
  fieldNumber: undefined,
};

export default Fields;
