import Form from '@rjsf/core';
import type { FC } from 'react';

import SubmissionStatus from '../../SubmissionStatus';
import type { BaseFormProps } from '../types';
import styles from './Base.module.css';

const BaseForm: FC<BaseFormProps> = ({ children, submissionStatus, hit, id = '', ...props }) =>
  submissionStatus ? (
    <SubmissionStatus status={submissionStatus} schoolName={hit.school.name} />
  ) : (
    // We use onClickCapture instead of onClick here because the click event was stopping form interaction
    // For example, if your first click was on the checkbox, the formStarted would fire, but the checkbox wouldn't change values
    <div className={styles.prqForm}>
      <Form
        id={id}
        {...props}
        // HACK: temporary fix for the form not being able to be submitted on the first interaction
        // onChange={handleFirstInteraction}
      >
        <div className={styles.submitBtnContainer}>
          {!children ? (
            <button type="submit" className={styles.submitBtn}>
              Submit
            </button>
          ) : (
            <>{children}</>
          )}
        </div>
      </Form>
    </div>
  );

export default BaseForm;
