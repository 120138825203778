import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import AccordionItem from '../../AccordionItem';
import styles from './SchoolHighlights.module.css';

type Props = {
  result: VoyagerResult;
};
const SchoolHighlightsV3: FC<Props> = ({ result }) =>
  (result?.school?.config?.valueProps && (
    <div className={styles.schoolHighlights}>
      {result?.school?.config.valueProps.map((highlight: { title: string; description: string }, index: number) => (
        <AccordionItem title={highlight.title} description={highlight.description} index={index} />
      ))}
    </div>
  )) ||
  null;

export default SchoolHighlightsV3;
