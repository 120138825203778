import { type Dispatch, type SetStateAction, type FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectDcs } from '@core/reducers/dcsSlice';
import type { VoyagerResult } from '@core/ts/results';
import { isUnderGraduate } from '@core/utils/isUnderGraduate';

import GradPiiConfirmation from './Grad';
import UnderGradPiiConfirmation from './UnderGrad';

type Props = {
  onFormSubmit: () => void;
  pii: Record<string, string>;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  onEdit: () => void;
  onEditConfirm: () => void;
  isHeclidLoading: boolean;
  isSubmittingLead: boolean;
  result: VoyagerResult;
  headerComponent?: ReactNode;
  isEditing?: boolean;
};

const PiiConfirmation: FC<Props> = (props) => {
  const {
    dcsDegrees: [degree],
  } = useSelector(selectDcs);
  const isGrad = !isUnderGraduate(degree);

  if (isGrad) {
    return <GradPiiConfirmation {...props} />;
  }
  return <UnderGradPiiConfirmation {...props} />;
};

export default PiiConfirmation;
