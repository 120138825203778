/* eslint-disable jsx-a11y/label-has-associated-control */
import { type ChangeEvent, type Dispatch, type FC, type SetStateAction } from 'react';

import cn from '@core/utils/classNames';

import ErrorMessage from '../ErrorMessage';
import InputFieldWithFieldEventing from './InputFieldWithFieldEventing';
import styles from './PiiFormSection.module.css';

type Props = {
  header?: string;
  piiErrors: Record<string, boolean>;
  setPii: Dispatch<SetStateAction<Record<string, string>>>;
  pii: Record<string, string>;
  isEditing?: boolean;
  isDisabled?: boolean;
};
const PiiFormSection: FC<Props> = ({ header, piiErrors, setPii, pii, isEditing, isDisabled }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setPii({ ...pii, [e.target.name]: e.target.value });

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.formHeader}>{header}</h2>

      {isEditing ? (
        <>
          <div className={styles.formName}>
            <div
              className={cn(styles.inputContainer, piiErrors?.firstName ? styles.errorBorder : styles.defaultBorder)}
            >
              <InputFieldWithFieldEventing
                fieldName="first-name"
                fieldLabel="First Name"
                inputName="firstName"
                defaultValue={pii?.firstName}
                onChange={handleChange}
                isDisabled={isDisabled}
              />
              <label htmlFor="firstName" className={styles.formLabel}>
                First Name
              </label>
              {piiErrors?.firstName && <ErrorMessage message="Please enter a valid first name." />}
            </div>
            <div className={cn(styles.inputContainer, piiErrors?.lastName ? styles.errorBorder : styles.defaultBorder)}>
              <InputFieldWithFieldEventing
                inputName="lastName"
                fieldName="last-name"
                fieldLabel="Last Name"
                defaultValue={pii?.lastName}
                onChange={handleChange}
                isDisabled={isDisabled}
              />
              <label htmlFor="lastName" className={styles.formLabel}>
                Last Name
              </label>
              {piiErrors?.lastName && <ErrorMessage message="Please enter a valid last name." />}
            </div>
          </div>

          <div className={cn(styles.inputContainer, piiErrors?.email ? styles.errorBorder : styles.defaultBorder)}>
            <InputFieldWithFieldEventing
              inputName="email"
              fieldName="email"
              fieldLabel="Email"
              defaultValue={pii.email}
              onChange={handleChange}
              isDisabled={isDisabled}
            />
            <label htmlFor="email" className={styles.formLabel}>
              Email
            </label>
            {piiErrors?.email && <ErrorMessage message="Please enter a valid email address." />}
          </div>

          <div className={cn(styles.inputContainer, piiErrors?.phone ? styles.errorBorder : styles.defaultBorder)}>
            <InputFieldWithFieldEventing
              inputName="phone"
              fieldName="phone"
              fieldLabel="Phone"
              defaultValue={pii?.phone}
              onChange={handleChange}
              isDisabled={isDisabled}
            />
            <label htmlFor="phone" className={styles.formLabel}>
              Phone
            </label>
            {piiErrors.phone && <ErrorMessage message="Please enter a valid phone number." />}
          </div>
        </>
      ) : (
        <>
          <div className={styles.piiPreviewContainer}>
            <div className={styles.piiPreview}>
              <div>Name:</div>
              <div>
                {pii?.firstName} {pii?.lastName}
              </div>
            </div>
            <div className={styles.piiPreview}>
              <div>Email:</div>
              <div>{pii?.email}</div>
            </div>
            <div className={styles.piiPreview}>
              <div>Phone:</div>
              <div>{pii?.phone}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default PiiFormSection;
