import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import styles from './HorizontalCardSlider.module.css';

type Props = {
  results?: VoyagerResult[];
};

const MiniProductCard: FC<{ result: VoyagerResult }> = ({ result }) => (
  <div className={styles.miniProductCard}>
    <div className={styles.schoolLogo}>
      <img
        src={`https://simple-storage-server.highereducation.com/${result?.school?.slug}.png?w=130&h=80`}
        alt={`${result?.school.name} logo`}
      />
    </div>
    <div className={styles.programName}>{result?.program?.name}</div>
  </div>
);

const HorizontalCardSlider: FC<Props> = ({ results }) => (
  <section className={styles.horizontalCardSlider}>
    {results ? (
      results?.map((result) => <MiniProductCard key={result?.program?.id} result={result} />)
    ) : (
      <>
        <div className={styles.miniProductCardSkeletonLoader} />
        <div className={styles.miniProductCardSkeletonLoader} />
        <div className={styles.miniProductCardSkeletonLoader} />
      </>
    )}
  </section>
);

export default HorizontalCardSlider;
