import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import usePersistSearchParams from '@core/hooks/usePersistSearchParams';
import type { BlockPage } from '@core/schemas/schema';

const RootRouteHandler: FC<{ pages: BlockPage[] }> = ({ pages }) => {
  const search = usePersistSearchParams();

  const navigate = useNavigate();
  useEffect(() => {
    navigate({ pathname: pages[0].slug, search });
  }, []);
  return null;
};

export default RootRouteHandler;
